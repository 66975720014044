import React, { useContext, useState } from 'react';
import _noop from 'lodash/noop';
import { useDisclosure } from '@chakra-ui/core';
import { EngagedCandidate } from '../../firebase/firestore/documents/candidate';
import { useSeekerDocumentSnapshot } from '../../firebase/firestore/hooks';
import { Seeker } from '../../firebase/firestore/documents/seeker';
import { SelectedSeekerEngagedCandidate } from './sidebar/seekerList/seekersPresenter';

export type MessagingViewContextValueType = {
  selectedEngagedCandidate?: Readonly<EngagedCandidate>;
  selectedSeeker?: Readonly<Seeker>;
  setSelectedEngagedCandidate: (candidate?: Readonly<EngagedCandidate>) => void;
  selectedEngagedSeeker?: SelectedSeekerEngagedCandidate | undefined;
  setSelectedEngagedSeeker: (candidate: SelectedSeekerEngagedCandidate | undefined) => void;
  isHireModalOpen: boolean;
  onHireModalOpen: () => void;
  onHireModalClose: () => void;
  req: string | undefined;
  setReq: (req?: string | undefined) => void;
  isMeetModalOpen: boolean;
  onMeetModalOpen: () => void;
  onConfirmModalClose: () => void;
};

export const DEFAULT_MESSAGING_VIEW_CONTEXT: MessagingViewContextValueType = {
  selectedEngagedCandidate: undefined,
  selectedSeeker: undefined,
  setSelectedEngagedCandidate: _noop,
  selectedEngagedSeeker: undefined,
  setSelectedEngagedSeeker: _noop,
  isHireModalOpen: false,
  onHireModalOpen: _noop,
  onHireModalClose: _noop,
  req: undefined,
  setReq: _noop,
  isMeetModalOpen: false,
  onMeetModalOpen: _noop,
  onConfirmModalClose: _noop,
};

const MessagingViewContext = React.createContext<MessagingViewContextValueType>(DEFAULT_MESSAGING_VIEW_CONTEXT);

export const MessagingViewContextProvider: React.FC = ({ children }) => {
  const [selectedEngagedCandidate, setSelectedEngagedCandidate] = useState<Readonly<EngagedCandidate>>();
  const [selectedEngagedSeeker, setSelectedEngagedSeeker] = useState<SelectedSeekerEngagedCandidate | undefined>();
  const [req, setReq] = useState<string | undefined>(undefined);
  const selectedSeeker = useSeekerDocumentSnapshot(selectedEngagedCandidate?.seeker);
  const { isOpen: isHireModalOpen, onOpen: onHireModalOpen, onClose: onHireModalClose } = useDisclosure();
  const { isOpen: isMeetModalOpen, onOpen: onMeetModalOpen, onClose: onConfirmModalClose } = useDisclosure();

  return (
    <MessagingViewContext.Provider
      value={{
        selectedEngagedSeeker,
        setSelectedEngagedSeeker,
        selectedEngagedCandidate,
        selectedSeeker,
        setSelectedEngagedCandidate,
        isHireModalOpen,
        onHireModalOpen,
        onHireModalClose,
        req,
        setReq,
        isMeetModalOpen,
        onMeetModalOpen,
        onConfirmModalClose,
      }}
    >
      {children}
    </MessagingViewContext.Provider>
  );
};

export const useMessagingViewContext = (): MessagingViewContextValueType => useContext(MessagingViewContext);
export const useSelectedEngagedCandidate = (): MessagingViewContextValueType['selectedEngagedCandidate'] =>
  useContext(MessagingViewContext)?.selectedEngagedCandidate;
