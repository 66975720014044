export default {
  createJobsTab: 'Create',
  createJobPositionCardHeader: 'Create Position',
  updateJobPositionCardHeader: 'Update Position',
  createJobPositionCardHint: 'Fill out or edit remaining fields',
  industry: 'Industry',
  occupation: 'Occupation',
  jobType: 'Job Type',
  jobTitle: 'Job Title',
  noOfOpenings: 'Number of Openings',
  businessLocation: 'Business Location',
  businessLocationPlaceholder: 'Select Business Location',
  salary: 'Salary',
  per: 'Per',
  unit: 'Unit',
  experience: 'Experience',
  jobDescription: 'Description',
  yearsOfExperience: 'Years of Experience',
  expiryDate: 'Expiry Date',
  automationCardHeader: 'Automation ',
  automationCardHint: 'Are you busy? Here are 3 ways I will help you simplify your recruiting.',
  automationOptionOneLabel: 'Find me candidates',
  automationOptionOneHint: 'I will find you the best candidates, ask them a few questions, but the rest is up to you.',
  automationOptionTwoLabel: 'Screen the candidates',
  automationOptionTwoHint:
    'I will find you the best candidates and screen them, and check their interest for you. Just tell me how many.',
  automationOptionNoOfCandidateScreenLabel: 'How many candidates should I screen?',
  automationOptionThreeLabel: 'Take care of everything',
  automationOptionThreeHint:
    'I find the best candidates, offer them multiple pre-selected time slot, and manage your interview schedule.',
  interviewCardHeader: 'Interview Details',
  interviewCardHint: 'Edit interview details',
  interviewType: 'Interview Type',
  interviewDuration: 'Interview Duration',
  interviewLocation: 'Interview in Business Location',
  interviewOtherLocation: 'Other Location',
  interviewMessage: 'Interview Details for the Candidates',
  videoCardHeader: 'Video Details',
  videoCardHint: 'Add Video for Candidates',
  maximizeInterest: 'Maximize Interest!',
  interestDetail: `Record and upload a 30 seconds video to increase candidates’ interest in
  your position. The video will be visible to candidates looking at the position.`,
  uploadBtn: 'Upload',
  removeBtn: 'Remove',
  keepBtn: 'Keep',
  deleteBtn: 'Delete',
  questionCardHeader: 'Specific Questions',
  questionCardHint: 'Add questions to ask candidates',
  questionLabel: 'Add your Question',
  questionInputPlaceholder: 'Type your question here (500 characters)',
  questionExpectedAnsLabel: 'Expected Answer',
  questionAutoDismissLabel: `Automatically dismiss candidates who do not select the expected answer`,
  deleteQuestionConfirmation: 'Are you sure want to delete the question?',
  confirmBtn: 'Confirm',
  freeFormQuestionCardHeader: 'Free Form Questions',
  freeFormQuestionCardHint: 'Add free form questions to ask candidates',
  freeFormQuestionLabel: 'Add your Free Form Question',
  freeFormQuestionInputPlaceholder: 'Type your free form question here (500 characters)',
  workScheduleCardHeader: 'Desired Availability',
  workScheduleCardHint: 'Specify the work schedule for this position. Toggle the time slots that are part of the schedule',
  weekDay: 'Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday',
  workSchedule: 'Day, Evening, Night',
  fullTimeLabel: 'This is a full time position',
  advanceAccordionLabel: 'Advance',
  skillsCardHeader: 'Skills',
  skillsCardHint: 'Indicate the importance of each skills relative to one another.',
  skillsCardHintSlideText: "Use the slider to set the skill's importance",
  questionRequired: 'Question is required',
  questionMinLetterRequired: 'Question must contain minimum 10 letter',
  questionMaxLetterRequired: 'Question must contain maximum {{count}} letter',
  interviewTypeRequired: 'Interview type is required',
  interviewDurationRequired: 'Interview duration is required',
  interviewMessageRequired: 'Interview message is required',
  interviewLocationRequired: 'Interview Location is required',
  minPriceRequired: 'Minimum value is required',
  maxPriceRequired: 'Maximum value is required',

  invalidNumberFormat: 'Invalid number format',
  maxValueShouldBeGreaterThanMinValue: `Number must be greater than or equal to {{minValue}}`,
  minValueRequired: 'Minimum value is required',
  maxValueRequired: 'Maximum value is required',
  minValueShouldBeLowerThanMaxValue: 'Number must be less than or equal to {{maxValue}}',

  unitRequired: 'Unit is required',
  industryRequired: 'Industry is required',
  occupationRequired: 'Occupation is required',
  jobRequired: 'Job is required',
  jobTitleRequired: 'Job title is required',
  businessLocationRequired: 'Business location is required',
  noOfOpeningsRequired: 'Number of openings is required',
  maxPriceMustBe: 'Number must be greater than or equal to',
  maxNoOfOpeningRequired: 'Number must be less than or equal to 999',
  jobDescriptionRequired: 'Job description is required',
  jobExpiryDateRequired: 'Job expiry date is required',
  jobCreatedSuccessMessage: 'Job position created successfully',
  jobUpdatedSuccessMessage: 'Job position updated successfully',
  automationConfigurationRequired: 'Number of candidates is required',
  automationConfigurationMinRequired: 'At least 1 is required',
  proposedSalaryUnitOptions: {
    annual: 'Annual',
    hourly: 'Hourly',
    custom: 'Custom',
  },
  experienceFilterTypeOptions: {
    preferred: 'Preferred',
    maximum: 'Maximum',
    minimum: 'Minimum',
  },
  steps: {
    jobDetail: 'Job Details',
    additionalDetail: 'Additional Details',
    questions: 'Questions',
    automation: 'Automation',
    availability: 'Availability',
    videoDetails: 'Video Details',
  },
  stepsNav: {
    back: 'Back',
    next: 'Next',
    submit: 'Create Position',
    update: 'Update Position',
  },
  businessManagementTab: {
    businessAndPosition: 'Locations and Positions',
    availableJobs: 'Available Positions',
    createJob: 'Create Position',
    updatesJob: 'Update Position',
    createMultipleJobs: 'Create Multiple Jobs',
    automation: 'Automation',
  },
  jobDescriptionPlaceholder: 'Write a short description of the position',
  interviewDescriptionPlaceholder: 'Write a message for the interviewee',
  selectOccupationPlaceholder: 'Select Occupation',
  selectJobTypePlaceholder: 'Select Job Type',
  to: 'To',
  video: {
    maxLengthError: 'Max allowed video length is {{length}} minute(s).',
    maxSizeError: 'Max allowed video size is {{size}} MB.',
  },
  selectFilterByType: `Select Filter Type`,
  copyInfo: 'You can modify other details once the position is created.',
  jobDetails: 'Position Details',
  copyPositionError: 'The position could not be copied at this moment.',
  reviewPositionBtn: 'Review Position Details',
  wages: 'Salary Amount',
  salaryType: 'Salary Unit',
  noSpecificQuestions: 'There are no questions configured for this position.',
  noFreeFormQuestions: 'There are no questions configured for this position.',
  questionHeader: 'Questions',
  edit: 'Edit Position',
  copyPosition: 'Copy',
  dublicationInfo: 'Copying Position Details to new position',
  positionNamePlaceholder: 'Position',
  hireForm: 'Hire Form',
  scheduleMatch: 'Schedule Matching',
  enabled: 'Enabled',
  disabled: 'Disabled',
  modernHire: 'Competency Evaluation',
  modernHirePassThreshold: 'Evaluation Pass Threshold',
  templateID: 'Template Id',
  modernHireTemplateIdError: 'Template Id cannot be empty',
  modernHireThresholdValueNotEmptyError: 'Pass threshold cannot be empty',
  modernHireThresholdValueNotZeorError: 'Pass threshold must be greater than 0',
  languageThresholdValueEmptyError: 'Language evaluation pass threshold is required',
  multipleChoiceQuestions: 'Multiple Choice Questions',
  newQuestionHeader: 'Pre-qualification Questions',
  questionsHint: `Add questions we'll ask to candidates when they apply`,
  freeFormQuestions: 'Free Form Questions',
  freeform: 'Freeform',
  specific: 'Yes/No',
  multipleChoice: 'Multiple Choice',
  pleaseSelect: 'Candidates need to select',
  bestOptions: 'options. Answers',
  of: 'of',
  done: 'Done',
  selectOptions: 'Select Options',
  hintText: 'Candidates will be prompted to select the correct number of options.',
  placeholderHint: 'If you set A and B as required options, any answers containing A and B will be considered correct.',
  optionNotEmpty: 'You must fill all options.',
  questionNotEmpty: 'You must enter a question.',
  optionsRequired: 'You need to select {{numberOfOptions}} correct options.',
  multipleChoiceQuestion: {
    IS_EQUAL: 'must be equal to',
    HAS_ANSWER: 'must include',
    DOES_NOT_HAVE_ANSWER: 'must not include',
    AT_LEAST: 'must include at least',
  },
  addOption: 'Add Option',
  enableLanguageEvaluation: 'Enable Language Evaluation',
  languageEvaluation: 'Language Evaluation:',
  languageEvaluationPassThreshold: 'Language Evaluation Pass Threshold',
  selectLanguage: 'Select Language',
  selectLanguageEvaluationPassThreshold: 'Pass Threshold',
  templateRequiredError: 'You must choose at least one phrase.',
  languageAgentRequiredError: 'You must select both an English and French agent.',
  selectPassThreshold: 'Select Threshold',
  phraseId: 'Phrase',
  selectPhrase: ' Select Phrase',
  english: 'English',
  french: 'French',
  enabledCertnIntegration: 'Enable Certn Integration',
  certnIntegration: 'Certn Integration',
  certnIntegrationSubtitle: 'Select the required checks as default',
  virtualRecruiter: 'Virtual Recruiter',
  noPhrasetext: 'You can add phrases from Administration/Configuration/Language Evaluation',
  videoImageFormatFail: 'Your video must be in .mp4, .webm, .ogg, or .mov format.',
  notSupportedInWebApply: 'Note that multiple choice questions are not currently supported in Web Apply.',
  enableDialogFlow: 'Enable Conversational AI',
  selectEnglishAgent: 'Select English Agent',
  selectFrenchAgent: 'Select French Agent',
  dialogflow: 'Conversational AI',
  selectedDialogflowAgents: 'Selected Conversational AI Agents',
  englishAgent: 'English Agent',
  frenchAgent: 'French Agent',
  videoGallery: {
    videoGallery: ' Video Gallery',
    videoGalleryInfo: 'Manage your uploaded video content here.',
    addVideo: 'Add Video',
    deleteVideo: 'Delete Video',
    videoDeleteWarning: `Are you sure? You can't undo this action afterwards.`,
    cancel: 'Cancel',
    delete: 'Delete',
    videoDeleteSuccess: 'Video deleted successfully.',
    videoDeleteErrorMessage: 'The video could not be deleted at the moment.',
    close: 'Close',
  },
  recruiterNotes: 'Recruiter Notes',
  recruiterNotePlaceholder: 'Write your notes here.',
};
