import { Thunk, thunk } from 'easy-peasy';
import { ToDoListController } from '../controllers/toDoListController';
import { HttpStoreModel } from '../http/models/httpModel';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { ToDoList } from '../firebase/firestore/documents/toDo';

export type ToDoListStoreModel = {
  toDoList: ToDoModel;
};

export interface ToDoModel {
  getToDo: Thunk<ToDoModel, { accountId: string }, void, HttpStoreModel, Promise<ToDoList>>;
  updateRecruiterActionStatus: Thunk<
    ToDoModel,
    { userId: string; docId: string; status: string; category: string; subCategory: string | null; candidateId: string },
    void,
    HttpStoreModel,
    Promise<any>
  >;
}

export const toDoStoreModel: ToDoModel = {
  getToDo: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ToDoListController(getStoreState().http.client as HttpClientWithRetry);
    const response = await controller.getToDoList(payload.accountId);
    return response?.data as ToDoList;
  }),

  updateRecruiterActionStatus: thunk(async (_actions, payload, { getStoreState }) => {
    const controller = new ToDoListController(getStoreState().http.client as HttpClientWithRetry);
    // eslint-disable-next-line no-return-await
    return await controller.updateRecruiterActionStatus(
      payload.userId,
      payload.docId,
      payload.status,
      payload.category,
      payload.subCategory,
      payload.candidateId,
    );
  }),
};
