import React from 'react';
import {
  AccordionIcon,
  Box,
  Text,
  Flex,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/core';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { FavoriteCandidateView } from './FavoriteCandidateView';
import { CandidateEngagementView } from './CandidateEngagementView';
import { BackgroundCheck } from './BackgroundCheck';
import { searchAndSortWrapper } from './SearchAndSortStyles';
import { useSeekersFilters } from '../filters/SeekersFiltersContext';
import { RatingsView } from './RatingsView';
import { useStoreState } from '../../../../models/hooks';
// import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { HireStatus } from './HireStatus';
import { LanguageEvaluation } from './LanguageEvaluation';
import { AvailabilityFit } from './AvailabilityFit';
import { PriorityCandidatesView } from './PriorityCandidatesView';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import { KioskCandidatesView } from './KioskCandidatesView';
import { ActionRequiredCandidateView } from './ActionRequiredCandidateView';

export type SearchAndSortViewProps = {
  onCloseSearch: () => void;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
};

export const SearchAndSortView = ({
  onCloseSearch,
  isCertnFeatureEnabled,
  isCertnEnabled,
}: SearchAndSortViewProps): JSX.Element => {
  const { t } = useTranslation();

  const { setIsFromSearchAndSort, searchAndSortTypes, setSearchAndSortTypes } = useSeekersFilters();

  const clearAllFilters = () => {
    searchAndSortTypes.isFavouriteApplied = false;
    searchAndSortTypes.isPriorityCandidates = false;
    searchAndSortTypes.kioskEnabled = false;
    searchAndSortTypes.backgroundCheck = [];
    searchAndSortTypes.hireStatus = [];
    searchAndSortTypes.languageEvaluation = [];
    searchAndSortTypes.availabilityFit = [];
    searchAndSortTypes.modernHireStatus = [];
    searchAndSortTypes.candidateEngagement.apointmentRequested = '';
    searchAndSortTypes.candidateEngagement.Hired = '';
    searchAndSortTypes.candidateEngagement.offerMade = '';
    searchAndSortTypes.candidateEngagement.offerRejected = '';
    searchAndSortTypes.candidateEngagement.appoinmentAccepted = '';
    searchAndSortTypes.candidateEngagement.appoinmentDeclined = '';
    searchAndSortTypes.candidateEngagement.appoinmentCancled = '';
    searchAndSortTypes.candidateEngagement.noAppointmentRequest = '';
    searchAndSortTypes.candidateEngagement.offerAccepted = '';
    searchAndSortTypes.candidateEngagement.interestRevoked = '';
    searchAndSortTypes.candidateEngagement.contactRequestDeclined = '';
    searchAndSortTypes.rating = [0, 0, 0, 0, 0];
    searchAndSortTypes.availability.day = false;
    searchAndSortTypes.availability.night = false;
    searchAndSortTypes.availability.evening = false;
    setIsFromSearchAndSort(false);
    setSearchAndSortTypes({ ...searchAndSortTypes });
  };

  // const isAvailabilitySearchEnabledInGlobalSearch = useStoreState((state) => {
  //   return state.remoteConfig[RemoteConfigKey.ENABLE_AVAILABILITY_FEATURE_IN_GLOBAL_SEARCH];
  // });
  const isHireFormEnabled = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabled);
  const isLanguageEvaluationEnabled = useStoreState((s) => s.app.accounts?.configuration?.languageEvaluationEnabled);

  const hireFormEnabledPositions = useStoreState((s) => s.app.accounts?.configuration?.hireFormEnabledPositions);

  const isRequsitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);

  const isPriorityFeatureEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_PRIORITY_CANDIDATE_FILTER];
  });

  return (
    <Box css={searchAndSortWrapper}>
      <Box className="searchSidebar">
        <Box px={3} py={4} className="searchAndSortHeader">
          <Flex>
            <Box onClick={onCloseSearch} style={{ cursor: 'pointer', marginTop: 2 }}>
              <AiOutlineArrowLeft />
            </Box>
            <Text
              px={3}
              style={{
                fontSize: '16px',
                fontWeight: 'bolder',
                paddingBottom: 14,
              }}
            >
              {t('searchAndSort.advanceSearch')}
            </Text>
          </Flex>
          <Divider />
        </Box>
        <Box className="searchAndSortBody">
          <Box>
            <Box mb={2}>
              <Box px={4} pb="1">
                <FavoriteCandidateView />
              </Box>
              {isPriorityFeatureEnabled && (
                <Box px={4} pb="1">
                  <PriorityCandidatesView />
                </Box>
              )}
              <Box px={4}>
                <KioskCandidatesView />
              </Box>
              <Box px={4} pb="1">
                <ActionRequiredCandidateView />
              </Box>
            </Box>
            <Box>
              <Accordion allowMultiple defaultIndex={[0]}>
                <AccordionItem style={{ border: 'none' }}>
                  <h2>
                    <AccordionButton style={{ paddingLeft: 0 }}>
                      <Box flex="1" textAlign="left" px={4}>
                        <Text
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bolder',
                          }}
                        >
                          {t('searchAndSort.candidateEngagement')}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <CandidateEngagementView />
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem style={{ border: 'none' }}>
                  <h2>
                    <AccordionButton style={{ paddingLeft: 0 }}>
                      <Box flex="1" textAlign="left" px={4}>
                        <Text
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bolder',
                          }}
                        >
                          {t('searchAndSort.ratings')}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <RatingsView />
                  </AccordionPanel>
                </AccordionItem>
                {isHireFormEnabled && hireFormEnabledPositions && hireFormEnabledPositions.length > 0 && (
                  <AccordionItem style={{ border: 'none' }}>
                    <h2>
                      <AccordionButton style={{ paddingLeft: 0 }}>
                        <Box flex="1" textAlign="left" px={4}>
                          <Text
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bolder',
                            }}
                          >
                            {t('searchAndSort.hireStatus')}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <HireStatus />
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {/* {isModernHireFormEnabled && ( */}
                {/*  <AccordionItem style={{ border: 'none' }}> */}
                {/*    <h2> */}
                {/*      <AccordionButton style={{ paddingLeft: 0 }}> */}
                {/*        <Box flex="1" textAlign="left" px={4}> */}
                {/*          <Text */}
                {/*            style={{ */}
                {/*              fontSize: '14px', */}
                {/*              fontWeight: 'bolder', */}
                {/*            }} */}
                {/*          > */}
                {/*            {t('searchAndSort.modernHireStatus')} */}
                {/*          </Text> */}
                {/*        </Box> */}
                {/*        <AccordionIcon /> */}
                {/*      </AccordionButton> */}
                {/*    </h2> */}
                {/*    <AccordionPanel> */}
                {/*      <ModernHireStatus /> */}
                {/*    </AccordionPanel> */}
                {/*  </AccordionItem> */}
                {/* )} */}
                {isCertnFeatureEnabled && isCertnEnabled && (
                  <AccordionItem style={{ border: 'none' }}>
                    <h2>
                      <AccordionButton style={{ paddingLeft: 0 }}>
                        <Box flex="1" textAlign="left" px={4}>
                          <Text
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bolder',
                            }}
                          >
                            {t('searchAndSort.backgroundCheck')}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <BackgroundCheck />
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {/* {isAvailabilitySearchEnabledInGlobalSearch && ( */}
                {/*  <AccordionItem style={{ border: 'none' }}> */}
                {/*    <h2> */}
                {/*      <AccordionButton style={{ paddingLeft: 0 }}> */}
                {/*        <Box flex="1" textAlign="left" px={4}> */}
                {/*          <Text */}
                {/*            style={{ */}
                {/*              fontSize: '14px', */}
                {/*              fontWeight: 'bolder', */}
                {/*            }} */}
                {/*          > */}
                {/*            {t('searchAndSort.availability')} */}
                {/*          </Text> */}
                {/*        </Box> */}
                {/*        <AccordionIcon /> */}
                {/*      </AccordionButton> */}
                {/*    </h2> */}
                {/*    <AccordionPanel> */}
                {/*      <Availability /> */}
                {/*    </AccordionPanel> */}
                {/*  </AccordionItem> */}
                {/* )} */}
                {isLanguageEvaluationEnabled && (
                  <AccordionItem style={{ border: 'none' }}>
                    <h2>
                      <AccordionButton style={{ paddingLeft: 0 }}>
                        <Box flex="1" textAlign="left" px={4}>
                          <Text
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bolder',
                            }}
                          >
                            {t('audioEvaluation:languageEvaluation')}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <LanguageEvaluation />
                    </AccordionPanel>
                  </AccordionItem>
                )}
                {isRequsitionEnabled && (
                  <AccordionItem style={{ border: 'none' }}>
                    <h2>
                      <AccordionButton style={{ paddingLeft: 0 }}>
                        <Box flex="1" textAlign="left" px={4}>
                          <Text
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bolder',
                            }}
                          >
                            {t('searchAndSort.availabilityFit')}
                          </Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <AvailabilityFit />
                    </AccordionPanel>
                  </AccordionItem>
                )}
              </Accordion>
            </Box>
            <Box px={4} py={4} onClick={clearAllFilters} data-testid="clearFilterBtn">
              <Text
                style={{ fontWeight: 700, fontSize: 14, float: 'right', color: '#1F3CBA', paddingBottom: 14, cursor: 'pointer' }}
              >
                {t('searchAndSort.clearAll')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
