/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Container } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { Header } from '../../app/topBar/Header';
import { ANIMATED_VARIANTS } from '../../routes/constants';
import { AppLoadingSpinner } from '../../app/components';
import useConfiguration, { IsUnderMaintenanceEnum } from '../../app/hooks/useConfiguration';
import { MaintenanceView } from '../../error/MaintenanceView';
import { PositionManagementView } from '../administration/positionManagement/PositionManagementView';

const MotionBox = motion.custom(Box);

export const JobPositionView = (): JSX.Element => {
  const useConfig = useConfiguration();
  return (
    <React.Fragment>
      {useConfig === IsUnderMaintenanceEnum.MAINTENANCE && <MaintenanceView />}
      {useConfig === IsUnderMaintenanceEnum.READY && (
        <MotionBox data-testid="JobPositionView" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
          <Header data-testid="Header" showMenu />
          <Box py={8} backgroundColor="#F3F3F3" height="calc(100vh - 64px)" overflowY="auto">
            <Container maxW="1200px">
              <PositionManagementView />
            </Container>
          </Box>
        </MotionBox>
      )}
      {useConfig === IsUnderMaintenanceEnum.LOADING && <AppLoadingSpinner />}
    </React.Fragment>
  );
};
