export default {
  readyToJoin: `Prêt à joindre?`,
  join: 'Joindre!',
  waitingForCandidate: `En attente du candidat`,
  alreadyJoinedHeader: "Un recruteur s'est déjà joint à l'entrevue",
  alreadyJoinedBy: "est présentement dans l'entrevue.",
  okay: 'Okay',
  ratingDisabledTooltip: "Vous pouvez évaluer ce candidat pour jusqu'à 24 heures suivant l'entrevue.",
  testSpeaker: 'Vérifier sortie audio',
  yourDevices: `Choisir le périphérique que vous allez utiliser pour écouter l'entrevue`,
  done: 'Terminer',
  checkYourDevice: 'Vérifier sortie audio',
  guestMeet: {
    readyToJoin: `Vous êtes quasiment arrivé!`,
    enterInfoToJoin: `Remplissez les champs ci-dessous pour pouvoir joindre l'entrevue.`,
    name: 'Votre nom',
    namePlaceholder: 'Entrez votre nom complet',
    nameRequired: 'Your name is required',
    codeRequired: 'Code is required',
    code: 'Code de vérification',
    codePlaceholder: 'Entrez le code de vérification que vous avez reçu',
    join: 'Joindre',
    hintMessageBeforeJoin:
      'In preparation for the interview, please make sure you are using the headphone or the device that you will use for the event.',
    joinMessage: `Vous êtes connecté comme {{userName}}. Vous pouvez maintenant joindre l'entrevue.`,
    invalidCode: 'Invalid Code',
    leaveCall: `Quitter l'entrevue`,
  },
  errorCode: {
    '1005': `Vous ne pouvez pas joindre l'entrevue avant qu'elle commence.`,
    '1006': `Vous ne pouvez pas joindre l'entrevue après qu'elle soit terminée.`,
  },
  collaboratorFeatureNotAvailable: `Notre fonctionnalité de co-intervieweur est actuellement non disponible.`,
  timeEndingAlert: {
    giveAssessment: "N'oubliez pas d'évaluer ce candidat!",
    interviewTimeEnd: 'Cette entrevue termine bientôt.',
    timeRemaining: "Cette entrevue termine bientôt, donc assurez-vous d'évaluer le candidat si ce n'est pas déjà fait.",
    interviewEndAfter: 'Cette entrevue termine automatiquement dans',
    okay: `J'ai compris`,
  },
};
