import React, { Fragment } from 'react';
import {
  Box,
  Button,
  ModalFooter,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Modal,
  Text,
  Flex,
  Divider,
  Badge,
  FormLabel,
} from '@chakra-ui/core';

import { useTranslation } from 'react-i18next';
import 'rc-steps/assets/index.css';
import { RiPhoneFill, RiUserVoiceFill, RiVidiconFill } from 'react-icons/ri';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { JobPosition, JobType, OccupationType } from '../PositionType';
import { getTranslatedValue } from '../../../../utils/localizedString';
import iconTransportation from '../../../../assets/img/icon-transportation.svg';
import iconFood from '../../../../assets/img/icon-food.svg';
import iconHospitality from '../../../../assets/img/icon-hospitality.svg';
import iconRetail from '../../../../assets/img/icon-retail.svg';
import iconServices from '../../../../assets/img/icon-services.svg';
import iconManufacturing from '../../../../assets/img/icon-manufacturing.svg';
import { IconIndustry } from './JobPositionDetailCard';
import colors from '../../../../styles/colors';
import useBusinessLocations from '../useBusinessLocations';
import { useStoreState } from '../../../../models/hooks';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';
import useBackgroundChecks from '../../../../app/hooks/useBackgroundChecks';
import { BackgroundChecks } from '../../../../firebase/firestore/documents/candidate';
import { LanguageEvaluationPhrases } from '../../../../firebase/firestore/documents/languageEvaluationPhrases';
import useLanguagePhrasesByAccountId from '../../../../app/hooks/useLanguagePhrasesByAccountId';
import { Lookups } from '../../../../firebase/firestore/documents/lookups';

export type ReviewCreateJobViewProps = {
  jobPositionData: JobPosition;
  isNewPosition: boolean;
  isCopyPostion: boolean;
  onClose: () => void;
  onSave: () => void;
  reviewPosition: boolean;
  occupationList: OccupationType[];
  jobList: JobType[];
  videoUrl?: string | null;
  isVideoFeatureEnabled?: boolean;
  audioEvaluationOptions?: Lookups[];
};

export const ReviewCreateJobView = ({
  jobPositionData,
  isNewPosition,
  isCopyPostion,
  onClose,
  onSave,
  reviewPosition,
  occupationList,
  jobList,
  videoUrl,
  isVideoFeatureEnabled,
  audioEvaluationOptions,
}: ReviewCreateJobViewProps): JSX.Element => {
  const loadIcon = (industryType: string) => {
    switch (industryType) {
      case IconIndustry.TRANSPORTATION:
        return iconTransportation;
      case IconIndustry.FOOD_AND_BEVERAGE:
        return iconFood;
      case IconIndustry.HOSPITALITY:
        return iconHospitality;
      case IconIndustry.RETAIL:
        return iconRetail;
      case IconIndustry.SERVICES:
        return iconServices;
      case IconIndustry.MANUFACTURING:
        return iconManufacturing;
      default:
        return iconServices;
    }
  };

  const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri'];
  const weekendDays = ['sat', 'sun'];

  const { t, i18n } = useTranslation();
  const accountId = useStoreState((state) => state.app.accounts?.id);
  const businessLocations = useBusinessLocations();
  const backgroundChecks = useBackgroundChecks();
  const languageEvaluationPhrases = useLanguagePhrasesByAccountId(accountId ?? '');

  const loadSubmitLabel = () => {
    if (isNewPosition) {
      return t('createJobPosition:stepsNav.submit');
    }

    return t('createJobPosition:stepsNav.update');
  };

  const filterJobs = (industryId: string, occupationId: string) =>
    jobList
      // eslint-disable-next-line radix
      .filter((j) => j.occupation === parseInt(occupationId))
      .sort((j1, j2) => (/* istanbul ignore next */ j1.jobTitle.en.toUpperCase() < j2.jobTitle.en.toUpperCase() ? -1 : 1));

  const topMargin = 4;
  const enableMultipleChoiceFeature: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_MULTIPLE_CHOICE_FEATURE];
  });
  const isVirtualRecruiterEnabled = useStoreState((s) => s.app.accounts?.configuration?.virtualRecruiterEnabled);

  const loadInterviewIcon = (iconType: string) => {
    switch (iconType) {
      case 'inPerson':
        return <RiUserVoiceFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
      case 'video':
        return <RiVidiconFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
      default:
        return <RiPhoneFill style={{ color: colors.blue[500], width: '28px', height: '28px' }} />;
    }
  };

  const getAutomationData = (automationType: string) => {
    if (automationType === 'screening') {
      return (
        <Box>
          <Text fontSize="14px" fontWeight="700" alignItems="center" display="flex">
            {t('createJobPosition:automationOptionTwoLabel')}
          </Text>
          <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
            {t('createJobPosition:automationOptionTwoHint')}
          </Text>
        </Box>
      );
    }
    if (automationType === 'scheduling') {
      return (
        <Box>
          <Text fontSize="14px" fontWeight="700" alignItems="center" display="flex">
            {t('createJobPosition:automationOptionThreeLabel')}
          </Text>
          <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
            {t('createJobPosition:automationOptionThreeHint')}
          </Text>
        </Box>
      );
    }
    return (
      <Box>
        <Text fontSize="14px" fontWeight="700" alignItems="center" display="flex">
          {t('createJobPosition:automationOptionOneLabel')}
        </Text>
        <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
          {t('createJobPosition:automationOptionOneHint')}
        </Text>
      </Box>
    );
  };
  const getBusinessAddress = (businessId: string) => {
    const selectedBusinessLocation = businessLocations.find((businessLocation) => businessLocation.id === businessId);

    if (selectedBusinessLocation) {
      return selectedBusinessLocation.label;
    }
    return '';
  };
  const getExperienceType = (type: string) => {
    if (type === 'minimum') {
      return t('createJobPosition:experienceFilterTypeOptions.minimum');
    }
    if (type === 'maximum') {
      return t('createJobPosition:experienceFilterTypeOptions.maximum');
    }
    return t('createJobPosition:experienceFilterTypeOptions.preferred');
  };

  const hireFormEnabled = useStoreState((state) => state.app.accounts?.configuration?.hireFormEnabled);
  const isModernHireEnable = useStoreState((s) => s.app.accounts?.configuration?.modernHireEnable);
  const isScheduleMatchEnabled = useStoreState((s) => s.app.accounts?.configuration?.scheduleMatchEnabled);
  const isRequsitionEnabled = useStoreState((s) => s.app.accounts?.configuration?.requisitionEnabled);
  const selectedThresholdOption = audioEvaluationOptions?.find(
    (option) => option.order === jobPositionData.languageEvaluationPassThreshold,
  );

  const filteredSelectedAgents = jobPositionData.dialogFlowAgents?.reduce(
    (result: { [key: string]: string | undefined }, item) => {
      // istanbul ignore next
      if (result.en && result.fr) return result;
      return {
        ...result,
        [item.defaultLanguageCode]: result[item.defaultLanguageCode] || item.displayName,
      };
    },
    {},
  );

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={reviewPosition}
      size="5xl"
      scrollBehavior="inside"
      onClose={/* istanbul ignore next */ () => onClose()}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex mt={topMargin}>
            <Box w="33%" pr={12}>
              <Text fontSize="16px" fontWeight="700">
                {isNewPosition ? t('jobPosition:newJobFor') : `${t('jobPosition:updateJobPosition')}`}
              </Text>
              <Text fontSize="14px" fontWeight="700" alignItems="center" display="flex">
                {getBusinessAddress(jobPositionData.business)}
              </Text>
            </Box>
            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:industry')}
              </Text>
              <Flex mt={2}>
                {jobPositionData.industryName && (
                  <img src={loadIcon(jobPositionData.industryName)} alt={loadIcon(jobPositionData.industryName)} />
                )}
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex" paddingLeft="12px">
                  {jobPositionData.industryName}
                </Text>
              </Flex>
            </Box>

            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:experience')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {jobPositionData.experienceFilterType
                  ? `${getExperienceType(jobPositionData.experienceFilterType)} - ${jobPositionData.requiredExperience}`
                  : `${jobPositionData.requiredExperience ?? '-'}`}
              </Text>
            </Box>
          </Flex>

          <Flex mt={topMargin}>
            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:occupation')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {getTranslatedValue(
                  occupationList.filter((value) => value.id.toString() === jobPositionData.occupation)[0].name,
                  i18n.language,
                )}
              </Text>
            </Box>

            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:jobType')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {getTranslatedValue(
                  filterJobs(jobPositionData.industry, jobPositionData.occupation).filter(
                    (value) => value.id.toString() === jobPositionData.job,
                  )[0].jobTitle,
                  i18n.language,
                )}
              </Text>
            </Box>
            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:jobTitle')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {jobPositionData.jobCustomName}
              </Text>
            </Box>
          </Flex>
          <Flex mt={topMargin}>
            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:noOfOpenings')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {jobPositionData.noOfOpenings ? jobPositionData.noOfOpenings : '-'}
              </Text>
            </Box>

            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:wages')}
              </Text>
              <Flex>
                {jobPositionData.proposedSalary.minValue && (
                  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                    $
                  </Text>
                )}

                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData.proposedSalary.minValue}
                  &nbsp;
                </Text>
                {jobPositionData.proposedSalary.minValue && jobPositionData.proposedSalary.maxValue && (
                  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                    &nbsp; - &nbsp;
                  </Text>
                )}
                {jobPositionData.proposedSalary.maxValue && (
                  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                    $
                  </Text>
                )}
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData.proposedSalary.maxValue}
                </Text>
              </Flex>
            </Box>
            <Box w="33%">
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:salaryType')}
              </Text>
              <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                {jobPositionData.proposedSalary.unit}
              </Text>
            </Box>
          </Flex>
          <Box mt={topMargin}>
            <Text fontSize="16px" fontWeight="700">
              {t('createJobPosition:jobDescription')}
            </Text>
            <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
              {jobPositionData.jobDescription ? jobPositionData.jobDescription : '-'}
            </Text>
          </Box>
          {!_isNil(videoUrl || jobPositionData.videoUrl) && isVideoFeatureEnabled && (
            <Box>
              <Text fontSize="16px" fontWeight="700">
                {t('createJobPosition:videoCardHeader')}
              </Text>
              {
                // eslint-disable-next-line jsx-a11y/media-has-caption
                jobPositionData.videoUrl ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video width="100%" style={{ height: '300px' }} controls>
                    <source src={jobPositionData.videoUrl} type="video/mp4" />
                  </video>
                ) : (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video width="100%" style={{ height: '300px' }} controls>
                    <source src={videoUrl /* istanbul ignore next */ || ''} type="video/mp4" />
                  </video>
                )
              }
            </Box>
          )}
          <Flex>
            {hireFormEnabled && (
              <Box mt={topMargin} w="30%" pr="6">
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:hireForm')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.enableHireFormSolution ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>
            )}
            {isScheduleMatchEnabled && isRequsitionEnabled && (
              <Box mt={topMargin} w="30%" pr="6">
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:scheduleMatch')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.enableScheduleMatch ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>
            )}
            {isVirtualRecruiterEnabled && (
              <Box mt={topMargin} w="30%" pr="6">
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:virtualRecruiter')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.virtualRecruiterEnabled ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>
            )}
          </Flex>
          {isModernHireEnable && (
            <Flex>
              <Box w="30%" mt={topMargin}>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:modernHire')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.modernHireEnable ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>
              <Box w="30%" mt={topMargin}>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:modernHirePassThreshold')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.modernHirePassThreshold ? jobPositionData?.modernHirePassThreshold : '-'}
                </Text>
              </Box>
              <Box w="30%" mt={topMargin}>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:templateID')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData?.modernHireTemplateId ? jobPositionData?.modernHireTemplateId : '-'}
                </Text>
              </Box>
            </Flex>
          )}
          <Box>
            <Flex>
              <Box my={3} w="30%">
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:languageEvaluation')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {!_isNil(jobPositionData.languageEvaluationTemplates) ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>

              {!_isNil(selectedThresholdOption) ? (
                <Box my={3} w="30%">
                  <Text fontSize="16px" fontWeight="700">
                    {t('createJobPosition:languageEvaluationPassThreshold')}
                  </Text>
                  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                    <Badge colorScheme="green">{getTranslatedValue(selectedThresholdOption?.label, i18n.language)}</Badge>
                  </Text>
                </Box>
              ) : null}

              <Box my={3} w="30%">
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:dialogflow')}
                </Text>
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                  {jobPositionData.dialogFlowEnabled ? (
                    <Badge colorScheme="green">{t('createJobPosition:enabled')}</Badge>
                  ) : (
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  )}
                </Text>
              </Box>

              {!_isEmpty(jobPositionData.dialogFlowAgents) ? (
                <Box my={3} w="30%">
                  <Text fontSize="16px" fontWeight="700">
                    {t('createJobPosition:selectedDialogflowAgents')}
                  </Text>
                  <Text d="flex" flexDirection="column" fontSize="14px" fontWeight="400" alignItems="baseline" display="flex">
                    <Badge mb={2} colorScheme="green">
                      {`${t('createJobPosition:englishAgent')} - ${filteredSelectedAgents?.['en']}`}
                    </Badge>
                    <Badge colorScheme="green">
                      {`${t('createJobPosition:frenchAgent')} - ${filteredSelectedAgents?.['fr']}`}
                    </Badge>
                  </Text>
                </Box>
              ) : null}

              {!jobPositionData.enableScreeningOptions && (
                <Box my={3} w="30%">
                  <Text fontSize="16px" fontWeight="700">
                    {t('createJobPosition:certnIntegration')}
                  </Text>
                  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex">
                    <Badge colorScheme="red">{t('createJobPosition:disabled')}</Badge>
                  </Text>
                </Box>
              )}
            </Flex>
            {/* Todo: Will be uncomment on next release */}
            {/* <Box my={3} w="100%"> */}
            {/*  <Text fontSize="16px" fontWeight="700"> */}
            {/*    {t('createJobPosition:recruiterNotes')} */}
            {/*  </Text> */}
            {/*  <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex"> */}
            {/*    {jobPositionData.recruiterNote ? jobPositionData.recruiterNote : '-'} */}
            {/*  </Text> */}
            {/* </Box> */}

            {jobPositionData.languageEvaluationTemplates?.map((template) => (
              <Box mb={3} key={template.phraseId}>
                <FormLabel htmlFor="phrase" fontWeight="bold" fontSize="14px">
                  {
                    languageEvaluationPhrases.filter(
                      (langPhrase: LanguageEvaluationPhrases) => langPhrase.id === template.phraseId,
                    )[0]?.phraseId
                  }
                </FormLabel>
                <Box fontSize="14px" border="1px solid" borderColor="gray.50" borderRadius={3} p={3}>
                  {template.phrase}
                </Box>
              </Box>
            ))}
          </Box>
          {jobPositionData.enableScreeningOptions &&
            jobPositionData?.screeningOptions &&
            Object.keys(Object.fromEntries(Object.entries(jobPositionData?.screeningOptions).filter(([, v]) => v))).length > 0 &&
            backgroundChecks.length > 0 && (
              <Box>
                <Box my={3}>
                  <Text fontSize="16px" fontWeight="700">
                    {t('createJobPosition:certnIntegration')}
                  </Text>
                  <Flex flexWrap="wrap">
                    {jobPositionData.screeningOptions?.request_soquij === true && (
                      <Box d="inline-flex" alignItems="center" w="33%" fontSize="sm">
                        <MdCheckBox style={{ color: colors.blue[500], marginRight: '3px' }} />
                        SOQUIJ
                      </Box>
                    )}
                    {backgroundChecks
                      .filter(
                        (bc) =>
                          jobPositionData?.screeningOptions && jobPositionData.screeningOptions[bc.id as keyof BackgroundChecks],
                      )
                      .sort((a, b) => (a.order > b.order ? 1 : -1))
                      .map((bc) => {
                        return (
                          <Box d="inline-flex" alignItems="center" key={bc.id} w="33%" fontSize="sm">
                            <MdCheckBox style={{ color: colors.blue[500], marginRight: '3px' }} />
                            {bc.label.en}
                          </Box>
                        );
                      })}
                  </Flex>
                </Box>
              </Box>
            )}

          {enableMultipleChoiceFeature && (
            <Box>
              <Divider mt={2} mb={2} />

              <Box mt={topMargin}>
                {jobPositionData.multipleChoiceQuestions && jobPositionData.multipleChoiceQuestions.length > 0 && (
                  <Fragment>
                    <Box>
                      <Text fontSize="16px" fontWeight="700">
                        {t('createJobPosition:multipleChoiceQuestions')}
                      </Text>
                    </Box>
                    {jobPositionData.multipleChoiceQuestions?.map((q, index) => (
                      // eslint-disable-next-line react/jsx-key,react/no-array-index-key
                      <Box mt="16px" key={`${q.question}-${index}`}>
                        <Divider mt={1} mb={3} opacity={0.15} display={index === 0 ? 'none' : 'block'} />
                        <Flex justifyContent="space-between">
                          <Box w="100%">
                            <Flex>
                              <Text fontSize="14px" fontWeight="700" pr="2px">
                                Q.
                              </Text>
                              <Text fontSize="14px" fontWeight="700">
                                {q.question}
                              </Text>
                            </Flex>
                            <Box>
                              <Flex w="100%">
                                <Text fontSize="14px" fontWeight="700" pr="2px">
                                  A.
                                </Text>
                                <Flex w="100%" flexWrap="wrap">
                                  {q.options.map((o, i) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <Flex key={`${o.text}-${i}`} w="25%" alignItems="center">
                                      {/* eslint-disable-next-line no-nested-ternary */}
                                      {o.isPreferredAnswer ? (
                                        q.totalAnswer === 1 ? (
                                          <MdRadioButtonChecked style={{ color: colors.blue[500], marginTop: '3px' }} />
                                        ) : (
                                          <MdCheckBox style={{ color: colors.blue[500], marginTop: '3px' }} />
                                        )
                                      ) : q.totalAnswer === 1 ? (
                                        <MdRadioButtonUnchecked style={{ color: colors.blue[500], marginTop: '3px' }} />
                                      ) : (
                                        <MdCheckBoxOutlineBlank style={{ color: colors.blue[500], marginTop: '3px' }} />
                                      )}
                                      <Text
                                        w="calc(100% - 25px)"
                                        ml="5px"
                                        fontSize="14px"
                                        lineHeight="1.3"
                                        fontWeight="400"
                                        mt="5px"
                                      >
                                        {o.text}
                                      </Text>
                                    </Flex>
                                  ))}
                                </Flex>
                              </Flex>
                            </Box>
                          </Box>
                        </Flex>
                      </Box>
                    ))}
                  </Fragment>
                )}
              </Box>
            </Box>
          )}
          <Divider mt={2} mb={2} />
          <Flex mt={topMargin}>
            <Box w="49%">
              <Box>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:questionHeader')}
                </Text>
              </Box>
              {jobPositionData.specificQuestions.length ? (
                jobPositionData.specificQuestions.map((item) => (
                  <Box key={item.id}>
                    <Flex>
                      <Box w="90%">
                        <Text fontSize="14px" fontWeight="700">
                          {item.text}
                        </Text>
                      </Box>
                      <Box w="10%">
                        <Text fontSize="14px" fontWeight="400">
                          {item.preferredAnswer ? t('common:questions.answerPositive') : t('common:questions.answerNegative')}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                ))
              ) : (
                <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex" color={colors.red[600]}>
                  {t('createJobPosition:noSpecificQuestions')}
                </Text>
              )}
              <Box mt={topMargin}>
                {jobPositionData.freeFormQuestions.map((item) => (
                  <Flex key={item.id}>
                    <Box w="90%">
                      <Text fontSize="14px" fontWeight="700">
                        {item.text}
                      </Text>
                    </Box>
                    <Box w="10%" />
                  </Flex>
                ))}
              </Box>
            </Box>
            <Box w="2%" />
            <Box w="49%">
              <Box>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:automationCardHeader')}
                </Text>
                <Text fontSize="14px" fontWeight="400">
                  {getAutomationData(jobPositionData?.automationType ? jobPositionData?.automationType : '')}
                </Text>
              </Box>
              <Box mt={topMargin}>
                <Box>
                  <Text fontSize="16px" fontWeight="700">
                    {t('createJobPosition:interviewCardHeader')}
                  </Text>
                </Box>
                <Flex>
                  <Box w="50%" fontSize="14px" fontWeight="700">
                    <Text>{t('createJobPosition:interviewType')}</Text>
                  </Box>
                  <Box w="50%">
                    <Text fontSize="14px" fontWeight="700">
                      {t('createJobPosition:interviewDuration')}
                    </Text>
                  </Box>
                </Flex>
                <Flex>
                  <Box w="50%" fontSize="14px" fontWeight="700">
                    <Flex>
                      <Box>{loadInterviewIcon(jobPositionData.interview.interviewType)}</Box>
                      <Text fontSize="14px" fontWeight="400" alignItems="center" display="flex" ml={2}>
                        {jobPositionData.interview.interviewType}
                      </Text>
                    </Flex>
                  </Box>
                  <Box w="50%">
                    <Flex>
                      <Text fontSize="14px" fontWeight="400">
                        {jobPositionData.interview.interviewDuration}
                      </Text>
                      <Text fontSize="14px" fontWeight="400">
                        &nbsp;min.
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
                <Box alignItems="center" mt={1}>
                  <Text fontSize="14px" fontWeight="700">
                    {t('createJobPosition:interviewMessage')}
                  </Text>
                  <Text fontSize="14px" fontWeight="400">
                    {jobPositionData.interview.interviewMessage ? jobPositionData.interview.interviewMessage : '-'}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Flex>
          <Divider mt={2} mb={2} />
          <Flex mt={topMargin}>
            <Box w="49%">
              <Box>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:skillsCardHeader')}
                </Text>
                {jobList
                  // eslint-disable-next-line radix
                  .filter((job) => job.id === parseInt(jobPositionData.job, undefined))[0]
                  .skills.map((skill) => (
                    <Flex justifyContent="space-between" key={skill.id}>
                      <Text fontSize="14px" fontWeight="400">
                        {getTranslatedValue(skill.name, i18n.language)}
                      </Text>
                      <Flex>
                        <Text fontSize="14px" fontWeight="400">
                          {jobPositionData?.skills?.length &&
                            jobPositionData?.skills?.filter((value) => value.id === skill.id)[0].value * 100}
                        </Text>
                        <Text fontSize="14px" fontWeight="400">
                          &nbsp;%
                        </Text>
                      </Flex>
                    </Flex>
                  ))}
              </Box>
            </Box>
            <Box w="2%" />
            {jobPositionData.newAvailabilities && (
              <Box w="49%" ml={4}>
                <Text fontSize="16px" fontWeight="700">
                  {t('createJobPosition:workScheduleCardHeader')}
                </Text>
                <Box>
                  <Box>
                    {weekDays.map((week, count) => (
                      <Flex
                        justifyContent="space-between"
                        key={week}
                        px={1}
                        py={1}
                        fontSize="13.5px"
                        border="1px solid"
                        bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
                        borderColor="gray.150"
                      >
                        <Box textTransform="capitalize" fontWeight="bold" w="25%">
                          {t(`jobPosition:days.${week}`)}
                        </Box>
                        <Flex style={{ float: 'right', width: '82%', justifyContent: 'end' }}>
                          <Text w="29%" fontSize="12px" display="flex" justifyContent="flex-end" mr="4px">
                            {jobPositionData.newAvailabilities && jobPositionData.newAvailabilities[week]
                              ? moment((jobPositionData.newAvailabilities || /* istanbul ignore next */ {})[week].start_1, [
                                  'HH:mm',
                                ]).format('LT')
                              : /* istanbul ignore next */ ''}
                          </Text>
                          <Text w="2%" fontSize="12px">
                            -
                          </Text>
                          <Text w="29%" fontSize="12px">
                            {jobPositionData.newAvailabilities && jobPositionData.newAvailabilities[week]
                              ? moment((jobPositionData.newAvailabilities || /* istanbul ignore next */ {})[week].end_1, [
                                  'HH:mm',
                                ]).format('LT')
                              : /* istanbul ignore next */ ''}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Box>
                  <Box mt="12px">
                    {weekendDays.map((week, count) => (
                      <Flex
                        justifyContent="space-between"
                        key={week}
                        px={1}
                        py={1}
                        fontSize="13.5px"
                        border="1px solid"
                        bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
                        borderColor="gray.150"
                      >
                        <Box textTransform="capitalize" fontWeight="bold" w="25%">
                          {t(`jobPosition:days.${week}`)}
                        </Box>
                        <Flex style={{ float: 'right', width: '82%', justifyContent: 'end' }}>
                          <Text w="29%" fontSize="12px" display="flex" justifyContent="flex-end" mr="4px">
                            {jobPositionData.newAvailabilities && jobPositionData.newAvailabilities[week]
                              ? moment((jobPositionData.newAvailabilities || /* istanbul ignore next */ {})[week].start_1, [
                                  'HH:mm',
                                ]).format('LT')
                              : /* istanbul ignore next */ ''}
                          </Text>
                          <Text w="2%" fontSize="12px">
                            -
                          </Text>
                          <Text w="29%" fontSize="12px">
                            {jobPositionData.newAvailabilities && jobPositionData.newAvailabilities[week]
                              ? moment((jobPositionData.newAvailabilities || /* istanbul ignore next */ {})[week].end_1, [
                                  'HH:mm',
                                ]).format('LT')
                              : /* istanbul ignore next */ ''}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={() => onClose()} data-testid="close_btn">
            {t('createJobPosition:edit')}
          </Button>
          <Button colorScheme="blue" onClick={() => onSave()} data-testid="save_btn">
            {isCopyPostion ? t('createJobPosition:copyPosition') : loadSubmitLabel()}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
