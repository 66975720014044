export default {
  myProfile: 'Mon profil',
  editProfile: 'Modifier votre profil',
  personalInformation: 'Renseignements',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  email: 'Adresse courriel',
  update: 'Mettre à jour',
  firstNameRequired: 'Le prénom est requis',
  lastNameRequired: 'Le nom de famille est requis',
  emailNameRequired: 'Le courriel est requis',
  emailFormatIncorrect: 'Le courriel doit être un format valide',
  profileUpdatedSuccessfully: 'Profil modifié avec succès',
  profileUpdatedFailed: `Il a eu une erreur! Le profil n'a pas sauvegardé.`,
  profilePictureUpdated: `Votre photo de profil est modifiée avec succès.`,
  profilePictureUpdateFail: `Votre photo n'a pu être modifiée.`,
  profileImageFormatFail: 'Votre photo de profil doit être en format PNG ou JPEG.',
  profileImageSizeFail: 'Votre photo de profil ne doit pas dépasser 1 MB.',
  firstNameMin: 'Le prénom doit être au moins 2 caractères',
  firstNameMax: 'Le prénom ne doit pas excéder 30 caractères',
  lastNameMin: 'Le nom doit être au moins 2 caractères',
  lastNameMax: 'Le nom ne doit pas excéder 30 caractères',
};
