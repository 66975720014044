import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CurrentStatus } from '../../../firebase/firestore/documents/offerAndHireProcess';
import { PATH_DASHBOARD } from '../../../routes/constants';
import colors from '../../../styles/colors';
import DropDownCheckBox, { Option } from '../../common/DropDownCheckBox/DropDownCheckBox';

const OfferAndHireProcessViewTopBar = ({
  checkedItems,
  handleCheckboxChange,
  queryName,
  options,
}: {
  checkedItems: {
    [key: string]: boolean;
  };
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  queryName: string;
  options: Option[];
}) => {
  const { t } = useTranslation('offerAndHireProcess');
  const placeholderStatus = CurrentStatus[queryName as keyof typeof CurrentStatus];
  return (
    <Box p="12px" bg="#fff">
      <Flex alignItems="center" justifyContent="space-between">
        <Flex pl="10px" pr="10px" alignItems="center">
          <MdOutlineArrowBack
            size="20px"
            data-testid="goBackToDoLeftIcon"
            style={{ marginRight: 8, cursor: 'pointer' }}
            onClick={() => window.history.back()}
          />
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink fontSize="md" color={colors.gray[900]} as={Link} to={PATH_DASHBOARD}>
                {t('toDoBreadCrumb')}
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink fontSize="md" color={colors.black}>
                {t('pageTitle')}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex>
        <Box minWidth="fit-content">
          <DropDownCheckBox
            options={options}
            dropDownPlaceholder={`${t('offerAndHireStatus')} ${t(`filterOption.${placeholderStatus}`)}`}
            status={queryName}
            checkedItems={checkedItems}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default OfferAndHireProcessViewTopBar;
