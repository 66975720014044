/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment } from 'react';
import { Box, Flex, Text } from '@chakra-ui/core';
import i18next from 'i18next';
import moment from 'moment';
import { getTranslatedValue } from '../../../../../../utils/localizedString';

const weekDays = [
  { en: 'mon', fr: 'lun' },
  { en: 'tue', fr: 'mar' },
  { en: 'wed', fr: 'mer' },
  { en: 'thu', fr: 'jeu' },
  { en: 'fri', fr: 'ven' },
];

const weekEnd = [
  { en: 'sat', fr: 'sam' },
  { en: 'sun', fr: 'dim' },
];

export type CandidateAvailabilityDuration = {
  start_1: string;
  end_1: string;
};

type CandidateAvailabilitiesProps = {
  candidateAvailabilities: { [key: string]: { start_1: string; end_1: string } };
};

export const CandidateAvailabilities = ({ candidateAvailabilities }: CandidateAvailabilitiesProps): JSX.Element => {
  let count = 0;
  return (
    <Flex>
      <Box w="50%" mr="10px">
        {weekDays.map((d) => {
          count += 1;
          if (candidateAvailabilities && (candidateAvailabilities[d.en] as CandidateAvailabilityDuration)) {
            return (
              <Flex
                justifyContent="space-between"
                key={d.en}
                w="100%"
                px={1}
                py={1}
                fontSize="13.5px"
                bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
                border="1px solid"
                borderColor="gray.150"
              >
                <Box textTransform="capitalize" fontWeight="bold" w="18%">
                  {getTranslatedValue(d, i18next.language)}
                </Box>
                <Flex style={{ float: 'right', width: '82%', justifyContent: 'end' }}>
                  <Text w="40%" fontSize="12px">
                    {moment(candidateAvailabilities[d.en].start_1, ['HH:mm']).format('LT')}
                  </Text>
                  <Text w="5%" mr="6px" fontSize="12px">
                    -
                  </Text>
                  <Text w="40%" fontSize="12px">
                    {moment(candidateAvailabilities[d.en].end_1, ['HH:mm']).format('LT')}
                  </Text>
                </Flex>
              </Flex>
            );
          }
          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              w="100%"
              px={1}
              py={1}
              fontSize="13.5px"
              bg={count === 1 || count === 3 || count === 5 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
            >
              <Box textTransform="capitalize" fontWeight="bold">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              <Box>---</Box>
            </Flex>
          );
        })}
      </Box>
      <Box w="50%">
        {weekEnd.map((d) => {
          count += 1;
          /* istanbul ignore else */

          if (candidateAvailabilities && (candidateAvailabilities[d.en] as CandidateAvailabilityDuration)) {
            return (
              <Flex
                justifyContent="space-between"
                key={d.en}
                w="100%"
                minWidth="165px"
                px={1}
                py={1}
                fontSize="13.5px"
                bg={count === 7 ? 'gray.150' : 'white'}
                border="1px solid"
                borderColor="gray.150"
              >
                <Box textTransform="capitalize" fontWeight="bold" w="18%">
                  {getTranslatedValue(d, i18next.language)}
                </Box>
                <Flex style={{ float: 'right', width: '82%', justifyContent: 'end' }}>
                  <Text w="40%" fontSize="12px">
                    {moment(candidateAvailabilities[d.en].start_1, ['HH:mm']).format('LT')}
                  </Text>
                  <Text w="5%" mr="6px" fontSize="12px">
                    -
                  </Text>
                  <Text w="40%" fontSize="12px">
                    {moment(candidateAvailabilities[d.en].end_1, ['HH:mm']).format('LT')}
                  </Text>
                </Flex>
              </Flex>
            );
          }
          /* istanbul ignore next */
          return (
            <Flex
              justifyContent="space-between"
              key={d.en}
              w="48.5%"
              minWidth="165px"
              px={1}
              py={1}
              fontSize="13.5px"
              bg={count === 7 ? 'gray.150' : 'white'}
              border="1px solid"
              borderColor="gray.150"
            >
              <Box textTransform="capitalize" fontWeight="bold">
                {getTranslatedValue(d, i18next.language)}
              </Box>
              <Box>---</Box>
            </Flex>
          );
        })}
      </Box>
    </Flex>
  );
};
