export const isCandidatePage = () => {
  const location = window.location.href;
  return location.includes('/candidate/');
};
export const isCandidatesPage = () => {
  const location = window.location.href;
  return location.includes('/candidates/');
};

export const isCandidateOnDashboard = () => {
  const location = window.location.href;
  return location.includes('/dashboard');
};
