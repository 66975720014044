import moment from 'moment-timezone';
import { Shifts } from '../firebase/firestore/documents/requisition';

export const isRequisitionAvailable = (
  effectiveDate: Date | undefined,
  requisitionActiveThresholdDaysAccount?: number,
  requisitionActiveThresholdDaysPosition?: number,
) => {
  if (!effectiveDate) {
    return true;
  }

  const parsedDate = moment(effectiveDate);
  const today = moment();

  if (requisitionActiveThresholdDaysPosition !== undefined) {
    return parsedDate.isSameOrBefore(today.add(requisitionActiveThresholdDaysPosition, 'days'), 'day');
  }

  if (requisitionActiveThresholdDaysAccount !== undefined) {
    return parsedDate.isSameOrBefore(today.add(requisitionActiveThresholdDaysAccount, 'days'), 'day');
  }

  return true; // Default to true if no thresholds are specified
};
export const compareTimes = (time1: string, time2: string): number => {
  const [hour1, minute1] = time1.split(':').map(Number);
  const [hour2, minute2] = time2.split(':').map(Number);
  return hour1 + minute1 / 60 - (hour2 + minute2 / 60);
};
// eslint-disable-next-line consistent-return
export const getShiftIntersectionWithCandidate = (requisitionShift?: Shifts, candidateShift?: Shifts) => {
  if (requisitionShift && Object.keys(requisitionShift).length && candidateShift && Object.keys(candidateShift)) {
    const intersectingShift: Shifts = {};
    let start: string;
    let end: string;

    // eslint-disable-next-line no-restricted-syntax
    for (const day in requisitionShift) {
      if (candidateShift[day]) {
        start =
          compareTimes(requisitionShift[day].start_1, candidateShift[day].start_1) > 0
            ? requisitionShift[day].start_1
            : candidateShift[day].start_1;
        end =
          compareTimes(requisitionShift[day].end_1, candidateShift[day].end_1) > 0
            ? candidateShift[day].end_1
            : requisitionShift[day].end_1;
        /* istanbul ignore next eslint-disable-next-line no-continue */
        // eslint-disable-next-line no-continue
        if (compareTimes(start, end) >= 0) continue; // there is no intersection between two times
        intersectingShift[day] = { start_1: start, end_1: end };
      }
    }
    return intersectingShift;
  }
};
