/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, Center, Flex, Grid, Image, InputGroup, Spinner, Stack, Text, Textarea } from '@chakra-ui/core';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ResizeTextarea from 'react-textarea-autosize';
import _throttle from 'lodash/throttle';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import colors from '../../../../../styles/colors';
import ChatMessage from './chatMessage/ChatMessage';
import { chat, chatMessageInput, chatMessageInputWrapper, chatMessages, stackVariants } from './Chat.styles';
import useMessages, { MessageStateEnum } from './useMessages';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { isCandidateFromFollowedPosition, isCandidateReadOnly } from '../../../../../services/CandidateService';
import { useInterviewConfig } from '../../header/actionsDropdown/useInterviewConfig';
import { Candidate } from '../../../../../firebase/firestore/documents/candidate';
import TypingIndicator from './typingIndicator/TypingIndicator';
import useSeekerIsTyping from './useSeekerIsTyping';

const MotionBox = motion.custom(Box);
export type ChatProps = {
  candidate?: Candidate;
  messageText: string;
  onMessageChange: (value: string) => void;
};

export const Chat = ({ candidate, messageText, onMessageChange }: ChatProps): JSX.Element => {
  const { t } = useTranslation();

  const useMessagesPresenter = useMessages();
  const interviewConfig = useInterviewConfig();
  const { sendMessage, markCandidateAsRead, setRecruiterIsTyping } = useStoreActions((actions) => actions.messaging);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const isSeekerTyping = useSeekerIsTyping();
  const appUserId = useStoreState((s) => s.app.user?.id);
  const [numberOfNewLine, setNumberOfNewLine] = useState<number>(0);

  const isUserDeactivated = candidate && Boolean(candidate.deactivated || candidate.scheduledDeletion);
  const isUserDismissed = candidate && Boolean(candidate.dismissed);
  const isUserBlacklisted = candidate && Boolean(candidate.blacklisted);

  const throttleRequest = useMemo(
    () =>
      _throttle((currentCandidate?: Candidate, lastUpdate?: Date) => {
        setRecruiterIsTyping({ candidateId: currentCandidate?.id, lastUpdate });
      }, 1000),
    [setRecruiterIsTyping],
  );

  useEffect(() => {
    if (candidate) {
      markCandidateAsRead({ positionId: candidate.position, seekerId: candidate.seeker });
    }
  }, [markCandidateAsRead, candidate]);

  useEffect(() => {
    const messagesEnd = messagesEndRef?.current;
    if (useMessagesPresenter.state === MessageStateEnum.READY) {
      messagesEnd?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [useMessagesPresenter]);

  const prevCandidate = useRef(candidate);
  useEffect(() => {
    // istanbul ignore next
    if (candidate && prevCandidate.current && candidate.id !== prevCandidate.current.id) {
      prevCandidate.current = candidate;
      onMessageChange('');
    }
  }, [candidate, onMessageChange]);

  const handleMessageTextInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const message = event.target.value;
    /* istanbul ignore else */
    if (message.split('\n').length <= 1) {
      setNumberOfNewLine(0);
    } else if (message.split('\n').length <= 3) {
      setNumberOfNewLine(24);
    }
    if (_isEmpty(message)) throttleRequest(candidate);
    else throttleRequest(candidate, moment().toDate());

    onMessageChange(message);
  };

  const doSendMessage = () => {
    /* istanbul ignore if */
    if (!candidate || !messageText.trim()) return;

    throttleRequest(candidate);

    onMessageChange('');
    setNumberOfNewLine(0);
    sendMessage({
      message: messageText,
      positionId: candidate.position,
      seekerId: candidate.seeker,
    });
  };

  const isSelectedCandidateReadOnly = candidate ? isCandidateReadOnly(candidate) : false;

  return (
    <Grid data-testid="Chat" css={chat}>
      {useMessagesPresenter && (
        <Fragment>
          {!isCandidateFromFollowedPosition(candidate, appUserId) && useMessagesPresenter.state === MessageStateEnum.UNDEFINED && (
            <Center>
              <Flex direction="column" data-testid="NoMessagesUnfollowed" textAlign="center">
                <Image src="/nomessage.svg" boxSize="300px" alt="No Message" />
                <Text mt="5">
                  <b>{t('noMessageUnfollowed')}</b>
                </Text>
              </Flex>
            </Center>
          )}

          {isCandidateFromFollowedPosition(candidate, appUserId) && useMessagesPresenter.state === MessageStateEnum.UNDEFINED && (
            <Center>
              <Flex direction="column" data-testid="NoMessages">
                <Image src="/nomessage.svg" boxSize="300px" alt="No Message" />
                <Text mt="5">
                  <b>{t('noMessage')}</b>
                </Text>
              </Flex>
            </Center>
          )}

          {useMessagesPresenter.state === MessageStateEnum.LOADING && (
            <Center>
              <Spinner color={colors.blue.default} data-testid="Spinner" />
            </Center>
          )}
          {useMessagesPresenter.state === MessageStateEnum.READY && (
            <MotionBox
              overflow="auto"
              initial="hidden"
              animate="visible"
              variants={stackVariants}
              key="ChatMessageGrid"
              css={chatMessages}
              p={4}
              data-testid="ChatMotion"
              style={{ marginBottom: 16 }}
            >
              {candidate &&
                useMessagesPresenter.presenter.messages.map((message) => (
                  <ChatMessage
                    key={message.id}
                    message={message}
                    interviewConfig={
                      useMessagesPresenter.presenter.isLastAppointmentRequestMessage(message) ? interviewConfig : undefined
                    }
                    isMonetized={!!candidate?.monetized}
                  />
                ))}

              {isSeekerTyping && <TypingIndicator />}

              <div ref={messagesEndRef} data-testid="Chat_MessageEnd" />
            </MotionBox>
          )}
        </Fragment>
      )}

      <Box css={chatMessageInput} className="super-centered" px={4} py={4} display="flex" bg={colors.white} mt="-8px">
        <Stack direction="row" alignItems="center" w="100%" css={chatMessageInputWrapper}>
          <InputGroup flex={1} style={{ verticalAlign: 'bottom', display: 'table-cell' }}>
            <Textarea
              className="input-type"
              data-testid="message-text-input"
              variant="outline"
              placeholder={isUserDeactivated ? t('messaging:actions.disabledActionLabelDeactivated') : 'Message'}
              resize="unset"
              minH="unset"
              maxH="120px"
              minRows={1}
              maxRows={5}
              as={ResizeTextarea}
              style={{ background: '#f5f5f5' }}
              flex={1}
              autoFocus
              value={messageText}
              onKeyPress={(event) => {
                /* istanbul ignore else */
                if (event.key === 'Enter' && !event.shiftKey) {
                  doSendMessage();
                  event.preventDefault();
                } else if (event.key === 'Enter') {
                  setNumberOfNewLine(numberOfNewLine < 48 ? numberOfNewLine + 12 : numberOfNewLine);
                }
              }}
              onChange={handleMessageTextInputChange}
              disabled={
                isSelectedCandidateReadOnly ||
                isUserDeactivated ||
                isUserDismissed ||
                isUserBlacklisted ||
                !isCandidateFromFollowedPosition(candidate, appUserId)
              }
            />
          </InputGroup>
          <Button
            onClick={doSendMessage}
            disabled={
              !messageText.trim() ||
              isSelectedCandidateReadOnly ||
              isUserDeactivated ||
              isUserDismissed ||
              isUserBlacklisted ||
              !isCandidateFromFollowedPosition(candidate, appUserId)
            }
            backgroundColor={colors.blue.default}
            color={colors.white}
            style={{ marginBottom: 10 }}
            data-testid="send-message-button"
          >
            {t('send')}
          </Button>
        </Stack>
      </Box>
      <Box style={{ height: numberOfNewLine }} />
    </Grid>
  );
};
