/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, memo, useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  Text,
  Flex,
} from '@chakra-ui/core';
import { BiCalendar } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { useStoreActions } from '../../../models/hooks';
import { AvailableBookingWithPosition } from '../../../firebase/firestore/documents/appointmentAvaiability';
import { appointmentMessageCSS } from '../../messaging/seeker/candidate/messages/appointmentMessage/AppointmentMessage.style';
import colors from '../../../styles/colors';

export type BookingDetailViewCancelModalProps = {
  isOpen: boolean;
  onClose: () => void;
  bookingDetailViewClose: () => void;
  bookingDetail: AvailableBookingWithPosition;
  showWarning?: boolean;
};

export const BookingDetailViewCancelModal = ({
  isOpen,
  onClose,
  bookingDetail,
  bookingDetailViewClose,
  showWarning,
}: BookingDetailViewCancelModalProps): JSX.Element => {
  const { t } = useTranslation('calendar');
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelIndividualBooking = useStoreActions((state) => state.calendar.cancelIndividualBooking);

  const cancelBookingOfCandidate = async () => {
    try {
      setIsLoading(true);
      await cancelIndividualBooking({
        positionId: bookingDetail.booking.positionId,
        seekerId: bookingDetail.booking.seekerId,
      });
      onClose();
      bookingDetailViewClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AlertDialog size="lg" isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            <Box display="flex">
              <Box>
                <Flex
                  direction="column"
                  alignItems="center"
                  justify="center"
                  marginTop={1}
                  className="is-accepted-icon-for-group-event"
                  css={appointmentMessageCSS}
                >
                  <BiCalendar size={14} style={{ color: 'white' }} />
                </Flex>
              </Box>
              <Text color="#049BE5" style={{ marginTop: '2px' }}>
                {t('booking.cancelEvent')}
              </Text>
            </Box>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Box>
              {t('booking.description')}
              {showWarning && (
                <Text fontSize="14px" fontStyle="italic" color={colors.red[500]}>
                  {t('booking.warningDescription')}
                </Text>
              )}
            </Box>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              style={{ backgroundColor: '#fff', color: '#373a3c', border: '1px solid #ccc' }}
            >
              {t('booking.decline')}
            </Button>
            <Button
              data-testid="DeleteEventBtn"
              isLoading={isLoading}
              onClick={cancelBookingOfCandidate}
              colorScheme="red"
              ml={3}
            >
              {t('booking.accept')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
