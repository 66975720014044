/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons/lib';
import { IoIosRemoveCircle } from 'react-icons/io';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { SeekerContainerTabItems, useSeekerContainerContext } from '../../SeekerContainerContext';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import colors from '../../../../../styles/colors';
import { useCurrentUserProfile } from '../../../../../app/hooks/useCurrentUserProfile';
import { useMessagingViewContext } from '../../../MessagingViewContext';
import { MESSAGING_CANDIDATE_TABS, MESSAGING_TABS } from '../../../../../routes/constants';
import { SeekersPresenter } from '../../../sidebar/seekerList/seekersPresenter';
import { isCandidatePage } from '../../../../../utils/candidatesUtils';

export type UnDismissConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isMessagingView: boolean;
};

export const UnDismissConfirmationModal = ({
  isOpen,
  onClose,
  isMessagingView,
}: UnDismissConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const { selectedEngagedCandidate, setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();
  const { selectedCandidate } = useSeekerContainerContext();
  const { currentUserProfile } = useCurrentUserProfile();

  // istanbul ignore next
  const undismissCandidate = useStoreActions((actions) => actions.messaging.undismissCandidate);
  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);

  const handleAfterUnDismissModal = () => {
    if (isMessagingView) {
      if (seekerList?.dismissedCandidate && seekerList?.dismissedCandidate.length > 0) {
        const nonBlacklistedCandidates = seekerList?.dismissedCandidate.filter(
          (value) => !value.blacklisted && value.id !== selectedEngagedCandidate?.id,
        );

        setSelectedEngagedCandidate(nonBlacklistedCandidates[0]);
        setSelectedEngagedSeeker(
          nonBlacklistedCandidates[0] && {
            seekerId: nonBlacklistedCandidates[0].seeker,
            candidateId: nonBlacklistedCandidates[0].id,
          },
        );
        // istanbul ignore next
        if (isCandidatePage()) {
          window.history.pushState(
            null,
            '',
            MESSAGING_CANDIDATE_TABS(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
          );
        } else {
          window.history.pushState(
            null,
            '',
            MESSAGING_TABS(seekerList?.dismissedCandidate[0].id, SeekerContainerTabItems.Messaging),
          );
        }
      }
    }
  };

  const handleUnDismissedRequest = async () => {
    // istanbul ignore next
    if (!selectedCandidate) return;
    if (!currentUserProfile) return;
    try {
      setIsLoading(true);
      await undismissCandidate({
        positionId: selectedCandidate.position,
        seekerId: selectedCandidate.seeker,
        recruiterId: currentUserProfile.id,
      });

      handleAfterUnDismissModal();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal id="DismissConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
            <Flex direction="row" alignItems="center">
              <Flex alignItems="center" direction="column" justify="center" className="blue-circle-icon">
                <IconContext.Provider value={{ color: colors.white }}>
                  <IoIosRemoveCircle size={14} />
                </IconContext.Provider>
              </Flex>
              <Box width={2} />
              {t('messaging:actions.undismiss.confirmationModal.header')}
            </Flex>
          </ModalHeader>
          <ModalBody>{t('messaging:actions.undismiss.confirmationModal.body')}</ModalBody>
          <ModalFooter>
            <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
              {t('messaging:actions.undismiss.confirmationModal.cancelButton')}
            </Button>
            <Button data-testid="ConfirmButton" colorScheme="blue" onClick={handleUnDismissedRequest} isLoading={isLoading}>
              {t('messaging:actions.undismiss.confirmationModal.sendButton')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
