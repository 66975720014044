import React, { Fragment } from 'react';
import { Box, Tab, TabList, Text } from '@chakra-ui/core';
import {
  RiBriefcase2Line,
  RiCalendar2Line,
  RiCalendarCheckLine,
  RiCodeBoxLine,
  RiDatabase2Line,
  RiLinkUnlink,
  RiSettingsLine,
  RiTeamLine,
  RiUploadCloud2Line,
  RiTranslate,
} from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import { GrConfigure, GrGallery } from 'react-icons/gr';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { TbWebhook } from 'react-icons/tb';

import { useTranslation } from 'react-i18next';
import RenderSubmenuWithTree from '../common/RenderSubmenuWithTree/RenderSubmenuWithTree';
import { AccountMenuItemEnabledCheck, AdministrationMenuItemEnum } from './AdministrationMenuItemEnum';
import { AdministrationTabsParams } from '../../routes/constants';

export type AdministrationSidebarPropsType = {
  accountMenuItemEnabledCheck: AccountMenuItemEnabledCheck;
};

export const AdministrationSidebar = ({ accountMenuItemEnabledCheck }: AdministrationSidebarPropsType): JSX.Element => {
  const { t } = useTranslation('administration');
  const { tab } = useParams<AdministrationTabsParams>();

  const administrationMenuList = {
    mainMenuItems: [
      {
        id: AdministrationMenuItemEnum.RECRUITER_MANAGEMENT,
        label: t('administrationSideBarMenuItems.recruiterManagement'),
        icons: <RiCalendarCheckLine style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: accountMenuItemEnabledCheck.isRecruiterManagementFeatureEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.TEAM_LOW_AVAILABILITY,
        label: t('administrationSideBarMenuItems.lowAvailability'),
        icons: <RiCalendar2Line style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: accountMenuItemEnabledCheck.isTeamAvailabilityFeatureEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.CALENDAR,
        label: t('administrationSideBarMenuItems.appointmentCalendarLabel'),
        icons: <RiCalendarCheckLine style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: !accountMenuItemEnabledCheck.isRecruiterManagementFeatureEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.POSITION_MANAGEMENT,
        label: t('administrationSideBarMenuItems.positionManagement'),
        icons: <RiDatabase2Line style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: accountMenuItemEnabledCheck.isPositionManagementFeatureEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.TEAM_MANAGEMENT,
        label: t('team:teamInfoTitle'),
        icons: <RiTeamLine style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: accountMenuItemEnabledCheck.isTeamManagementFeatureEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.VIDEO_GALLERY,
        label: t('administrationSideBarMenuItems.videoGallery'),
        icons: <GrGallery style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: accountMenuItemEnabledCheck.isVideoGalleryEnabled,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
    ],
    subMenuItems: [
      {
        id: AdministrationMenuItemEnum.CERTN_INTEGRATION,
        label: t('administrationSideBarMenuItems.certnIntegration'),
        icons: <RiSettingsLine style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: Boolean(accountMenuItemEnabledCheck.isCertnEnabled),
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.JOB_LISTING_URL,
        label: t('administrationSideBarMenuItems.jobListingUrl'),
        icons: <RiLinkUnlink style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: Boolean(accountMenuItemEnabledCheck.isWebAppyEnabled),
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.UPLOAD_COMPANY_LOGO,
        label: t('administrationSideBarMenuItems.uploadCompanyLogo'),
        icons: <RiUploadCloud2Line style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.UNRESPONSIVE_PERIOD,
        label: t('administrationSideBarMenuItems.unresponsivePeriod'),
        icons: <AiOutlineFieldTime style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.HIRE_FORM_API_KEY,
        label: t('administration:hireFormAPIKey.label'),
        icons: <RiCodeBoxLine style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: Boolean(accountMenuItemEnabledCheck.isHireFormEnabled),
        superAdminCheck: accountMenuItemEnabledCheck.isSuperAdmin,
      },
      {
        id: AdministrationMenuItemEnum.MODERN_HIRE,
        label: t('administration:modernHire.sidebarTitle'),
        icons: <RiBriefcase2Line style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: true,
        superAdminCheck: accountMenuItemEnabledCheck.isSuperAdmin,
      },
      {
        id: AdministrationMenuItemEnum.LANGUAGE_EVALUATION_PHRASES,
        label: t('administration:administrationSideBarMenuItems.languageEvaluationPhrasesLabel'),
        icons: <RiTranslate style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: true,
        superAdminCheck: true,
      },
      {
        id: AdministrationMenuItemEnum.WEB_HOOK_URL,
        label: t('administration:administrationSideBarMenuItems.webHookURL'),
        icons: <TbWebhook style={{ marginRight: '.75rem' }} />,
        remoteConfigCheck: true,
        accountConfigCheck: true,
        superAdminCheck: accountMenuItemEnabledCheck.isSuperAdmin,
      },
    ],
  };

  return (
    <Fragment>
      <TabList borderWidth={0} borderRadius={5} className="s" backgroundColor="white" p={4} mr={5} w="20%" minW="252px">
        {administrationMenuList.mainMenuItems.map(
          (menuItem) =>
            menuItem.remoteConfigCheck &&
            menuItem.accountConfigCheck &&
            menuItem.superAdminCheck && (
              <Tab
                _selected={{ color: 'blue' }}
                color={`${menuItem.id === tab ? 'blue.default' : ''}`}
                bg={`${menuItem.id === tab ? '#F3F6F9' : ''}`}
                fontWeight="700"
                mb={2}
                borderRadius={2}
                padding={0}
                data-testid={`${menuItem.id}Tab`}
                className={`${menuItem.id} ${menuItem.id === tab ? 'active-nav' : ''}`}
                key={menuItem.id}
                border={0}
              >
                <Link
                  to={`/administration${menuItem.id === AdministrationMenuItemEnum.CALENDAR ? 's' : ''}/${menuItem.id}`}
                  style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px 15px' }}
                >
                  <Box>{menuItem.icons}</Box>
                  <Text textAlign="left" whiteSpace="pre-line">
                    {menuItem.label}
                  </Text>
                </Link>
              </Tab>
            ),
        )}
        <RenderSubmenuWithTree
          parentTabIcon={<GrConfigure style={{ marginRight: '.75rem' }} />}
          parentTabHeading={
            <Box flex="1" textAlign="left" className="configuration">
              <Text fontWeight="bold">{t('administrationSideBarMenuItems.configuration')}</Text>
            </Box>
          }
          isSubMenuItemSelected={!administrationMenuList.subMenuItems.some((item) => item.id === tab)}
        >
          {administrationMenuList.subMenuItems.map(
            (menuItem, index) =>
              menuItem.remoteConfigCheck &&
              menuItem.accountConfigCheck &&
              menuItem.superAdminCheck && (
                <Tab
                  _selected={{ color: 'blue' }}
                  color={`${menuItem.id === tab ? 'blue.default' : ''}`}
                  bg={`${menuItem.id === tab ? '#F3F6F9' : ''}`}
                  width="100%"
                  fontWeight="700"
                  p={0}
                  borderRadius={2}
                  justifyContent="flex-start"
                  style={{ textAlign: 'left' }}
                  borderTopWidth={index === 0 ? '1px' : '0'}
                  borderTopColor="#ededed"
                  borderTopStyle="solid"
                  data-testid={`${menuItem.id}Tab`}
                  className={`${menuItem.id} ${menuItem.id === tab ? 'active-nav' : ''}`}
                  key={menuItem.id}
                >
                  <Link
                    to={`/administration/${menuItem.id}`}
                    style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '10px 15px' }}
                  >
                    <Box>{menuItem.icons}</Box>
                    <Text>{menuItem.label}</Text>
                  </Link>
                </Tab>
              ),
          )}
        </RenderSubmenuWithTree>
      </TabList>
    </Fragment>
  );
};

export default AdministrationSidebar;
