import { AxiosError, AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';

export class ToDoListController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getToDoList(accountId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/dashboard/todos`;
    return this.httpClient.get(path);
  }

  async updateRecruiterActionStatus(
    userId: string,
    docId: string,
    status: string,
    category: string,
    subCategory: string | null,
    candidateId: string,
  ): Promise<void> {
    const path = `/api/v1/${userId}/dashboard/updateRecuiterActionStatus`;
    await this.httpClient
      .post(path, {
        docId,
        status,
        category,
        subCategory,
        candidateId,
      })
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }
}
