import { ConfigData, GoogleUser, MicrosoftUser, User } from '../../firebase/firestore/documents/user';

export interface CurrentUserProfile {
  readonly id: string;
  readonly account: string;
}

export class CurrentUserProfilePresenter implements CurrentUserProfile {
  constructor(private readonly user: User) {}

  get id(): string {
    return this.user.id;
  }

  get fullName(): string {
    return `${this.user.firstName} ${this.user.lastName ?? ''}`;
  }

  get account(): string {
    return this.user.account;
  }

  get email(): string {
    return this.user.email;
  }

  get locale(): string {
    return this.user.locale;
  }

  get config(): ConfigData | undefined {
    return this.user.config;
  }

  get googleCalendar(): GoogleUser[] | undefined {
    return this.user.googleCalendar;
  }

  get microsoftCalendar(): MicrosoftUser[] | undefined {
    return this.user.microsoftCalendar;
  }

  get filterCandidateByDays(): number | undefined {
    return this.user.filterCandidateByDays;
  }
}
