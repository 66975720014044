export default {
  pageTitle: 'Management Overview',
  hiringOverview: {
    outstandingOffers: 'Outstanding Offers',
    hiresProcessing: 'Processing Hires',
    backgroundChecksDelayed: 'Background Checks Delayed',
    overdueApprovals: 'Overdue Approvals',
    stalledCandidates: 'Stalled Candidates',
    openRequisitions: 'Open Requisitions',
    atRisk: 'At Risk',
    positionsImpacted: 'Positions Impacted',
    distributionOfAgedRequisitions: 'Requisition Age Distribution',
    unfilledReqs: 'Unfilled Requisitions',
    past10DaysNeedDate: '> 10 days past effective date',
    loadingHiringOverview: 'Loading Management Overview',
    weekLabel: `Weeks`,
    weekAndMore: 'Weeks and more',
    value: 'Reqs: ',
  },
  hireOfferStat: {
    hireOfferStatistics: 'Hire & Offer Stats',
    hireProposed: 'Hires Proposed',
    hireApproved: 'Hires Approved',
    hireRejected: 'Hires Rejected',
    offersMade: 'Offers Made',
    offersAccepted: 'Offers Accepted',
    offersRejected: 'Offers Rejected',
    offersRevoked: 'Offers Revoked',
    hRSubmission: 'Form Submission',
    timeToFill: 'Time to Fill',
    offerMadeCount: 'Offer Made Count',
    offerRejectCount: 'Offer Rejected Count',
    offerAcceptCount: 'Offer Accepted Count',
    offerRevokedCount: 'Offer Revoked Count',
    dismissedCandidateCount: 'Dismissed Candidate Count',
    loadingOfferStatistics: 'Loading Hire & Offer Stats',
    selectDate: 'Select date',
    oneWeek: '1 week',
    twoWeek: '2 weeks',
    oneMonth: '1 month',
    threeMonth: '3 months',
    from: 'From:',
    to: 'To:',
  },
};
