/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import {
  Tooltip,
  Badge,
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Image,
  Spacer,
  Spinner,
  Stack,
  StackDivider,
  HStack,
  Text,
  VStack,
  useDisclosure,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
} from '@chakra-ui/core';
import { createIcon, DeleteIcon, StarIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';

import moment from 'moment-timezone';
import { RiUserStarLine, RiCalendarEventFill, RiMoneyDollarCircleLine } from 'react-icons/ri';
import { NoteListRenderedByEnum, NoteViewsEnum } from '../NotesViewsEnum';
import { PositionNote } from '../positionNote';
import { Note } from '../note';
import { getColorForState } from '../../../../../../utils/positionUtils';
import { NotesStateEnum } from '../useSeekerNotes';
import { chatInputHeight } from '../../../../../../styles/sizes';
import { Candidate, SeekerNoteType } from '../../../../../../firebase/firestore/documents/candidate';
import { useStoreActions, useStoreState } from '../../../../../../models/hooks';
import { isCandidateFromFollowedPosition } from '../../../../../../services/CandidateService';
import { getTranslatedNumber } from '../../../../../../utils/localizedNumber';
import { ReactComponent as _FeedBackIcon } from '../../../../../../assets/img/feedback.svg';
import colors from '../../../../../../styles/colors';
import { DeleteNoteConfirmationModal } from '../DeleteNoteConfirmationModal';

const FeedBackIcon = React.memo(_FeedBackIcon);

const MotionStack = motion.custom(Stack);

export const notesCSS = css`
  height: 100%;
  grid-column: midSide / rightSide;
  grid-row: sub2 / bottom;
  display: grid;
  grid-template-rows: 1fr ${chatInputHeight};
  overflow-y: scroll;

  .text-style {
    background-color: ${colors.blue.default};
    color: white !important;
    border-radius: 5px;
    text-align: center;
    width: auto;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .text-style-start-date {
    background-color: ${colors.blue.default};
    color: white !important;
    border-radius: 5px;
    text-align: center;
    width: auto;
    padding-top: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .margin-style {
    margin-right: 0.8rem;
    color: ${colors.gray['500']};
  }

  .note-body {
    div p:last-child {
      margin-bottom: 0;
    }

    .note-content {
      h1 {
        font-size: 1.5rem;
      }

      h2 {
        font-size: 1.2rem;
      }

      h3 {
        font-size: 1rem;
      }

      h1,
      h2,
      h3 {
        font-weight: 700;
      }

      a {
        text-decoration: underline;
      }

      p,
      ul,
      ol {
        font-size: 14px;
      }

      ul,
      ol {
        padding-left: 30px;
      }
    }
  }
`;

const childrenVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -200 },
    },
  },
  hidden: {
    y: -10,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

export const WriteIcon = createIcon({
  displayName: 'WriteIcon',
  viewBox: '0 0 16 16',
  d:
    'M7.675 3.14356l3.1815 3.18225-7.425 7.42429H.25v-3.1823l7.425-7.42499v.00075zm1.0605-1.0605L10.3263.491563c.1406-.140604.3313-.21959.5302-.21959.1989 0 .3896.078986.5303.21959l2.1217 2.121747c.1406.14065.2196.33138.2196.53025 0 .19888-.079.38961-.2196.53025L11.917 5.26456l-3.1815-3.1815z',
});

export type NotesProps = BoxProps & {
  notes?: PositionNote[];
  state: NotesStateEnum;
  setActiveView: (page: NoteViewsEnum, note?: PositionNote, noteId?: string, index?: number) => void;
  selectedCandidate?: Candidate;
  isNoteDeleteFeatureEnabled: boolean;
  noteWrapperStyle?: React.CSSProperties;
  noteListRenderedBy?: NoteListRenderedByEnum;
};

export const NoteList = ({
  isNoteDeleteFeatureEnabled,
  notes,
  state,
  setActiveView,
  selectedCandidate,
  noteWrapperStyle,
  noteListRenderedBy,
}: NotesProps): JSX.Element | null => {
  const { t, i18n } = useTranslation(['notes', 'candidateStatus']);
  const appUserId = useStoreState((s) => s.app.user?.id);
  const [notePositionId, setNotePositionId] = useState('');
  const [noteIndex, setNoteIndex] = useState(0);
  const ratingStars: number[] = Array.from({ length: 5 }, (_, i) => i);
  const { updateRatingFor } = useStoreActions(/* istanbul ignore next */ (actions) => actions.interview);

  const {
    isOpen: isDeleteNoteConfimationModalOpen,
    onOpen: onDeleteNoteConfirmationModalOpen,
    onClose: onDeleteNoteConfirmationModalClose,
  } = useDisclosure();

  const deleteNoteAction = (positionId: string, activeNoteIndex: number) => {
    // istanbul ignore next
    if (!selectedCandidate) return;
    setNotePositionId(positionId);
    setNoteIndex(activeNoteIndex);
    onDeleteNoteConfirmationModalOpen();
  };

  return (
    <React.Fragment>
      <DeleteNoteConfirmationModal
        isOpen={isDeleteNoteConfimationModalOpen}
        onClose={onDeleteNoteConfirmationModalClose}
        setActiveView={setActiveView}
        accountId={selectedCandidate?.account}
        positionId={notePositionId}
        seekerId={selectedCandidate?.seeker}
        noteIndex={noteIndex}
      />
      <Grid css={notesCSS} padding={{ base: 4, md: 8 }} style={noteWrapperStyle}>
        {state === NotesStateEnum.UNDEFINED && (
          <Center>
            <Flex direction="column" data-testid="NoNotes" align="center">
              <Image src="/addnote.svg" w="260px" h="220px" alt="No Notes" />
              <Box maxW="32rem">
                <Heading fontSize="lg" mt={4} textAlign="center">
                  {t('noNotes')}
                </Heading>
                <Text fontSize="md" mt={2} textAlign="center">
                  {noteListRenderedBy === NoteListRenderedByEnum.RECRUITERS_CANDIDATE_TABLE_VIEW
                    ? t('recruiterManagement:recruitersCandidateTable.viewNotesModal.noNotesMessage')
                    : t('noNotesMessage')}
                </Text>
              </Box>
            </Flex>
          </Center>
        )}
        {state === NotesStateEnum.LOADING && (
          <Center>
            <Spinner color={colors.blue.default} data-testid="Spinner" />
          </Center>
        )}
        {state === NotesStateEnum.READY && (
          <Stack spacing={8} data-testid="NoteList">
            {notes?.map((notePosition: PositionNote) => (
              <MotionStack
                variants={childrenVariants}
                spacing={4}
                style={{ marginBottom: '-1rem' }}
                key={notePosition.positionId}
              >
                <Box
                  p={4}
                  borderRadius="sm"
                  className="note-block"
                  style={
                    noteListRenderedBy === NoteListRenderedByEnum.RECRUITERS_CANDIDATE_TABLE_VIEW
                      ? { borderWidth: '0' }
                      : { borderWidth: '1px' }
                  }
                >
                  <Flex className="note-header">
                    <Box>
                      <Heading as="h3" className="position" fontSize="18px">
                        {notePosition.positionName}
                      </Heading>
                      <Text color="black" fontSize="sm" className="location">
                        {notePosition.location}
                      </Text>
                    </Box>
                    <Spacer />
                    <Box>
                      <Flex alignItems="center" className="status-block">
                        <Badge background={getColorForState(notePosition?.status).color} color="white">
                          {t(`candidateStatus:${notePosition.getCandidateStatus()}`)}
                        </Badge>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex>
                    <VStack divider={<StackDivider borderColor="gray.50" />} spacing={4} align="stretch" width="100%">
                      {notePosition.notes.map((n: Note) => (
                        <Grid data-testid={n.id} key={n.id}>
                          {Array.isArray(n.note) ? (
                            n.note?.map((note, index, { length }) => {
                              return (
                                <Grid
                                  key={`${n.id}_${note.originalIndex}`}
                                  data-testid={`${n.id}_${note.originalIndex}`}
                                  gap={4}
                                  style={{
                                    paddingTop: '0.5rem',
                                    borderBottom: index + 1 === length ? '' : /* istanbul ignore next */ '1px solid #D3F1FF',
                                  }}
                                >
                                  <Box display="flex" justifyContent="space-between">
                                    <HStack spacing="2rem">
                                      <Flex direction="row" justify="space-between">
                                        <Tooltip hasArrow label={t('noteList.recruiterName')}>
                                          <span>
                                            <RiUserStarLine size={20} className="margin-style" />
                                          </span>
                                        </Tooltip>
                                        <Text fontSize="0.875rem" mb="0" color="gray.500">
                                          {n.recruiterName}
                                        </Text>
                                      </Flex>
                                      <Flex direction="row" justify="space-between">
                                        <Tooltip hasArrow label={t('noteList.updatedDate')}>
                                          <span>
                                            <RiCalendarEventFill size={20} className="margin-style" />
                                          </span>
                                        </Tooltip>
                                        <Text fontSize="0.875rem" mb="0" color="gray.500">
                                          {moment(note?.lastEdited).format('ll')}
                                        </Text>
                                      </Flex>

                                      {note.type && (
                                        <Flex direction="row" justify="space-between">
                                          <Tooltip hasArrow label={t('noteList.noteType')}>
                                            <span>
                                              <FeedBackIcon className="margin-style" width="20px" height="16px" />
                                            </span>
                                          </Tooltip>
                                          <Text
                                            data-testid="NoteType"
                                            className="text-style"
                                            color="white"
                                            fontSize="0.875rem"
                                            mb="0"
                                          >
                                            {t(`noteType.${note.type}`)}
                                          </Text>
                                        </Flex>
                                      )}
                                      {note.type === SeekerNoteType.FEEDBACK && n.recruiterRating && (
                                        <Flex direction="row" justify="space-between">
                                          <Box>
                                            {ratingStars.map((k) => {
                                              const val: number = k + 1;
                                              let color = '#FF6600';
                                              const rating = n.recruiterRating;
                                              /* istanbul ignore else */
                                              if ((rating as any) < val) {
                                                color = '#FFFFFF';
                                              }

                                              return (
                                                <Tooltip hasArrow label={t('noteList.interveiwRating')} key={k}>
                                                  <span>
                                                    <StarIcon
                                                      mb="0.1rem"
                                                      boxSize={3}
                                                      color={color}
                                                      style={{ marginRight: '12px', cursor: 'pointer', stroke: '#FF6600' }}
                                                      data-testid="RatingStars"
                                                    />
                                                  </span>
                                                </Tooltip>
                                              );
                                            })}
                                          </Box>
                                        </Flex>
                                      )}
                                      {note.additionalContextData && (
                                        <Flex direction="row" justify="space-between">
                                          <Tooltip hasArrow label={t('noteList.salary')}>
                                            <span>
                                              <RiMoneyDollarCircleLine size={20} className="margin-style" />
                                            </span>
                                          </Tooltip>
                                          {note.additionalContextData?.salary && (
                                            <Text fontSize="0.875rem" mb="0" color="gray.500">
                                              {`$  ${getTranslatedNumber(note.additionalContextData?.salary, i18n.language)} `}
                                              {note.additionalContextData?.salaryUnit &&
                                              note.additionalContextData?.salaryUnit !== 'custom'
                                                ? note.additionalContextData?.salaryUnit
                                                : note.additionalContextData?.customSalaryUnit}
                                            </Text>
                                          )}
                                        </Flex>
                                      )}

                                      {note?.additionalContextData?.dateOfJoining && (
                                        <Flex direction="row" justify="space-between">
                                          <Text
                                            data-testid="NoteType"
                                            className="text-style-start-date"
                                            color="white"
                                            fontSize="0.875rem"
                                            mr="2"
                                          >
                                            <p>{t('messaging:actions.hire.startDate')}</p>
                                          </Text>
                                          <Text fontSize="0.875rem" mb="0" color="gray.500">
                                            {moment(note?.additionalContextData?.dateOfJoining).format('ll')}
                                          </Text>
                                        </Flex>
                                      )}
                                    </HStack>
                                    {isNoteDeleteFeatureEnabled && notePosition.getNoteById(n.id)?.isEditable && (
                                      <Flex direction="row" justify="space-between" style={{ marginTop: '-0.2rem' }}>
                                        <Tooltip hasArrow label={t('createNotes.deleteNote')}>
                                          <span>
                                            <Box
                                              data-testid="delete-button"
                                              as="button"
                                              onClick={() => deleteNoteAction(notePosition.positionId, note.originalIndex)}
                                              cursor="pointer"
                                            >
                                              <DeleteIcon size={28} className="margin-style" />
                                            </Box>
                                          </span>
                                        </Tooltip>
                                      </Flex>
                                    )}
                                  </Box>
                                  <Box>
                                    <Flex
                                      data-testid="array-note"
                                      direction="row"
                                      justify="flex-start"
                                      onClick={() => {
                                        if (isCandidateFromFollowedPosition(selectedCandidate, appUserId)) {
                                          setActiveView(NoteViewsEnum.NOTE_DETAIL, notePosition, n.id, note.originalIndex);
                                        }
                                      }}
                                    >
                                      <Tooltip
                                        hasArrow
                                        label={t('noteDetail.editNote')}
                                        isDisabled={noteListRenderedBy === NoteListRenderedByEnum.RECRUITERS_CANDIDATE_TABLE_VIEW}
                                      >
                                        <Text
                                          fontSize="0.75rem"
                                          className="note-body"
                                          style={{ cursor: 'pointer' }}
                                          paddingBottom="15px"
                                        >
                                          <Box w="100%" className="note-content">
                                            <div dangerouslySetInnerHTML={{ __html: note.note }} />
                                          </Box>
                                        </Text>
                                      </Tooltip>
                                    </Flex>
                                  </Box>
                                </Grid>
                              );
                            })
                          ) : (
                            <Grid
                              gap={4}
                              style={{
                                paddingTop: '12px',
                                paddingBottom: '12px',
                              }}
                            >
                              <Box display="flex" justifyContent="space-between">
                                <HStack spacing="2rem">
                                  <Flex direction="row" justify="space-between">
                                    <Tooltip hasArrow label={t('noteList.recruiterName')}>
                                      <span>
                                        <RiUserStarLine size={20} className="margin-style" />
                                      </span>
                                    </Tooltip>
                                    <Text fontSize="0.875rem" mb="0.1rem" color="gray.500">
                                      {n.recruiterName}
                                    </Text>
                                  </Flex>
                                  <Flex direction="row" justify="space-between">
                                    <Tooltip hasArrow label={t('noteList.updatedDate')}>
                                      <span>
                                        <RiCalendarEventFill size={20} className="margin-style" />
                                      </span>
                                    </Tooltip>
                                    <Text fontSize="0.875rem" mb="0.1rem" color="gray.500">
                                      {n.getUpdatedDate()}
                                    </Text>
                                  </Flex>
                                  {n.type && (
                                    <Flex direction="row" justify="space-between">
                                      <Tooltip hasArrow label={t('noteList.noteType')}>
                                        <span>
                                          <FeedBackIcon className="margin-style" width="20px" height="16px" />
                                        </span>
                                      </Tooltip>
                                      <Text
                                        data-testid="NoteType"
                                        className="text-style"
                                        color="gray.500"
                                        fontSize="0.875rem"
                                        mb="0.1rem"
                                      >
                                        {t(`noteType.${n.type}`)}
                                      </Text>
                                    </Flex>
                                  )}
                                </HStack>
                                {isNoteDeleteFeatureEnabled && notePosition.getNoteById(n.id)?.isEditable && (
                                  <Flex direction="row" justify="space-between" style={{ marginTop: '-0.2rem' }}>
                                    <Tooltip hasArrow label={t('createNotes.deleteNote')}>
                                      <span>
                                        <Box
                                          data-testid="single-note-delete-button"
                                          as="button"
                                          onClick={() => deleteNoteAction(notePosition.positionId, -1)}
                                          cursor="pointer"
                                        >
                                          <DeleteIcon size={28} className="margin-style" />
                                        </Box>
                                      </span>
                                    </Tooltip>
                                  </Flex>
                                )}
                              </Box>

                              <Box>
                                <Flex
                                  data-testid="single-note"
                                  direction="row"
                                  justify="flex-start"
                                  onClick={() => {
                                    if (isCandidateFromFollowedPosition(selectedCandidate, appUserId)) {
                                      setActiveView(NoteViewsEnum.NOTE_DETAIL, notePosition, n.id);
                                    }
                                  }}
                                >
                                  <Tooltip hasArrow label={t('noteDetail.editNote')}>
                                    <Text fontSize="0.75rem" className="note-body" mt={-1} style={{ cursor: 'pointer' }}>
                                      <Box w="100%" className="note-content">
                                        <div dangerouslySetInnerHTML={{ __html: n.note! }} />
                                      </Box>
                                    </Text>
                                  </Tooltip>
                                </Flex>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      ))}
                    </VStack>
                  </Flex>
                </Box>
              </MotionStack>
            ))}
          </Stack>
        )}
        <div className="red">
          <Menu closeOnSelect={false}>
            <MenuButton
              as={Button}
              display={isCandidateFromFollowedPosition(selectedCandidate, appUserId) ? 'flex' : 'none'}
              data-testid="compose-note-button"
              leftIcon={<WriteIcon />}
              color={colors.blue.default}
              variant="ghost"
              className="btn-write-note"
              disabled={Boolean(
                selectedCandidate &&
                  (selectedCandidate.deactivated || selectedCandidate.scheduledDeletion || selectedCandidate.dismissed),
              )}
            >
              {t('noteList.compose')}
            </MenuButton>
            <MenuList>
              <MenuItem
                minWidth="240px"
                onClick={() => setActiveView(NoteViewsEnum.NOTE_FORM)}
                data-testid="compose-new-note-button"
              >
                {t('common:tabs.notes')}
              </MenuItem>
              <MenuItem
                minWidth="240px"
                data-testid="compose-feedback-note-button"
                onClick={() => {
                  if (selectedCandidate) {
                    updateRatingFor({
                      name: '',
                      seekerId: selectedCandidate?.seeker,
                      positionId: selectedCandidate?.position,
                      selectedCandidate,
                    });
                  }
                }}
              >
                {t('common:interviewFeedBack')}
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </Grid>
    </React.Fragment>
  );
};
