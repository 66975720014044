import { NotificationTypes } from './notificationTypes';
import { LocalizedString } from './localizedString';
import { AppointmentStatus } from './appointmentStatus';
import { AppointmentType } from './candidateAudit';
import { HireFormTypeEnum } from '../../../modules/messaging/seeker/header/actionsDropdown/HireFormTypeEnum';
import { ModernHireStatusList } from './modernHireTypes';

export enum FitStatus {
  GOOD = 'GOOD',
  MEDIUM = 'MEDIUM',
  BAD = 'BAD',
}
export type CommuteData = {
  durationInSeconds: number;
  distanceInMiles: number;
  calculationsCount: number;
  travelMode: string;
};

export type Candidate = {
  account: string;
  email: string;
  accountNotification?: AccountNotification;
  appointment?: Appointment;
  assignedRecruiters: string[];
  businessName: string;
  computedStatus?: {
    category?: string;
    lastUpdate: Date;
    status: CandidateStatus;
  };
  hireDetailsStatus?: string;
  offerAcceptanceStatus?: {
    offerAccepted: boolean;
    reason?: string;
  };
  offerStatus?: string;
  hired?: boolean;
  knockedOut?: boolean;
  knockedOutAt?: Date;
  seekerLookingForWork?: boolean;
  contactStatus?: ContactStatus;
  system?: SystemStatus;
  dismissed: boolean;
  dismissedReason?: string;
  rejectedReason?: string;
  accountExcluded?: boolean;
  displayProfilePicture?: boolean;
  firstName: string;
  id: string;
  jobTitle: LocalizedString;
  lastActivity: Date;
  lastName: string;
  monetized: boolean;
  position: string;
  positionOpen: boolean;
  questionResponses: QuestionResponse[];
  freeFormQuestionResponses?: FreeFormQuestionResponse[];
  multipleChoiceQuestionResponses?: MultipleChoiceQuestionResponse[];
  seeker: string;
  insights?: {
    flexibleTransportation?: boolean;
    highSalaryDemand?: boolean;
  };
  preferredSalary?: {
    unit: string;
    value: number;
  };
  seekerNotification?: SeekerNotification;
  screeningDetails?: Screening;
  automation?: CandidateAutomation;
  recruiterInterviewReview?: {
    [assignedRecruiters: string]: {
      lastEdited: Date;
      rating: string;
      review: string;
      assessmentReason: string;
      interviewType: AppointmentType;
      date: Date;
    };
  };
  ghostingStatus?: {
    status: GhostingStatus;
    lastUpdatedDate: Date;
  };
  lastMessageAt?: { fromRecruiter?: Date; fromSeeker?: Date };
  deactivated?: boolean;
  scheduledDeletion?: boolean;
  experience?: {
    maximumExperience: number;
    minimumExperience: number;
    totalExperience: number;
  };
  availability?: {
    day: boolean;
    night: boolean;
    evening: boolean;
  };
  blacklisted?: boolean;
  availabilityUpdatedAt?: Date;
  modernHire?: {
    status: ModernHireStatusList;
    startedDate?: Date;
    completedData?: Date;
    score?: number;
  };
  hireDetailsResubmissionRequest?: ResubmissionRequestFields[];
  requisitionId?: string;
  newHireData?: {
    positionId?: string;
    jobTitle?: LocalizedString;
    businessId?: string;
    businessName?: string;
  };
  newAvailabilities?: { [key: string]: { start_1: string; end_1: string } };
  availabilityMatchedRequisitions?: MatchedRequisition[];
  availabilityMatchedPositions?: MatchedRequisition[];
  availabilityFitBand?: number;
  forSorting?: Date;
  earliestAvailabilityDate?: Date;
  recording?: RecordingType;
  languageEvaluationReview?: LanguageEvaluationReview;
  recordingRequested?: boolean;
  offerMadeAt?: Date;
  hiredAt?: Date;
  hiredBy?: string;
  hiredByFullName?: string;
  offerMadeByFullName?: string;
  hiredByFormApproval?: boolean;
  anonymousRecruiterInterviewReview?: AnonymousRecruiterInterviewReview[];
  priority?: boolean;
  kioskEnabled?: boolean;
  interventionEnabled?: boolean;
  businessCompleteAddress?: string;
  bookmarked?: boolean;
  modernHireEnable: boolean;
  commuteData?: CommuteData;
  recordingStatus?: RecordingStatus;
};

export enum RecordingStatus {
  REQUESTED = 'REQUESTED',
  RE_REQUESTED = 'RE_REQUESTED',
  IN_REVIEW = 'IN_REVIEW',
  REVIEW_COMPLETED = 'REVIEW_COMPLETED',
}
export type AnonymousRecruiterInterviewReview = {
  rating?: number;
  review?: string;
  date?: Date;
  positionId?: string;
  businessId?: string;
  interviewType?: AppointmentType;
  collaboratorName?: string;
  assessment?: string;
  assessmentReason?: string;
};

export type RecordingType = {
  createdAt: Date;
  language: string;
  metadata: {
    audioSource: number;
    bitsPerSample: number;
    channels: number;
    mimeType: string;
    sampleRate: number;
  };
  phraseId: string;
  phrase: string;
  url: string;
};

export type MatchedRequisition = {
  requisitionId: string;
  totalPointsPerRequisition: number;
  fit?: FitStatus;
};

export type ResubmissionRequestFields = {
  comment: string;
  field: string;
};

export enum GhostingStatus {
  NOTIFICATION_SENT = 'NOTIFICATION_SENT',
  GHOSTED = 'GHOSTED',
  UNGHOSTED = 'UNGHOSTED',
}

export type Screening = {
  applicantId: string;
  reportStatus: string;
  screeningStatus: string;
  status: string;
  lastUpdatedDate?: Date;
};

export type SeekerIsTyping = {
  lastUpdate: Date | undefined;
};

export type EngagedCandidate = Candidate & {
  monetized: true;
  email: string;
  phone: string;
  shouldShowInFilter?: boolean;
};

export type Appointment = PositionBasedAppointment | RecruiterBasedAppointment;

export type PositionBasedAppointment = BaseAppointment & {
  period?: string;
  appointmentAvailabilityId?: string;
  bookingId?: string;
  recruiterName?: string;
};

export type RecruiterBasedAppointment = BaseAppointment & {
  bookingId?: string;
  appointmentAvailabilityId?: string;
  positionId?: string;
  joinedRecruiterId?: string;
  recruiterName?: string;
};

type BaseAppointment = {
  status?: AppointmentStatus;
  toDate?: Date;
  fromDate?: Date;
  type?: AppointmentType;
  inPerson?: boolean;
};

export type AccountNotification = {
  lastSeekerMessageAt?: Date;
  lastUpdatedAt: Date;
  subscribers: string[];
  status: { [key: string]: { read: boolean; lastSeenAt: Date; unreadMessages: number } };
  lastNotificationType?: NotificationTypes;
};

export type SeekerNotification = {
  lastEmployerMessageAt?: Date;
  lastNotificationType?: string;
  lastSeenAt?: Date;
  lastUpdatedAt?: Date;
  read?: boolean;
  unreadMessages?: number;
};

export enum CandidateCategory {
  Other = 'OTHER',
}

export enum CandidateStatus {
  Dismissed = 'DISMISSED',
  NotLookingForWork = 'NOT_LOOKING_FOR_WORK',
  Hired = 'HIRED',
  HiredOnAnotherPosition = 'HIRED_ON_ANOTHER_POSITION',
  ContactRequested = 'CONTACT_REQUESTED',
  ContactRequestAccepted = 'CONTACT_REQUEST_ACCEPTED',
  ContactRequestDeclined = 'CONTACT_REQUEST_DECLINED',
  AppointmentRequested = 'APPOINTMENT_REQUESTED',
  AppointmentAccepted = 'APPOINTMENT_ACCEPTED',
  AppointmentDeclined = 'APPOINTMENT_DECLINED',
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
  AppointmentReschedulingNeeded = 'APPOINTMENT_RESCHEDULING_NEEDED',
  Bookmarked = 'BOOKMARKED',
  OfferMade = 'OFFER_MADE',
  OfferRejected = 'OFFER_REJECTED',
  Unhired = 'UNHIRED',
  Liked = 'LIKED',
  Unknown = 'UNKNOWN',
  InterestRevoked = 'INTEREST_REVOKED',
  Blacklisted = 'BLACKLISTED',
  HireInReview = 'HIRE_IN_REVIEW',
}

export type QuestionResponse = {
  id: string;
  answer: boolean;
  preferredAnswer: boolean;
  knockout: boolean;
  isActive?: boolean;
  text?: string;
};

export type FreeFormQuestionResponse = {
  id: string;
  answer?: string;
  isActive?: boolean;
};

export type MultipleChoiceQuestionResponse = {
  id: string;
  answers: {
    id: string;
    answer: string;
    preferredAnswer?: boolean;
  }[];
  isAnsweredCorrectly: boolean;
  knockout: boolean;
  question: string;
  preferredAnswers?: string[];
  options: {
    id: string;
    text: string;
    wasAnswered: boolean;
  }[];
};

export enum SeekerNoteType {
  NOTE = 'NOTE',
  FEEDBACK = 'FEEDBACK',
  OFFER_DETAILS = 'OFFER_DETAILS',
  OFFER_REJECTED_DETAILS = 'OFFER_REJECTED_DETAILS',
  HIRE_DETAILS = 'HIRE_DETAILS',
  UNHIRE_DETAILS = 'UNHIRE_DETAILS',
}

export type SeekerNote = {
  id: string;
  lastEdited?: Date;
  note?: string;
  notes?: SeekerNoteV2Item[];
  position: string;
  type?: SeekerNoteType | undefined;
  recruiter: string;
  recruiterName: string;
  seeker: string;
};

export type SeekerNoteV2Item = {
  note: string;
  lastEdited: Date;
  type: SeekerNoteType | undefined;
  isRemoved?: boolean | undefined;
  additionalContextData?: {
    salary: number;
    salaryUnit?: string;
    customSalaryUnit?: string;
    dateOfJoining?: Date;
  };
  originalIndex: number;
};

export type BackgroundChecks = {
  request_instant_verify_education: boolean;
  request_instant_verify_employment: boolean;
  request_enhanced_identity_verification: boolean;
  request_criminal_record_check: boolean;
  request_enhanced_criminal_record_check: boolean;
  request_us_criminal_record_check_tier_1: boolean;
  request_us_criminal_record_check_tier_2: boolean;
  request_soquij: boolean;
};

export type SystemStatus = {
  shouldCreateAppointmentRequest: boolean;
  shouldBeDismissed: boolean;
};

export enum ContactRequestStatus {
  requested = 'requested',
  accepted = 'accepted',
  declined = 'declined',
  revoked = 'revoked',
}

export enum AudioRatingType {
  fluency = 'fluency',
  pronunciation = 'pronunciation',
  accuracy = 'accuracy',
}

export enum LanguageEvaluationRating {
  EXPERT = 'EXPERT',
  COMPETENT = 'COMPETENT',
  LIMITED = 'LIMITED',
  OCCASIONAL = 'OCCASIONAL',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

export enum LanguageEvaluationStatus {
  PASS = 'PASS',
  FAIL = 'FAIL',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
}

export type LanguageEvaluationReview = {
  language: string; // en, fr
  phraseId: string;
  phrase: string;
  rating: LanguageEvaluationRating;
  fluency: number;
  pronunciation: number;
  accuracy: number;
  status: LanguageEvaluationStatus;
  automated?: boolean;
};

export type ContactStatus = {
  status: ContactRequestStatus;
  text?: string;
  declinedAt?: Date | null;
  sentAt?: Date | null;
  requestcount: number;
  toDate?: Date | null;
};

export type CandidateAutomation = {
  automatedContactRequest: boolean;
  automatedMonetization: boolean;
  automatedBookmark: boolean;
  automatedAppointmentRequest: boolean;
};

export type SeekerHireDetail = {
  type: HireFormTypeEnum;
  id: string;
  address: {
    addressLineOne?: string;
    addressLineTwo: string;
    city?: string;
    country?: string;
    postalCode: string;
    region?: string;
  };
  positionId: string;
  createdAt: Date;
  gender: string;
  name: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  correspondenceLanguage: string;
  optionalPhoneNumber: string;
  postalCode: string;
  positionName: null | string;
  seekerId: string;
  dateOfBirth?: Date;
  socialInsuranceNumber: string;
  emergencyContact: { firstName: string; lastName: string; phoneNumber: string };
  apartment?: string;
  startingDate: Date;
  wage: { value: number; unit: string; customUnit?: string };
  phoneNumber: string;
  fullTime: boolean;
  chequebookImageUrl: string | string[];
  updatedAt: Date;
  businessName: string;
  business: {
    address: string;
    name: string;
  };
  accountId: string;
  signatureImageUrl: string | string[];
  note?: string;
  endDate?: Date;
  partTimeHours: string;
  miscellaneous?: {
    contractDetailsWordings: string;
  };
  workPermitEndDate?: Date;
  workPermitImageUrl?: string | string[];
  documentIdImageUrl?: string | string[];
  canadianBankAccountDetails?: CanadianBankAccountDetails;
  americanBankAccountDetails?: AmericanBankAccountDetails;
};

export type CandidateHireFormDetailResponse = {
  seekerHireDetail: SeekerHireDetail;
  positionName: null | string;
  businessName: string;
};

export type CandidateHireFormDetailResponseWithError = {
  code: string;
  status: number;
  title: string;
  traceId: string;
};

export type CanadianBankAccountDetails = {
  branchTransitNumber: string;
  financialInstitutionNumber: string;
  bankAccountNumber: string;
};

export type AmericanBankAccountDetails = {
  routingNumber: string;
  bankAccountNumber: string;
};
