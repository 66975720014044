export default {
  noRequisition: 'This position has no open requisitions. Add requisitions to continue.',
  requisitionDetails: 'Requisition Details',
  partTime: 'Part Time',
  fullTime: 'Full Time',
  seasonal: 'Seasonal',
  effectiveTime: 'Effective Date :',
  wages: 'Salary: ',
  seasonalEndDate: 'End Date:',
  shifts: 'Shifts',
  contractedHours: 'Contracted Hours',
  minimum: 'Minimum',
  maximum: 'Maximum',
  guaranteedHours: 'Guaranteed Hours',
  requisitionID: 'Requisition',
  offerNotes: 'Offer Notes',
  selectRequisition: `Select Requisition`,
  locationAndPositionChanged: "This candidate has been sent an offer for a different position than the one they've applied for.",
  hourly: `Hourly`,
  annual: `Annual`,
  weekly: `Weekly`,
  conformAlert: {
    title: 'Warning',
    subtitle:
      'You are about to change the position this candidate is being hired for. Are you sure you want to make these changes?',
    close: 'Close',
    continue: 'Confirm',
  },
  partTimeHoursPerWeek: 'Hours/Week',
  couldNotLoadRequisition: 'Could not load requisition at moment.',
  OPEN: 'Open',
  CANCELLED: 'Cancelled',
  FILLED: 'Filled',
  PROCESSING: 'Processing',
  associatedCandidate: 'Selected Candidate',
  effectiveTimeInfo: "This requisition's start date is {{requisitionActiveThresholdDays}} days from now.",
  candidateRequisitionMatch: 'Candidate and Position Availability Overlap',
  compare: 'Comparison',
  days: 'Days',
  candidateAvailability: 'Candidate Availability',
  candidateAvailabilitiesInfo:
    "This is the intersection between the candidate's availability, and the shift needs for the requisition.",
  requisitionShifts: 'Desired Availability',
  requisitionRiskDashboard: 'Requisition Dashboard',
  requisitionInsights: 'Requisition Details',
  riskRequisitionDetailTable: {
    header: {
      requisitionId: 'Requisition ID',
      status: 'Status',
      createdUpdated: 'Created / Last Updated',
      hireBy: 'Hire By',
      locationPosition: 'Location & Position',
    },
    riskStatusText: {
      safe: 'On Track',
      atRisk: 'At Risk',
      overdue: 'Overdue',
    },
    overdueDaysText: 'Overdue by {{riskDays}} days',
    riskDaysText: '{{riskDays}} days left to hire',
  },
  requisitionByLocation: 'Requisitions By Location',
  businessRequisitionRiskStatus: {
    safe: 'On Track',
    atRisk: 'At Risk',
    overdue: 'Overdue',
  },
  requisitionCount: {
    unfilled: 'Open',
    atRisk: 'At Risk',
    overdue: 'Overdue',
    locationImpacted: 'Locations Impacted',
    positionImpacted: 'Positions Impacted',
  },
  pagination: {
    showingResult: 'Showing {{currentItemsLength}} of {{totalItemsLength}} requisitions',
  },
};
