import { AxiosError, AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { NewUserInfo } from '../modules/meet/MeetTokenResponse';
import { AppointmentType } from '../firebase/firestore/documents/position';

export class MeetController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async joinInterview(candidateId: string): Promise<AxiosResponse | undefined> {
    const path = '/api/v1/video/initialize';
    const body = { candidateId };
    return this.httpClient
      .post(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async joinMeet(groupEventId: string, participantId: string): Promise<AxiosResponse> {
    const path = '/api/v1/groupEvent/video/initialize';
    const body = { groupEventId, participantId };
    return this.httpClient.post(path, body);
  }

  async leaveInterview(candidateId: string): Promise<void> {
    const path = '/api/v1/video/leave';
    const body = { candidateId };
    await this.httpClient.post(path, body);
  }

  async leaveMeet(groupEventId: string, participantId: string): Promise<void> {
    const path = '/api/v1/groupEvent/video/leave';
    const body = { groupEventId, participantId };
    await this.httpClient.post(path, body);
  }

  async verifyMeetToken(meetToken: string): Promise<AxiosResponse | undefined> {
    const path = `api/v1/groupEvent/verify?token=${meetToken}`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async verifyURLWithCode(code: string, bookingId: string): Promise<AxiosResponse | undefined> {
    const path = `api/v1/video-interview/invite/validate-code`;
    const body = { code, bookingId };
    return this.httpClient
      .post(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async getCandidateDataOfMeet(positionId: string, seekerId: string): Promise<AxiosResponse | undefined> {
    const path = `api/v1/position/${positionId}/seeker/${seekerId}`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async meetOnBoarding(
    email: string,
    isCandidate: boolean,
    newUserInfo?: NewUserInfo,
    groupEventId?: string,
  ): Promise<AxiosResponse> {
    const path = `api/v1/groupEvent/${groupEventId}/onboard`;
    const body = { email, isCandidate, newUserInfo };
    return this.httpClient.post(path, body);
  }

  async getMeetParticipants(groupEventId: string, participantId: string): Promise<AxiosResponse> {
    const path = `/api/v1/groupEvent/${groupEventId}/participants/${participantId}`;
    return this.httpClient.get(path);
  }

  async rateInterview(
    positionId: string,
    seekerId: string,
    rating: number,
    review: string,
    date: Date,
    interviewType: AppointmentType,
    businessId: string,
    assessmentReason?: string,
  ): Promise<AxiosResponse | void> {
    const path = `/api/v1/position/${positionId}/assignedRecruiters/review`;
    const assesment = rating < 2 ? 'negative' : /* istanbul ignore next */ 'positive';
    const body = { seekerId, rating, assesment, assessmentReason, date, interviewType, businessId, review };
    await this.httpClient.post(path, body);
  }

  async collaboratorRateInterview(
    positionId: string,
    seekerId: string,
    rating: number,
    review: string,
    date: Date,
    interviewType: AppointmentType,
    businessName: string,
    collaborator: string,
    assessmentReason?: string,
  ): Promise<AxiosResponse | void> {
    const path = `/api/v1/position/${positionId}/seeker/${seekerId}/collaboratorInterviewReview`;
    const assessment = rating < 2 ? 'negative' : /* istanbul ignore next */ 'positive';
    const body = {
      seekerId,
      positionId,
      rating,
      assessment,
      assessmentReason,
      date,
      interviewType,
      businessName,
      review,
      collaborator,
    };
    await this.httpClient.post(path, body);
  }

  /* istanbul ignore next */
  async getInterviewBookingDetail(bookingId: string): Promise<AxiosResponse | undefined> {
    const path = `api/v1/video-interview/booking/${bookingId}`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }
}
