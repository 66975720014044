import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Stack, Tooltip, Spinner } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import colors from '../../../../styles/colors';
import { RequisitionResponseType } from './RequisitionByLocationType';
import { TablePaginationFooter } from '../../../../app/components/TablePaginationFooter';

const SAFE_COLOR = colors.green[500];
const OVERDUE_COLOR = colors.red[500];
const RISK_COLOR = colors.yellow[500];

const AVAILABLE_COLORS = [SAFE_COLOR, RISK_COLOR, OVERDUE_COLOR];

interface RequisitionProgressProps {
  values: number[];
  availableColors: string[];
}

type RiskRequisitionBusinessDataType = {
  businessName: string;
  values: number[];
  overallCount: number;
};

const ShowLabelAndColor = (labelText: string, color: string) => {
  return (
    <Flex alignItems="center" mr={6}>
      <Box width={4} height={4} bg={color} borderRadius={4} />
      <Text ml={2}>{labelText}</Text>
    </Flex>
  );
};

const RequisitionProgress: React.FC<RequisitionProgressProps> = ({ values, availableColors }) => {
  const total = values.reduce((a, b) => a + b, 0);
  let cumulativePercentage = 0;

  return (
    <Box position="relative" height="12px" borderRadius="md" bg={colors.lavenderMist}>
      {values.map((value, index) => {
        if (value === 0) return null;

        const widthPercent = (value / total) * 100;
        const leftPercent = cumulativePercentage;

        cumulativePercentage += widthPercent;

        return (
          <Tooltip key={value} label={`${value}`} bg={availableColors[index]} placement="top">
            <Box
              key={value}
              position="absolute"
              height="12px"
              bg={availableColors[index]}
              width={`${widthPercent}%`}
              left={`${leftPercent}%`}
              borderRadius="3xl"
              cursor="pointer"
              _hover={{
                transform: 'scale(1.005)',
                zIndex: 1,
              }}
              transition="transform 0.2s ease-in-out"
            />
          </Tooltip>
        );
      })}
    </Box>
  );
};

interface RequisitionByLocationItemProps {
  businessName: string;
  values: number[];
  overallCount: number;
}

const RequisitionByLocationItem: React.FC<RequisitionByLocationItemProps> = ({ businessName, values, overallCount }) => {
  return (
    <Box marginTop="0 !important" px={6} py={4} borderTop={`1px solid ${colors.neutral[200]}`}>
      <Flex alignItems="center" mb={2}>
        <Text flex="1" color={colors.gray[700]}>
          {businessName}
        </Text>
        <Text w="30px" textAlign="right" fontWeight="bold" color={colors.gray[700]}>
          {overallCount}
        </Text>
      </Flex>
      <RequisitionProgress values={values} availableColors={AVAILABLE_COLORS} />
    </Box>
  );
};

function transformData(input: RequisitionResponseType) {
  return input?.map((item) => {
    return {
      businessName: item.businessName,
      values: [item.safeRequisitionCount, item.overdueRequisitionCount, item.riskRequisitionCount],
      overallCount: item.overallCount,
    };
  });
}

export type RequisitionByLocationProps = {
  requisitionsBusinessDetail: RequisitionResponseType;
  isRiskRequisitionBusinessDetailLoading: boolean;
};

const RequisitionByLocation = ({
  requisitionsBusinessDetail,
  isRiskRequisitionBusinessDetailLoading,
}: RequisitionByLocationProps) => {
  const { t } = useTranslation('requisition');
  const [currentFilteredItems, setCurrentFilteredItems] = useState<RiskRequisitionBusinessDataType[]>();

  const [riskRequisitionBusinessData, setRiskRequisitionBusinessData] = useState<RiskRequisitionBusinessDataType[]>();

  useEffect(() => {
    /* istanbul ignore else */
    const transformedData = transformData(requisitionsBusinessDetail);
    setRiskRequisitionBusinessData(transformedData);
  }, [requisitionsBusinessDetail]);

  return (
    <Box bg={colors.white} borderTopRadius="md" borderBottom={`1px solid ${colors.neutral[200]}`}>
      <Text fontSize="lg" fontWeight="bold" px={4} py={6} color={colors.gray[800]}>
        {t('requisitionByLocation')}
      </Text>
      {!isRiskRequisitionBusinessDetailLoading && riskRequisitionBusinessData ? (
        <>
          <Stack spacing={4}>
            {currentFilteredItems?.map((item) => (
              <RequisitionByLocationItem
                key={item.businessName}
                businessName={item.businessName}
                values={item.values}
                overallCount={item.overallCount}
              />
            ))}
          </Stack>
          <Flex
            justifyContent="center"
            pt={2}
            pb={2}
            borderTop={`1px solid ${colors.neutral[200]}`}
            borderBottom={`1px solid ${colors.neutral[200]}`}
          >
            {ShowLabelAndColor(t('businessRequisitionRiskStatus.safe'), SAFE_COLOR)}
            {ShowLabelAndColor(t('businessRequisitionRiskStatus.atRisk'), RISK_COLOR)}
            {ShowLabelAndColor(t('businessRequisitionRiskStatus.overdue'), OVERDUE_COLOR)}
          </Flex>
          <TablePaginationFooter finalListOfItems={riskRequisitionBusinessData} setCurrentItemsList={setCurrentFilteredItems} />
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" p={5}>
          <Spinner colorScheme="blue" size="lg" />
        </Flex>
      )}
    </Box>
  );
};

export default RequisitionByLocation;
