import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  Flex,
  Select,
  Button,
  Center,
  FormErrorMessage,
  useToast,
  Spacer,
} from '@chakra-ui/core';
import Joi from 'joi';
import _isNil from 'lodash/isNil';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import { OptionTypeBase, ValueType } from 'react-select';
import Bugsnag from '@bugsnag/js';
import { useTranslation } from 'react-i18next';
import { createBusinessStyle } from './CreateBusinessStyle';
import { Business, GoogleAutoCompleteValues } from '../BusinessType';
import {
  clearErrorMessage,
  errorFormat,
  FormattedError,
  getErrorMessage,
  hasErrorMessage,
  updateObject,
} from '../../../../utils/FormErrorUtils';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { phoneFormat } from '../../../../utils/PhoneUtils';
import { NotificationTypes } from '../../../../firebase/firestore/documents/notificationTypes';
import useBusinessLocations from '../useBusinessLocations';
import { RemoteConfigKey } from '../../../../firebase/remoteConfig';

export const CreateBusinessView = (props: {
  successCallBack: (business: Business) => void;
  closeCallBack: (businessId: string) => void;
  businessData: Business;
  createNewBusiness: boolean;
}): JSX.Element => {
  const { t } = useTranslation('businessManagement');
  const [savingBusiness, setSavingBusiness] = useState<boolean>(false);
  const [businessData, setBusinessData] = useState<Business>(props.businessData);
  const { user } = useStoreState((s) => s.app);
  const [formErrors, setFormErrors] = useState<FormattedError[]>([]);
  const [value, setValue] = useState<ValueType<OptionTypeBase, false>>({
    label: businessData.completeAddress,
    value: { place_id: businessData.placeId },
  });
  const toast = useToast();
  const businessLocations = useBusinessLocations();
  const { createBusiness, editBusiness } = useStoreActions((actions) => actions.businessManagement);

  const enableGroupInBusinessLocation: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_GROUP_IN_BUSINESS_LOCATION];
  });

  useEffect(() => {
    businessData.webSite = props.businessData.webSite ? props.businessData.webSite : '';
    businessData.phoneNumber = props.businessData.phoneNumber ? props.businessData.phoneNumber : '';
    businessData.state = props.businessData.state ? props.businessData.state : '';
    businessData.cityName = props.businessData.cityName ? props.businessData.cityName : '';
    businessData.zip = props.businessData.zip ? props.businessData.zip : '';
    businessData.address = props.businessData.address ? props.businessData.address : '';
    businessData.completeAddress = props.businessData.completeAddress ? props.businessData.completeAddress : '';
    businessData.country = props.businessData.country ? props.businessData.country : 'canada';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.id === 'phoneNumber') {
      const formattedValue = phoneFormat(event.target.value);
      updateObject(businessData, event.target.id, formattedValue, false);
    } else {
      updateObject(businessData, event.target.id, event.target.value, false);
    }
    setBusinessData({ ...businessData });
    setFormErrors(clearErrorMessage(formErrors, event.target.id));
  };

  const handleChangeLocationId = (locationId: string, fieldName: string) => {
    setBusinessData((prevState) => ({
      ...prevState,
      [fieldName]: locationId,
    }));
  };

  const schema = Joi.object()
    .options({ abortEarly: false })
    .keys({
      name: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.businessNameRequired'),
        }),
      locationId: Joi.string().allow(null).allow('').optional(),
      groupLvl1: Joi.string().allow(null).allow('').optional(),
      cityName: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.cityNameRequired'),
        }),
      address: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.addressRequired'),
        }),
      country: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.countryRequired'),
        }),
      zip: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.zipRequired'),
        }),
      webSite: Joi.alternatives()
        .try(Joi.string().uri(), Joi.string().domain())
        .allow(null)
        .allow('')
        .optional()
        .messages({
          'alternatives.match': t('createBusiness.websiteInvalid'),
        }),
      state: Joi.string()
        .required()
        .messages({
          'string.empty': t('createBusiness.stateRequired'),
        }),
      phoneNumber: Joi.string()
        .min(14)
        .messages({
          'string.empty': '',
          'string.min': t('createBusiness.phoneMin'),
        })
        .allow(null)
        .allow('')
        .optional(),
      latitude: Joi.number()
        .required()
        .messages({
          'string.empty': t('createBusiness.latitudeRequired'),
        }),
      longitude: Joi.number()
        .required()
        .messages({
          'string.empty': t('createBusiness.longitudeRequired'),
        }),
      completeAddress: Joi.string().messages({
        'string.empty': t('createBusiness.addressRequired'),
      }),
      placeId: Joi.string().messages({
        'string.empty': t('createBusiness.placeIdRequired'),
      }),
      positions: Joi.number()
        .required()
        .messages({
          'string.empty': t('createBusiness.positionRequired'),
        }),
      id: Joi.string(),
    });

  const formValidation = (formData: Business) => {
    const { error } = schema.validate(formData);
    if (error) {
      setFormErrors(errorFormat(error.details));
      return false;
    }

    setFormErrors([]);
    return true;
  };

  const saveBusiness = async () => {
    const requestObj: Business = {
      id: businessData.id,
      name: businessData.name,
      locationId: businessData.locationId,
      groupLvl1: businessData.groupLvl1,
      cityName: businessData.cityName,
      address: businessData.address,
      country: businessData.country,
      latitude: businessData.latitude,
      longitude: businessData.longitude,
      zip: businessData.zip,
      state: businessData.state,
      phoneNumber: businessData.phoneNumber,
      webSite: businessData.webSite,
      completeAddress: businessData.completeAddress,
      placeId: businessData.placeId,
      positions: 0,
    };

    const validate = formValidation(requestObj);
    if (validate && user) {
      setSavingBusiness(true);
      if (props.createNewBusiness) {
        try {
          const businessResponse = await createBusiness({ accountId: user.account, businessData });

          businessData.id = businessResponse?.businessId;
          businessData.account = user.account;

          props.successCallBack(businessData);
          toast({
            title: t('createBusiness.successMsg'),
            status: 'info',
            duration: 6000,
            isClosable: true,
          });
        } catch (err) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const errData: AxiosError = { ...err };
          const { response } = errData;
          setSavingBusiness(false);
          /* istanbul ignore next */
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (response?.data?.code === NotificationTypes.BUSINESS_ALREADY_EXISTS) {
            toast({
              title: t('createBusiness.businessAlreadyExists'),
              status: 'error',
              duration: 6000,
              isClosable: true,
            });
          }
        }
      } else {
        await editBusiness({ accountId: user.account, businessData, businessId: businessData.id });
        props.successCallBack(businessData);
        toast({
          title: t('createBusiness.successMsgBusinessUpdate'),
          status: 'info',
          duration: 6000,
          isClosable: true,
        });
      }
    }
  };

  const onSelectLocation = (e: GoogleAutoCompleteValues) => {
    let checkAlreadyHasLocation: boolean;
    setValue(e);
    if (props.businessData.placeId === e.value.place_id) {
      checkAlreadyHasLocation = true;
    } else checkAlreadyHasLocation = !!_isNil(businessLocations.find((location) => location.placeId === e.value.place_id));

    /* istanbul ignore next */
    if (e && checkAlreadyHasLocation) {
      businessData.placeId = e.value.place_id;
      businessData.address = e.value.structured_formatting.main_text;
      businessData.country = e.value.terms[e.value.terms.length - 1].value.toLowerCase() === 'usa' ? 'USA' : 'Canada';

      geocodeByPlaceId(e.value.place_id)
        .then((results) => {
          const city: google.maps.GeocoderAddressComponent[] = results[0].address_components.filter((address_component) =>
            ['locality', 'colloquial_area'].some((word) => {
              /* eslint no-bitwise: [2, { allow: ["~"] }] */
              return ~address_component.types.indexOf(word);
            }),
          );
          const state: google.maps.GeocoderAddressComponent[] = results[0].address_components.filter((address_component) =>
            ['administrative_area_level_1'].some((word) => {
              return ~address_component.types.indexOf(word);
            }),
          );
          const postal: google.maps.GeocoderAddressComponent[] = results[0].address_components.filter((address_component) =>
            ['postal_code'].some((word) => {
              return ~address_component.types.indexOf(word);
            }),
          );

          const completeAddress = results[0].formatted_address;

          /* istanbul ignore else */
          if (city) {
            businessData.cityName = city[0].short_name;
          }
          /* istanbul ignore else */
          if (postal) {
            businessData.zip = postal[0].short_name;
          }
          /* istanbul ignore else */
          if (state) {
            businessData.state = state[0].short_name;
          }
          /* istanbul ignore else */
          if (completeAddress) {
            businessData.completeAddress = completeAddress;
          }
          return getLatLng(results[0]);
        })
        .then(({ lat, lng }) => {
          businessData.latitude = lat;
          businessData.longitude = lng;
          setBusinessData({ ...businessData });
        })
        .catch((error: any) => {
          Bugsnag.notify(error);
        });
      setBusinessData({ ...businessData });
    } else {
      toast({
        title: t('createBusiness.businessAlreadyExists'),
        status: 'error',
        isClosable: true,
      });
      setValue({
        label: '',
        value: {
          place_id: '',
        },
      });
    }
  };

  return (
    <Box css={createBusinessStyle} data-testid="createBusinessComponent">
      <Box backgroundColor="white" borderRadius={5} mb={6}>
        <Box px={6} py={4}>
          <Flex>
            {props.createNewBusiness ? (
              <Box>
                <Heading as="h3" fontSize="md" lineHeight="1.3" mb={1}>
                  {t('createBusiness.businessCreation')}
                </Heading>
                <Text fontSize="xs" color="gray.500">
                  {t('createBusiness.businessCreationDetail')}
                </Text>
              </Box>
            ) : (
              ''
            )}
            <Spacer />
            <Center>
              <Button
                colorScheme="blue"
                data-testid="createBusinessCloseBtn"
                className="closeIcon"
                data={businessData.id}
                onClick={() => props.closeCallBack(businessData.id)}
              >
                {' '}
                {t('createBusiness.viewBusinessList')}
              </Button>
            </Center>
          </Flex>
        </Box>

        <Divider borderColor="#ECEFF1" />
        <Flex px={6} py={4}>
          <Box w="50%" mr={6}>
            <FormControl isInvalid={hasErrorMessage(formErrors, 'name')} isRequired>
              <FormLabel htmlFor="name">{t('createBusiness.businessName')}</FormLabel>
              <Input
                data-testid="businessNameField"
                variant="filled"
                id="name"
                type="text"
                value={businessData.name}
                onChange={handleChange}
              />
              <FormErrorMessage>{getErrorMessage(formErrors, 'name')}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box w={enableGroupInBusinessLocation ? '25%' : '50%'} mr={enableGroupInBusinessLocation ? 6 : 0}>
            <FormControl>
              <FormLabel htmlFor="locationId">{t('createBusiness.businessId')}</FormLabel>
              <Input
                data-testid="businessIdField"
                variant="filled"
                id="locationId"
                type="text"
                value={businessData.locationId}
                onChange={(e) => handleChangeLocationId(e.target.value, e.target.id)}
              />
            </FormControl>
          </Box>
          {enableGroupInBusinessLocation && (
            <Box w="25%">
              <FormControl>
                <FormLabel htmlFor="locationId">{t('createBusiness.group')}</FormLabel>
                <Input
                  data-testid="groupLvl1Field"
                  variant="filled"
                  id="groupLvl1"
                  type="text"
                  maxLength={80}
                  value={businessData.groupLvl1}
                  onChange={(e) => handleChangeLocationId(e.target.value, e.target.id)}
                />
              </FormControl>
            </Box>
          )}
        </Flex>
        <Box px={6} py={4}>
          <FormControl isInvalid={hasErrorMessage(formErrors, 'address')}>
            <FormLabel htmlFor="jobLocation">{t('createBusiness.address')}</FormLabel>
            <Box>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                debounce={1000}
                minLengthAutocomplete={3}
                data-testid="googleAutoComplete"
                selectProps={{
                  placeholder: t('createBusiness.address'),
                  value,
                  onChange: (e) => {
                    onSelectLocation(e as GoogleAutoCompleteValues);
                  },
                }}
              />
            </Box>
            <FormErrorMessage>{getErrorMessage(formErrors, 'address')}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box px={6} py={4}>
          <Flex mb={8}>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'cityName')} isRequired>
                <FormLabel htmlFor="cityName">{t('createBusiness.city')}</FormLabel>
                <Input
                  data-testid="businessCityField"
                  variant="filled"
                  id="cityName"
                  type="text"
                  onChange={handleChange}
                  value={businessData.cityName}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'cityName')}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'state')} isRequired>
                <FormLabel htmlFor="state">{t('createBusiness.state')}</FormLabel>
                <Input
                  data-testid="businessStateField"
                  variant="filled"
                  id="state"
                  type="text"
                  onChange={handleChange}
                  value={businessData.state}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'state')}</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
        </Box>
        <Box px={6} py={4}>
          <Flex mb={8}>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'zip')} isRequired>
                <FormLabel htmlFor="zip">{t('createBusiness.zip')}</FormLabel>
                <Input
                  data-testid="businessZipField"
                  variant="filled"
                  id="zip"
                  type="text"
                  value={businessData.zip}
                  onChange={handleChange}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'zip')}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'country')} isRequired>
                <FormLabel htmlFor="country">{t('createBusiness.country')}</FormLabel>
                <Select
                  data-testid="businessCountryField"
                  className="filled"
                  id="country"
                  onChange={handleChange}
                  value={businessData.country}
                >
                  <option value="Canada">{t('createBusiness.canada')}</option>
                  <option value="USA">{t('createBusiness.usa')}</option>
                </Select>
                <FormErrorMessage>{getErrorMessage(formErrors, 'country')}</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
        </Box>
        <Box px={6} py={4}>
          <iframe
            src={`https://maps.google.com/maps?q=${businessData.completeAddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
            width="100%"
            height="450"
            frameBorder="0"
            title={businessData.name}
            style={{ border: 0 }}
            aria-hidden="false"
          />
        </Box>
        <Box px={6} py={4}>
          <Flex mb={8}>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'webSite')}>
                <FormLabel htmlFor="webSite">{t('createBusiness.url')}</FormLabel>
                <Input
                  data-testid="businessWebsiteField"
                  variant="filled"
                  id="webSite"
                  type="text"
                  value={businessData.webSite}
                  onChange={handleChange}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'webSite')}</FormErrorMessage>
              </FormControl>
            </Box>
            <Box w="50%" mr={6}>
              <FormControl isInvalid={hasErrorMessage(formErrors, 'phoneNumber')}>
                <FormLabel htmlFor="phoneNumber">{t('createBusiness.phone')}</FormLabel>
                <Input
                  data-testid="businessPhoneNumberField"
                  variant="filled"
                  id="phoneNumber"
                  type="tel"
                  maxLength={14}
                  value={businessData.phoneNumber}
                  onChange={handleChange}
                />
                <FormErrorMessage>{getErrorMessage(formErrors, 'phoneNumber')}</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Button
        data-testid="CreateBusinessBtn"
        colorScheme="blue"
        isLoading={savingBusiness}
        style={{ position: 'absolute', bottom: '50px', right: '50px' }}
        onClick={saveBusiness}
      >
        {props.createNewBusiness ? t('createBusiness.create') : t('createBusiness.update')}
      </Button>
    </Box>
  );
};
