export default {
  pageTitle: "Vue d'ensemble",
  hiringOverview: {
    outstandingOffers: 'Offres en attente',
    hiresProcessing: 'Embauche en cours de traitement',
    backgroundChecksDelayed: 'Vérification des antécédents retardées',
    overdueApprovals: 'Approbations en retard',
    stalledCandidates: 'Candidats bloqués',
    openRequisitions: 'Réquisitions ouvertes',
    atRisk: 'À risque',
    positionsImpacted: 'Postes affectés',
    distributionOfAgedRequisitions: "Distribution d'âge des réquisitions",
    unfilledReqs: 'Réquisitions ouvertes',
    past10DaysNeedDate: '> 10 jours après la date effective',
    loadingHiringOverview: 'Chargement du tableau de bord de gestion',
    weekLabel: `Semaines`,
    weekAndMore: 'Semaines et plus',
    value: 'Réqs: ',
  },
  hireOfferStat: {
    hireOfferStatistics: 'Stats d’offres & embauches',
    hireProposed: 'Embauches proposés',
    hireApproved: 'Embauches approuvés',
    hireRejected: 'Embauches rejetés',
    offersMade: 'Offres faites',
    offersAccepted: 'Offres acceptées',
    offersRejected: 'Offres rejetées',
    offersRevoked: 'Offres révoquées',
    hRSubmission: 'Soumission de formulaire',
    timeToFill: 'Temps pour embaucher',
    offerMadeCount: 'Compte d’offres faites',
    offerRejectCount: 'Compte d’offres rejetées',
    offerAcceptCount: 'Compte d’offres acceptées',
    offerRevokedCount: 'Compte d’offres révoquées',
    dismissedCandidateCount: 'Compte de candidats rejetés',
    loadingOfferStatistics: 'Chargement des stats d’offres & embauches',
    selectDate: 'Choisir une date',
    oneWeek: '1 semaine',
    twoWeek: '2 semaines',
    oneMonth: '1 mois',
    threeMonth: '3 mois',
    from: 'De:',
    to: 'À:',
  },
};
