import { Box, Flex } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Column } from 'react-table';
import { TablePaginationFooter } from '../../../app/components/TablePaginationFooter';
import { Header } from '../../../app/topBar/Header';
import { defaultOfferAndHireDetails, OfferAndHireDetails } from '../../../firebase/firestore/documents/offerAndHireProcess';
import { useStoreActions, useStoreState } from '../../../models/hooks';
import { OfferAndHireStatus } from '../../../routes/constants';
import colors from '../../../styles/colors';
import { Option } from '../../common/DropDownCheckBox/DropDownCheckBox';
import OfferAndHireProcessViewTopBar from './OfferAndHireProcessViewTopBar';
import OfferAndHireProcessTable from './offerAndHireProcessTable/OfferAndHireProcessTable';
import { OfferAndHireProcessTableDataType } from './offerAndHireProcessTable/offerAndHireProcessTableType';

const OfferAndHireProcessView = () => {
  const { t } = useTranslation('offerAndHireProcess');

  const { status } = useParams<OfferAndHireStatus>();
  const queryName: string = status?.toUpperCase() as string;

  const options: Option[] = [
    { value: 'all', label: t('filterOption.all') },
    { value: 'HIRE_PROCESSING', label: t('filterOption.hiresProcessing') },
    { value: 'HIRED', label: t('filterOption.hired') },
    { value: 'OFFER_PROCESSING', label: t('filterOption.offersProcessing') },
    { value: 'OFFER_REJECTED', label: t('filterOption.offersRejected') },
    { value: 'OFFER_REVOKED', label: t('filterOption.offersRevoked') },
  ];

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    options.reduce((acc, option) => ({ ...acc, [option.value]: queryName === option.value }), {}),
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      setCheckedItems((prevChecked) => {
        const updatedChecked = { ...prevChecked, [name]: checked };
        const allOtherOptionsExceptAllTrue = Object.keys(updatedChecked)
          .filter((key) => key !== 'all')
          .every((key) => updatedChecked[key]);
        if (name === 'all') {
          updatedChecked.all = checked;
          options.forEach((option) => {
            updatedChecked[option.value] = updatedChecked.all;
          });
        } else if (allOtherOptionsExceptAllTrue) {
          updatedChecked.all = true;
        } else if (!checked) {
          updatedChecked.all = false;
        }
        return updatedChecked;
      });
    },
    [options],
  );

  const [offerAndHireDetails, setOfferAndHireDetails] = useState<OfferAndHireDetails>(defaultOfferAndHireDetails);
  const [tableData, setTableData] = useState<OfferAndHireProcessTableDataType[]>([]);
  const [currentFilteredItems, setCurrentFilteredItems] = useState<OfferAndHireProcessTableDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const accountId = useStoreState((state) => state.app.accounts?.id);
  const { getOfferAndHireDetails } = useStoreActions((actions) => actions.offerAndHireProcess);

  const onGetOfferAndHireDetails = useCallback(
    async (account: string) => {
      setIsLoading(true);
      const list = await getOfferAndHireDetails({ accountId: account });
      setIsLoading(false);
      setOfferAndHireDetails(list);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetOfferAndHireDetails(accountId);
    }
  }, [accountId, onGetOfferAndHireDetails]);

  useEffect(() => {
    const filteredData = offerAndHireDetails.data?.filter((item) =>
      Object.keys(checkedItems).some((key) => checkedItems[key] && item.status === key),
    );

    setTableData(filteredData || []);
  }, [checkedItems, offerAndHireDetails.data]);

  const tableHeaderTranslation = {
    locationAndPosition: t('offerAndHireProcessTableDetails.header.locationAndPosition'),
    candidate: t('offerAndHireProcessTableDetails.header.candidate'),
    requisitionId: t('offerAndHireProcessTableDetails.header.requisitionId'),
    commuteData: t('offerAndHireProcessTableDetails.header.commuteData'),
    currentStatus: t('offerAndHireProcessTableDetails.header.currentStatus'),
  };

  const offerAndHireProcessTableColumns: Column<OfferAndHireProcessTableDataType>[] = [
    {
      Header: tableHeaderTranslation.locationAndPosition,
      accessor: 'businessName',
    },
    {
      Header: tableHeaderTranslation.candidate,
      accessor: 'candidateName',
    },
    {
      Header: tableHeaderTranslation.requisitionId,
      accessor: 'requisitionId',
    },
    {
      Header: tableHeaderTranslation.currentStatus,
      accessor: 'status',
    },
    {
      Header: tableHeaderTranslation.commuteData,
      accessor: 'commuteData',
    },
  ];
  return (
    <>
      <Box backgroundColor={colors.antiFlashWhite}>
        <Header showMenu showPageTitle={t('pageTitle')} />
        <OfferAndHireProcessViewTopBar
          checkedItems={checkedItems}
          queryName={queryName}
          options={options}
          handleCheckboxChange={handleCheckboxChange}
        />
        {/* Table part */}
        <Flex p={4} pt={0} direction="column" height="calc(100vh - 133px)" overflowY="auto">
          <Box background={colors.white} w="100%" borderRadius="md">
            <OfferAndHireProcessTable
              isLoading={isLoading}
              columns={offerAndHireProcessTableColumns}
              data={currentFilteredItems}
            />

            <TablePaginationFooter finalListOfItems={tableData} setCurrentItemsList={setCurrentFilteredItems} />
          </Box>
        </Flex>
      </Box>
    </>
  );
};
export default OfferAndHireProcessView;
