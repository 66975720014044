import { AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import {
  CertnFormData,
  CertnSecretsResponse,
  WebApplyUrl,
  UploadLogoResponse,
  SuccessMessageResponse,
} from '../modules/administration/certn/CertnType';
import { BackgroundChecks } from '../firebase/firestore/documents/candidate';
import {
  ModernHireConfigData,
  ModernHireUpdateResponseType,
} from '../modules/administration/ModernHireConfigView/ModernHireConfigData';

export class AccountController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async getFavoriteSeekersList(accountId: string, userId: string): Promise<AxiosResponse> {
    const path = `/api/v1/account/${accountId}/user/${userId}/favourites`;
    return this.httpClient.get(path);
  }

  async saveNote(accountId: string, positionId: string, seekerId: string, note: string): Promise<void> {
    const path = `/api/v1/accounts/${accountId}/positions/${positionId}/seekers/${seekerId}/notes`;
    const body = { note };
    await this.httpClient.post(path, body);
  }

  async updateNote(accountId: string, positionId: string, seekerId: string, noteIndex: number, note?: string): Promise<void> {
    const path = `/api/v1/accounts/${accountId}/positions/${positionId}/seekers/${seekerId}/notes/${noteIndex}`;
    const body = { note };
    await this.httpClient.put(path, body);
  }

  async deleteNote(accountId: string, positionId: string, seekerId: string, noteIndex: number): Promise<void> {
    const path = `/api/v1/accounts/${accountId}/positions/${positionId}/seekers/${seekerId}/notes/${noteIndex}`;
    await this.httpClient.delete(path);
  }

  async saveOfferMadeNote(
    positionId: string,
    seekerId: string,
    offerDetails: string,
    salary: string,
    salaryUnit: string,
    customSalaryUnit?: string,
    notifyOfferMade?: boolean,
    requisitionId?: string,
    updatedPositionId?: string,
    updatedBusinessId?: string,
    autoDismiss?: boolean,
  ): Promise<void> {
    const path = `/api/v1/positions/${positionId}/seekers/${seekerId}/offer`;
    const body = {
      offerDetails,
      notifyOfferMade,
      offeredSalary: {
        salary,
        salaryUnit,
        customSalaryUnit,
      },
      requisitionId,
      updatedPositionId,
      updatedBusinessId,
      autoDismiss,
    };
    await this.httpClient.post(path, body);
  }

  async addSeekerToFavorite(accountId: string, userId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/account/${accountId}/user/${userId}/favourites`;
    const body = { seekerId };
    await this.httpClient.post(path, body);
  }

  async removeSeekerFromFavorite(accountId: string, userId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/account/${accountId}/user/${userId}/favourites`;
    const body = { data: { seekerId } };
    await this.httpClient.delete(path, body);
  }

  async saveOfferRejectedNote(positionId: string, seekerId: string, offerRejectedDetails: string | null): Promise<void> {
    const path = `/api/v1/positions/${positionId}/seekers/${seekerId}/offer`;
    const body = { data: { offerRejectedDetails } };
    await this.httpClient.delete(path, body);
  }

  async getWebApplyJobListURL(accountId: string, userId: string): Promise<WebApplyUrl> {
    const path = `/api/v1/account/${accountId}/user/${userId}/webApplyUrl`;
    const response = await this.httpClient.get<WebApplyUrl>(path);
    return response.data;
  }

  async uploadLogo(accountId: string, companyLogo: string): Promise<UploadLogoResponse> {
    const path = `/api/v1/account/${accountId}/companyLogo`;
    const body = { companyLogo };
    const response = await this.httpClient.post<UploadLogoResponse>(path, body);
    return response.data;
  }

  async getCompanyLogo(accountId: string): Promise<string> {
    const path = `/publicApi/v1/account/${accountId}/companyLogo?${new Date().getTime()}`;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, headers } = await this.httpClient.get<ArrayBuffer>(path, {
      responseType: 'arraybuffer',
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (headers['content-type'] === 'application/json; charset=utf-8') {
      return '';
    }
    return `data:image/png;base64,${Buffer.from(data).toString('base64')}`;
  }

  async saveCertnSecrets(accountId: string, apiKey: string, webhookSignature: string): Promise<CertnSecretsResponse> {
    const path = `/api/v1/account/${accountId}/certn`;
    const body = { apiKey, webhookSignature };
    const response = await this.httpClient.post<SuccessMessageResponse>(path, body);
    return response.data;
  }

  async getCertnSecrets(accountId: string): Promise<CertnFormData> {
    const path = `/api/v1/account/${accountId}/certn`;
    const response = await this.httpClient.get<CertnFormData>(path);
    return response.data;
  }

  async administrationBackgroundCheck(accountId: string, backgroundCheckList: BackgroundChecks): Promise<SuccessMessageResponse> {
    const path = `/api/v1/account/${accountId}/certn/screening/options`;
    const response = await this.httpClient.post<SuccessMessageResponse>(path, backgroundCheckList);
    return response.data;
  }

  async getHireFormAPIKey(accountId: string, isRenew: boolean): Promise<string> {
    const path = `/api/v1/hireDetail/apiKey/${accountId}/?isRenew=${isRenew}`;
    const response = await this.httpClient.get<string>(path);
    return response.data;
  }

  async updateModernHireConfigData(accountId: string, configData: ModernHireConfigData): Promise<ModernHireUpdateResponseType> {
    const path = `api/v1/account/${accountId}/modernHire`;
    const response = await this.httpClient.post<ModernHireUpdateResponseType>(path, configData);
    return response.data;
  }

  async getModernHireConfigData(accountId: string): Promise<ModernHireConfigData> {
    const path = `api/v1/account/${accountId}/modernHire`;
    const response = await this.httpClient.get<ModernHireConfigData>(path);
    return response.data;
  }

  async updateHireWebHookUrlInAccount(accountId: string, link: string): Promise<AxiosResponse> {
    const path = `api/v1/account/${accountId}/updateWebhookOnHireDetails/`;
    const response = await this.httpClient.post<ModernHireConfigData>(path, { hireWebhookForCustomer: { url: link } });
    return response;
  }
}
