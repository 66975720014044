import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Flex, useDisclosure } from '@chakra-ui/core';
import { Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import _orderBy from 'lodash/orderBy';
import { IoMdRefresh } from 'react-icons/io';
import { Header } from '../../app/topBar/Header';
import { ToDoCountViewSideBar } from './ToDoCountViewSideBar';
import RecruiterTodoListTableView from './RecruiterTodoLisTableView';
import { ActionEnum, RecruiterTodoListDataType } from './RecruiterTodoListType';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { ToDoData, ToDoDefault, ToDoList } from '../../firebase/firestore/documents/toDo';
import { ToDoCountViewTopBar } from './ToDoCountViewTopBar';
import { RequisitionOverviewView } from './requisitionOverview/RequisitionOverviewView';
import colors from '../../styles/colors';
import { TablePaginationFooter } from '../../app/components/TablePaginationFooter';
// import { toDosActions } from './TodosActionsData';
import { RecruiterActionFormModal } from './RecruiterActionFormModal';
import { LanguageEvaluationModal } from './LanguageEvaluationModal';
import useLookupsByType from '../../app/hooks/useLookupsByType';
import { dashboardStyleCSS } from './DashboardViewStyle';

export const DashboardHome = (): JSX.Element => {
  const candidateToDoListViewRef = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation('toDoList');
  const headerInfoFromTranslation = {
    action: t('actionToTake'),
    lastStatus: t('lastStatus'),
    status: t('status'),
    locationAndPosition: t('locationAndPosition'),
    candidateName: t('candidate'),
  };
  const recruiterTodoListTableColumns: Column<RecruiterTodoListDataType>[] = [
    {
      Header: headerInfoFromTranslation.action,
      accessor: 'actionType',
    },
    {
      Header: headerInfoFromTranslation.lastStatus,
      accessor: 'lastStatus',
    },
    {
      Header: headerInfoFromTranslation.status,
      accessor: 'status',
    },
    {
      Header: headerInfoFromTranslation.locationAndPosition,
      accessor: 'location',
    },
    {
      Header: headerInfoFromTranslation.candidateName,
      accessor: 'candidateFullName',
    },
  ];

  const [toDOList, setToDoList] = useState<ToDoList>(ToDoDefault);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedToDoItem, setSelectedToDoItem] = useState<
    | {
        id: string;
        task: string;
        candidateName: string;
        candidateId: string;
        helpRequestedMessages?: {
          userType: string;
          message: string;
          timestamp: Date;
          name: string;
        }[];
        latestHelpRequestFor?: string;
        hireDetailsRequestedAt?: string;
      }
    | undefined
  >();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLanguageEvaluationModelOpen,
    onOpen: onOpenLanguageEvaluationModel,
    onClose: onCloseLanguageEvaluationModel,
  } = useDisclosure();

  const [formattedTodoList, setFormattedTodoList] = useState<RecruiterTodoListDataType[]>([]);

  const accountId = useStoreState((state) => state.app.accounts?.id);

  const { getToDo } = useStoreActions((actions) => actions.toDoList);
  const [openSideBar, setOpenSideBar] = useState(false);

  const [currentItems, setCurrentItems] = useState<RecruiterTodoListDataType[]>([]);

  const topWrapperRef = useRef(null);
  const containerWithStickyNav = useRef<HTMLDivElement | null>(null);
  const topBarWithStickyBox = useRef<HTMLDivElement | null>(null);

  const lookups = useLookupsByType('candidateTodo');

  // const lookups = toDosActions;
  const candidateTodos = lookups[0]?.todo;
  const [isDataChanged, setIsDataChanged] = useState(true);
  const [disableLoader, setDisableLoader] = useState(false);

  const handleCurrentItems = (givenCurrentItems: RecruiterTodoListDataType[]) => {
    setCurrentItems(givenCurrentItems);
    candidateToDoListViewRef?.current?.scrollTo(0, 0);
  };

  const getActionTypeOfTodoItem = (status: string) => {
    switch (status) {
      case 'offerMade12HoursAgo':
      case 'offerMade24HoursAgo':
      case 'offerMade36HoursAgo':
      case 'offerMade48HoursAgo':
        return ActionEnum.OFFER;
      case 'bgCheckRequested24HoursAgo':
      case 'bgCheckRequested48HoursAgo':
      case 'bgCheckRequested72HoursAgo':
      case 'bgCheckFlaggedByCertnCandidates':
      case 'bgCheckNotProcessedByCertn24HoursAgoCandidates':
        return ActionEnum.BACKGROUND_CHECK;
      case 'hireFormRequested24HoursAgo':
      case 'hireFormRequested48HoursAgo':
      case 'hireFormRequested72HoursAgo':
      case 'hireFormCompleted24HoursAgo':
      case 'hireFormHelpRequested':
      case 'hireFormInReview':
        return ActionEnum.HIRE_FORM;
      case 'languageEvaluation':
        return ActionEnum.LANGUAGE_EVALUATION;
      case 'responseToCandidate':
      case 'dialogflow':
        return ActionEnum.CONVERSATIONAL_AI;
      default:
        return ActionEnum.OFFER;
    }
  };

  const formatData = (todoList: ToDoData[]): RecruiterTodoListDataType[] => {
    return todoList?.map((item) => ({
      id: item.recruiterActionId,
      actionType: getActionTypeOfTodoItem(item.status),
      location: item.businessName,
      candidateFullName: `${item.firstName} ${item.lastName}`,
      candidateId: item.id,
      position: item.position,
      status: t('toDo'),
      lastStatus: item.lastUpdate,
      title: item.status,
      description: item.status,
      customName: item.customName,
      latestHelpRequestedFor: item.latestHelpRequestedFor,
      hireDetailsRequestedAt: item.hireDetailsRequestedAt,
      helpRequestedMessages: item.helpRequestedMessages,
      todoStatus: item.todoStatus ?? '',
    }));
  };

  const onGetTodoList = useCallback(
    async (account: string) => {
      setIsLoading(true);
      const list = await getToDo({ accountId: account });
      setIsLoading(false);
      setDisableLoader(true);
      const { data } = list;
      const sortedData = _orderBy(data, ['todoStatus'], ['desc']);
      setToDoList((prevState) => ({
        ...prevState,
        count: list.count,
        data: sortedData,
      }));
      setFormattedTodoList(formatData(sortedData));
      setIsDataChanged(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleAction = (
    id: string,
    task: string,
    candidateName: string,
    candidateId: string,
    helpRequestedMessages?: {
      userType: string;
      message: string;
      timestamp: Date;
      name: string;
    }[],
    latestHelpRequestFor?: string,
    hireDetailsRequestedAt?: string,
  ) => {
    setSelectedToDoItem({
      id,
      task,
      candidateName,
      candidateId,
      helpRequestedMessages,
      latestHelpRequestFor,
      hireDetailsRequestedAt,
    });
    if (task === 'languageEvaluation') {
      onOpenLanguageEvaluationModel();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (accountId && isDataChanged) {
      /* eslint-disable @typescript-eslint/no-floating-promises */
      onGetTodoList(accountId);
    }
  }, [accountId, onGetTodoList, isDataChanged]);

  // useEffect(() => {
  // }, [onStatusChanged]);

  // istanbul ignore next
  const handleScroll = () => {
    if (candidateToDoListViewRef.current && containerWithStickyNav.current) {
      // Access scrollTop, scrollHeight, clientHeight, etc. as needed
      if (candidateToDoListViewRef.current.scrollTop >= containerWithStickyNav.current.offsetTop - 50) {
        containerWithStickyNav.current.classList.add('sticky');
        topBarWithStickyBox.current?.classList.remove('stickyTop');
      } else {
        containerWithStickyNav.current.classList.remove('sticky');
        topBarWithStickyBox.current?.classList.add('stickyTop');
      }
    }
  };

  // istanbul ignore next
  const handleDataChanged = () => {
    setIsDataChanged(true);
  };

  const onRefreshTodo = () => {
    setDisableLoader(false);
    /* istanbul ignore else */
    if (accountId) {
      onGetTodoList(accountId);
    }
  };

  return (
    <Box backgroundColor={colors.antiFlashWhite} ref={topWrapperRef}>
      <Header showMenu showPageTitle={t('toDoList:pageTitle')} />
      <Box css={dashboardStyleCSS}>
        <Flex
          direction="column"
          height="calc(100vh - 128px)"
          overflowY="auto"
          ref={candidateToDoListViewRef}
          onScroll={handleScroll}
        >
          <Flex ref={topBarWithStickyBox} className="stickyTop" bg="#fff" alignItems="center">
            <ToDoCountViewTopBar
              count={toDOList.count}
              onOpenSideBar={() => setOpenSideBar(!openSideBar)}
              openSideBar={openSideBar}
            />

            <Button
              onClick={onRefreshTodo}
              size="md"
              fontSize="sm"
              colorScheme="gray"
              leftIcon={<IoMdRefresh size={20} />}
              data-testid="refreshBtn"
              mr={4}
              variant="outline"
            >
              {t('jobPosition:refresh')}
            </Button>
          </Flex>

          <Box>
            <Flex>
              {openSideBar && (
                <Box w="20%" bg="#fff">
                  <ToDoCountViewSideBar count={toDOList.count} />
                </Box>
              )}
              <Box w={openSideBar ? '80%' : '100%'} p={openSideBar ? '0px' : '0px 12px 12px 12px'}>
                <Box bg="#fff">
                  {candidateTodos && (
                    <RecruiterTodoListTableView
                      isLoading={isLoading && !disableLoader}
                      handleAction={handleAction}
                      candidateTodos={candidateTodos}
                      columns={recruiterTodoListTableColumns}
                      data={currentItems}
                      onCallToDoAPI={handleDataChanged}
                      accountId={accountId}
                    />
                  )}
                  <TablePaginationFooter
                    finalListOfItems={formattedTodoList}
                    setCurrentItemsList={handleCurrentItems}
                    showingResultsTranslation={(variable: string) => t('toDoList:showingResult', variable)}
                  />
                  {selectedToDoItem && isOpen && candidateTodos && (
                    <RecruiterActionFormModal
                      selectedToDoItem={selectedToDoItem}
                      candidateTodos={candidateTodos}
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  )}
                  {selectedToDoItem && isLanguageEvaluationModelOpen && (
                    /* istanbul ignore next */ <LanguageEvaluationModal
                      isOpen={isLanguageEvaluationModelOpen}
                      onClose={onCloseLanguageEvaluationModel}
                      currentCandidateId={selectedToDoItem.candidateId}
                      currentTask={selectedToDoItem.task}
                      currentDocId={selectedToDoItem.id}
                      isRenderedBy="DashboardHome"
                    />
                  )}
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box ref={containerWithStickyNav}>
            <RequisitionOverviewView />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
