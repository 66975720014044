import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import { Header } from '../../../app/topBar/Header';
import { CandidatesList } from './CandidatesList';
import { useTopCandidatesContext } from './TopCandidatesContext';
import { SeekerProfile } from './SeekerProfile';
import { TopCandidatesSidebarFilter } from './TopCandidatesSidebarFilter';

export const TopCandidates = (): JSX.Element => {
  const { isFilterOpened } = useTopCandidatesContext();
  return (
    <Box position="absolute" height="100vh" width="100vw" margin="0">
      <Header showMenu />
      <Flex height="calc(100vh - 64px)">
        {isFilterOpened && <TopCandidatesSidebarFilter />}
        <Box
          w={isFilterOpened ? '65%' : '80%'}
          position="relative"
          borderRight="1px solid #E5E7EB"
          borderLeft="1px solid #E5E7EB"
        >
          <CandidatesList />
        </Box>
        <Box w="20%">
          <SeekerProfile />
        </Box>
      </Flex>
    </Box>
  );
};
