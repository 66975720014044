import { css } from '@emotion/core';
import colors from '../../../../styles/colors';

export const loginBox = css`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex: 2 0 70%;
  width: 45vw;
  max-width: 40rem;
  min-height: 40vh;

  .chakra-ui-dark & {
    background-color: ${colors.blackAlpha['300']};
  }
`;

export const loginForm = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const loginFormBox = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const loginFormTopText = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const loginButtonStyle = css`
  background-color: ${colors.blue.default};
  color: white;
`;

export const deleteButtonStyle = css`
  background-color: #b33636;
  color: white;
`;
