/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, Image } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import logo from '../../../assets/img/AppyHere-Recruiter-Blue-Logo.png';
import { ANIMATED_VARIANTS, PATH_DASHBOARD, PATH_MAIN } from '../../../routes/constants';
import { alertDialog, loginView, loginViewWrapper } from './LoginView.styles';
import LoginForm from './loginForm/LoginForm';
import { useCurrentUserProfile } from '../../../app/hooks/useCurrentUserProfile';
import { AuthenticationStatus } from '../../../app/models/appModel';
import { useAuthenticationStatus } from '../../../app/hooks/useAuthenticationStatus';
import { SignUpView } from '../signup/SignUpView';
import { ActiveTabEnum } from '../userProfileInfoType';
import useAccounts from '../../../app/hooks/useAccounts';

const MotionBox = motion.custom(Box);

export const LoginView: React.FC = () => {
  const { t } = useTranslation('login');
  const [activeTab, setActiveTab] = useState<ActiveTabEnum>(ActiveTabEnum.LOGIN);
  const { currentUserProfile } = useCurrentUserProfile();
  const authenticationStatus = useAuthenticationStatus();
  const currentUserAccount = useAccounts();

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.NEW_USER) {
      setActiveTab(ActiveTabEnum.SIGN_UP);
    }
  }, [authenticationStatus]);

  if (currentUserProfile && currentUserAccount) {
    return <Redirect to={currentUserAccount.configuration?.dashboardEnabled ? PATH_DASHBOARD : PATH_MAIN} />;
  }

  const renderInvalidUserErrorMessage = () => {
    if (AuthenticationStatus.INVALID_USER === authenticationStatus) {
      return (
        <Alert css={alertDialog} status="error" data-testid="InvalidUserMessage" variant="solid" height="100px">
          <AlertIcon />
          <AlertTitle mr={2}>{t('invalid_user_alert_title')}</AlertTitle>
          <AlertDescription>{t('invalid_user_alert_description')}</AlertDescription>
        </Alert>
      );
    }
    return undefined;
  };

  return (
    <Fragment>
      <MotionBox css={loginViewWrapper} px="2vw" initial="initial" animate="in" exit="out" variants={ANIMATED_VARIANTS}>
        <Flex justify="center" align="center" css={loginView} data-testid="LoginView">
          <Flex bg="blue.30" rounded="lg" justifyContent="space-between">
            <Flex flex="0 0 35%" justify="center" alignItems="center" px={8}>
              <Image src={logo} alt={t('app:bundleName')} data-testid="LogoImage" />
            </Flex>
            <Box bg="white" roundedRight="lg" py={14} px={8}>
              <Flex justifyContent="center">
                <Box bg="blue.20" borderRadius={30} p="5px">
                  <Button
                    borderRadius={30}
                    height="2.2rem"
                    lineHeight="1"
                    colorScheme="blue"
                    variant={activeTab === ActiveTabEnum.LOGIN ? 'solid' : 'ghost'}
                    onClick={() => setActiveTab(ActiveTabEnum.LOGIN)}
                    disabled={authenticationStatus === AuthenticationStatus.NEW_USER}
                    data-testid="LoginTabBtn"
                  >
                    {t('login')}
                  </Button>
                  <Button
                    borderRadius={30}
                    height="2.2rem"
                    lineHeight="1"
                    colorScheme="blue"
                    variant={
                      activeTab === ActiveTabEnum.SIGN_UP || authenticationStatus === AuthenticationStatus.NEW_USER
                        ? 'solid'
                        : 'ghost'
                    }
                    onClick={() => setActiveTab(ActiveTabEnum.SIGN_UP)}
                    data-testid="SignUpTabBtn"
                  >
                    {t('signUpLabel')}
                  </Button>
                </Box>
              </Flex>
              {authenticationStatus === AuthenticationStatus.NEW_USER ? <SignUpView /> : <LoginForm activeTab={activeTab} />}
            </Box>
          </Flex>
        </Flex>
      </MotionBox>
      {renderInvalidUserErrorMessage()}
    </Fragment>
  );
};
