import { css } from '@emotion/core';
import { transitionSpeed } from '../../../styles/sizes';
import colors from '../../../styles/colors';

export const seekerPanel = css`
  display: contents;
`;

export const chatMessage = css`
  background-color: ${colors.neutralGray['100']};
  transition: background-color ${transitionSpeed}, border-color ${transitionSpeed}, color ${transitionSpeed};
  width: 70%;

  .chakra-ui-dark & {
    background-color: ${colors.black};
  }
`;

export const columnHeight = css`
  grid-column: midSide / rightSide;
  grid-row: sub2 / bottom;
  display: grid;
  grid-template-rows: 1fr 1px;
  overflow-y: hidden;
`;
