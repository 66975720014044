import React, { useEffect, useState } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/core';
import { MdFirstPage, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineLastPage } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import isUndefined from 'lodash/isUndefined';
import colors from '../../styles/colors';

export type TablePaginationFooterProps = {
  setCurrentItemsList: any;
  finalListOfItems: any[];
  showingResultsTranslation?: any | undefined;
};

export const TablePaginationFooter = ({
  setCurrentItemsList,
  finalListOfItems,
  showingResultsTranslation,
}: TablePaginationFooterProps): JSX.Element => {
  const { t } = useTranslation('requisition');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = finalListOfItems.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(finalListOfItems.length / itemsPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    setCurrentItemsList(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalListOfItems, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalListOfItems]);

  return (
    <>
      <Flex alignItems="center" w="100%" p={4} background={colors.white}>
        <Flex alignItems="center" w="100%" justifyContent="space-between">
          <Text mr={3} data-testid="pagination-text">
            {isUndefined(showingResultsTranslation)
              ? t('common:pagination.showingResult', {
                  currentItemsLength:
                    itemsPerPage * currentPage < finalListOfItems.length ? itemsPerPage * currentPage : finalListOfItems.length,
                  totalItemsLength: finalListOfItems.length,
                })
              : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                showingResultsTranslation({
                  currentItemsLength:
                    itemsPerPage * currentPage < finalListOfItems.length ? itemsPerPage * currentPage : finalListOfItems.length,
                  totalItemsLength: finalListOfItems.length,
                })}
          </Text>
          <Flex alignItems="center">
            <IconButton
              onClick={() => paginate(1)}
              mr={3}
              isDisabled={currentPage <= 1}
              variant="outline"
              colorScheme="blue"
              aria-label="First"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdFirstPage />}
              data-testid="first-page-button"
            />
            <IconButton
              onClick={() => paginate(currentPage - 1)}
              mr={3}
              isDisabled={currentPage <= 1}
              variant="outline"
              colorScheme="blue"
              aria-label="Prev"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowLeft />}
              data-testid="prev-page-button"
            />
            <IconButton
              onClick={() => paginate(currentPage + 1)}
              mr={3}
              isDisabled={finalListOfItems.length <= itemsPerPage * currentPage}
              variant="outline"
              colorScheme="blue"
              aria-label="Next"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdKeyboardArrowRight />}
              data-testid="next-page-button"
            />
            <IconButton
              onClick={() => paginate(totalPages)}
              isDisabled={currentPage === totalPages}
              variant="outline"
              colorScheme="blue"
              aria-label="Last"
              borderRadius="8px"
              h="28px"
              w="28px"
              minW="28px"
              borderColor="#718096"
              icon={<MdOutlineLastPage />}
              data-testid="last-page-button"
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
