import React from 'react';
import { Badge, Box, Flex, ListItem, Text } from '@chakra-ui/core';
import moment from 'moment-timezone';
import colors from '../../../styles/colors';
import { Notification } from '../../../firebase/firestore/documents/notification';
import { Lookups } from '../../../firebase/firestore/documents/lookups';
import { getTranslatedValue } from '../../../utils/localizedString';

export function BackgroundCheckNotificationItem({
  notification,
  readNotificationInvitation,
  backgroundChecks,
  addToShowAll,
  showAll,
  getTitle,
  selectedCallback,
  locale,
}: {
  notification: Notification;
  readNotificationInvitation: (id: string) => void;
  addToShowAll: (id: string) => void;
  backgroundChecks: Lookups[];
  showAll: boolean;
  getTitle: (name?: string) => string;
  selectedCallback: (notification: Notification) => void;
  locale: string;
}): JSX.Element {
  const hasCorrectStructure = !!notification.additionalContextData?.candidateName && !!notification.positionTitle;
  const whenNotShowAll = 1;
  const options = [];

  if (hasCorrectStructure && notification.additionalContextData?.screeningOptions) {
    options.push(
      ...Object.keys(notification.additionalContextData.screeningOptions)
        .filter((opt) => {
          return notification.additionalContextData?.screeningOptions?.[opt];
        })
        .map((key) => {
          const index = backgroundChecks.findIndex((bc) => bc.id === key);
          return index > -1 ? getTranslatedValue(backgroundChecks[index].label, locale) : '';
        }),
    );
  }
  return (
    <ListItem
      px={4}
      py={3}
      key={notification.id}
      borderBottom="1px solid #EBEBEB"
      data-testid={`defaultList-${notification.id}`}
      onClick={() => {
        selectedCallback(notification);
      }}
      cursor="pointer"
      className={notification.new ? '' : 'read-notification'}
    >
      <Box>
        {!hasCorrectStructure && (
          <Box>
            <Text fontSize="sm" fontWeight="700" mb="1">
              {notification.title}
            </Text>
            {notification.message && (
              <Text fontSize="sm" fontWeight="500" mb="1">
                {notification.message}
              </Text>
            )}
          </Box>
        )}
        {hasCorrectStructure && (
          <>
            <Text
              fontSize="sm"
              mb="1"
              dangerouslySetInnerHTML={{
                __html: getTitle(notification.additionalContextData?.candidateName),
              }}
            />
            <Text fontSize="sm" mb="1">
              {notification.positionTitle}
            </Text>

            {options.length > 0 && (
              <Flex flexWrap="wrap" gridRowGap="5px" mb="1">
                {options.slice(0, showAll ? options.length : whenNotShowAll).map((opt) => (
                  <Badge
                    key={opt}
                    textTransform="capitalize"
                    variant="outline"
                    color={colors.blue.default}
                    mr={2}
                    px={2}
                    height="28px"
                    borderRadius={5}
                    lineHeight="28px"
                  >
                    {opt}
                  </Badge>
                ))}
                {!showAll && options.length > whenNotShowAll && (
                  <Badge
                    variant="solid"
                    background={colors.blue.default}
                    mr={2}
                    data-testid="ShowAllTagsBtn"
                    onClick={(e) => {
                      e.stopPropagation();
                      readNotificationInvitation(notification.id);
                      addToShowAll(notification.id);
                    }}
                    style={{ padding: '4px 7px', borderRadius: '15px', cursor: 'pointer' }}
                  >
                    {`+${options.length - whenNotShowAll}`}
                  </Badge>
                )}
              </Flex>
            )}
          </>
        )}
        <Text fontSize="xs" color="#A0AEC0">
          {moment(notification.sentAt).format('lll')}
        </Text>
      </Box>
    </ListItem>
  );
}
