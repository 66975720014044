import { css } from '@emotion/core';
import colors from '../../../styles/colors';

export const loginViewWrapper = css`
  position: absolute;
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: linear-gradient(270.02deg, ${colors.blue.a50} -6.62%, ${colors.blue.default} 99.45%);
  z-index: 0;
`;

export const loginView = css`
  height: 100%;
  z-index: 2;
`;

export const alertDialog = css`
  position: absolute;
`;
