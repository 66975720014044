import { BackgroundChecks } from './candidate';

export type Accounts = {
  analyticsEnabled?: boolean;
  id?: string;
  automated?: boolean;
  calendarEnabled?: boolean;
  configuration?: {
    dashboardEnabled?: boolean;
    groupEventEnabled?: boolean;
    webApplyEnabled?: boolean;
    certnEnabled?: boolean;
    freeFormQuestionEnabled?: boolean;
    unresponsiveAgedPeriod?: number;
    seasonalContractPeriod?: number;
    unresponsiveDismissPeriod?: number;
    hireFormEnabled?: boolean;
    requisitionEnabled?: boolean;
    modernHireEnable?: boolean;
    scheduleMatchEnabled?: boolean;
    virtualRecruiterEnabled?: boolean;
    languageEvaluationEnabled?: boolean;
    shouldEnableAppliedPositions?: boolean;
    hireFormEnabledPositions?: [];
    modernHireTemplateIdList?: [];
    dialogFlowEnabled?: boolean;
    candidateReofferEnabled?: boolean;
    isShiftIntersectable?: boolean;
    requisitionActiveThresholdDays?: number;
    hireWebhookForCustomer?: {
      url: string;
    };
    candidateNotificationBeforeInWeeks?: number;
    managementOverview?: ManagementOverviewType;
    managementOverviewEnabled?: boolean;
  };
  owners?: string[];
  superOwners?: string[];
  screeningOptions?: BackgroundChecks;
  subscription?: {
    automation: boolean;
  };
  type?: AccountType;
  name?: string;
  dialogFlowAgents?: DialogFlowAgent[];
  analyticsPages?: ThirdPartIntegrationUrl[];
  prototypeFeaturesPages?: ThirdPartIntegrationUrl[];
  accountHash?: string;
  prototypeEnabled?: boolean;
};

export type ThirdPartIntegrationUrl = {
  engURL: string;
  frURL: string;
  engName: string;
  frName: string;
  minimumRole?: string;
};

export type DialogFlowAgent = {
  defaultLanguageCode: string;
  agentId: string;
  location: string;
  displayName?: string;
  timeZone?: string;
};

export enum AccountType {
  PERSONAL = 'personal',
  BUSINESS = 'business',
}

type SubArcLimits = {
  safe: number;
  normal: number;
  danger: number;
};

type ManagementOverviewType = {
  outstandingOffers: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
  hiresProcessing: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
  backgroundChecksDelayed: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
  overdueApprovals: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
  stalledCandidates: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
  openRequisitions: {
    minValue: number;
    maxValue: number;
    subArcLimits: SubArcLimits;
  };
};
