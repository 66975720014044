/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/core';
import { css } from '@emotion/core';
import { useTranslation } from 'react-i18next';
import { useMeetContext } from '../../../meet/MeetContext';
import { LocalVideoPreview } from '../../../meet/LocalVideoPreview';
import { VideoToggleButton } from '../../../meet/twilio/VideoToggleButton';
import { InterviewAlreadyOngoingModal } from './InterviewAlreadyOngoingModal';
import { useStoreActions } from '../../../../models/hooks';
import { AudioToggleButtonGreenRoom } from '../../../meet/twilio/AudioToggleButtonGreenRoom';

const greenRoomVideoBlock = css`
  display: block;
  margin: 0 auto;
  max-width: 600px;
  height: 100%;
  position: relative;
  width: 100%;

  video {
    object-fit: cover !important;
  }
`;

const greenRoomVideoControls = css`
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);

  .chakra-button {
    padding: 0;
    margin: 0 0 0 12px;
    font-size: 25px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.56);

    svg path {
      fill: #fff;
    }

    &:first-of-type {
      margin: 0;
    }

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      border: 0;
    }

    &.off svg path {
      fill: #f34d4f;
    }
  }
`;

const greenRoomPage = css`
  height: calc(100vh - 64px);
`;

const joinButton = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 26px 10%;
  font-size: 24px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.44);
`;

type GreenRoomProps = {
  isLoading: boolean;
  joinRoom: () => void;
};

export const InterviewGreenRoom = ({ isLoading, joinRoom }: GreenRoomProps): JSX.Element => {
  const { t } = useTranslation(['meet']);
  const { getAudioAndVideoTracks, joinedByAnotherRecruiterName } = useMeetContext();
  // istanbul ignore next
  const { clearInterviewEvent } = useStoreActions((actions) => actions.interview);

  const {
    isOpen: isInterviewJoinedStatusModalOpen,
    onOpen: onInterviewJoinedStatusModalOpen,
    onClose: onInterviewJoinedStatusModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (joinedByAnotherRecruiterName) {
      onInterviewJoinedStatusModalOpen();
    }
  }, [joinedByAnotherRecruiterName, onInterviewJoinedStatusModalOpen]);

  useEffect(() => {
    getAudioAndVideoTracks();
  }, [getAudioAndVideoTracks]);

  return (
    <Box className="green-room-page">
      <Box css={greenRoomPage} justify="center" align="center">
        <Box css={greenRoomVideoBlock} className="green-room-video-block">
          <LocalVideoPreview />
          <Box css={greenRoomVideoControls} className="green-room-video-controls">
            <AudioToggleButtonGreenRoom isLoading={isLoading} />
            <VideoToggleButton isLoading={isLoading} />
          </Box>
          <Button css={joinButton} colorScheme="blue" size="lg" onClick={joinRoom} isLoading={isLoading} isDisabled={isLoading}>
            {t('join')}
          </Button>
        </Box>
      </Box>
      {joinedByAnotherRecruiterName && (
        <InterviewAlreadyOngoingModal
          isOpen={isInterviewJoinedStatusModalOpen}
          onClose={() => {
            onInterviewJoinedStatusModalClose();
            clearInterviewEvent();
          }}
          message={{
            bodyMessage: `${joinedByAnotherRecruiterName} ${t('alreadyJoinedBy')}`,
            confirmText: t('okay'),
            headerMessage: t('alreadyJoinedHeader'),
          }}
        />
      )}
    </Box>
  );
};
