import { AxiosError, AxiosResponse } from 'axios';
import HttpClientWithRetry from '../http/client/HttpClientWithRetry';
import { AppointmentType } from '../firebase/firestore/documents/appointmentAvaiability';
import { GroupEvent } from '../firebase/firestore/documents/groupEvent';

export class CalendarController {
  constructor(private readonly httpClient: HttpClientWithRetry) {}

  async saveSchedule(
    fromDate: Date,
    toDate: Date,
    appointmentTypes: AppointmentType[],
    bookingCapacity: number,
  ): Promise<AxiosResponse | undefined> {
    const path = '/api/v1/appointmentAvailabilities';
    const body = { fromDate, toDate, appointmentTypes, bookingCapacity };
    return this.httpClient
      .post(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async updateSchedule(id: string, payload: any) {
    const path = `/api/v1/appointmentAvailabilities/${id}`;
    await this.httpClient.put(path, payload);
  }

  async deleteSchedule(id: string) {
    const path = `/api/v1/appointmentAvailabilities/${id}`;
    await this.httpClient.delete(path);
  }

  async saveEvent(
    accountId: string,
    groupEvent: GroupEvent,
    candidates: string[],
    recruiters: string[],
  ): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/groupEvent/${accountId}/create`;
    const body = { groupEvent, candidates, recruiters };
    return this.httpClient
      .post(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async updateEvent(
    groupEventId: string,
    groupEvent: GroupEvent,
    candidates: string[],
    recruiters: string[],
  ): Promise<AxiosResponse | undefined> {
    const path = `api/v1/groupEvent/${groupEventId}/update`;
    const body = { groupEventId, groupEvent, candidates, recruiters };
    return this.httpClient
      .put(path, body)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async getEventToken(groupEventId: string): Promise<AxiosResponse | undefined> {
    const path = `/api/v1/groupEvent/${groupEventId}/token`;
    return this.httpClient
      .get(path)
      .then((response: AxiosResponse) => {
        return response;
      })
      .catch((error: AxiosError) => {
        return error.response;
      });
  }

  async deleteEvent(groupEventId: string) {
    const path = `api/v1/groupEvent/${groupEventId}`;
    await this.httpClient.delete(path);
  }

  async cancelIndividualBooking(positionId: string, seekerId: string): Promise<void> {
    const path = `/api/v1/position/${positionId}/assignedRecruiters/cancelAppointment`;
    const body = { seekerId };
    await this.httpClient.put(path, body);
  }
}
