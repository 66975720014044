import { css } from '@emotion/core';

export const meetViewStyle = css`
  width: 100%;
  max-width: 470px;

  .candidate-card-header {
    @media (max-width: 568px) {
      padding-right: 50px !important;
    }

    @media screen and (max-width: 922px) and (orientation: landscape) {
      flex-direction: column;
      align-items: flex-start;

      .chakra-badge {
        margin-bottom: 5px;
      }
    }
  }

  .meet-card-header {
    padding: 1rem 1.5rem;
    background: #ffeee3;

    h3 {
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;

      @media (max-width: 480px) {
        font-size: 12px;
      }
    }
  }
  .meet-card-body {
    padding: 1rem 1.5rem;

    .chakra-button {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
`;

export const greenRoomVideoBlock = css`
  display: block;
  margin: 0 auto 0 50px;
  max-width: 590px;
  height: 395px;
  position: relative;
  width: 100%;

  @media (max-width: 480px) {
    margin: 0 auto 70px;
    height: 200px;
  }

  video {
    object-fit: cover !important;

    @media (max-width: 480px) {
      object-fit: contain !important;
    }
  }
`;

export const greenRoomVideoControls = css`
  position: absolute;
  right: 25px;
  bottom: 20px;

  .chakra-button {
    padding: 0;
    margin: 0 0 0 12px;
    font-size: 25px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.56);

    svg path {
      fill: #fff;
    }

    &:first-of-type {
      margin: 0;
    }

    &:focus,
    &:active,
    &:hover {
      outline: none;
      box-shadow: none;
      border: 0;
    }

    &.off svg path {
      fill: #f34d4f;
    }
  }
`;

export const greenRoomTitle = css`
  font-weight: 600;
`;

export const greenRoomPage = css`
  height: calc(100vh - 100px);
  max-width: 1100px;
  margin: auto;
  min-height: 420px;

  @media (max-width: 480px) {
    height: auto;
    flex-wrap: wrap;
    flex-flow: column;
    flex-direction: column-reverse;
    padding: 20px 0 0 0;
  }
`;

export const hintBoxStyle = css`
  background: #ffeee3;
  border: 1px dashed #ff6600;
`;
