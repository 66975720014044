/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ChangeEvent, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Box,
  ModalCloseButton,
  Textarea,
  Checkbox,
  useToast,
  Text,
  useDisclosure,
  Flex,
  Tooltip,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import { Requisition } from '../../../../../firebase/firestore/documents/requisition';
import { useStoreActions } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { SelectRequisition } from '../SelectRequisition';
import { BusinessSelectType, PositionSelectType } from '../../../../administration/positionManagement/PositionType';

export type HireWithRequisitionModalProps = {
  isOpen: boolean;
  disabled: boolean;
  onClose: () => void;
  requisitionEnabled: boolean;
  selectedRequisitionID?: string;
};

export const HireWithRequisitionModal = ({
  isOpen,
  disabled,
  onClose,
  requisitionEnabled,
  selectedRequisitionID,
}: HireWithRequisitionModalProps): JSX.Element => {
  const { selectedCandidate } = useSeekerContainerContext();
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedRequisition, setSelectedRequisition] = useState<Requisition | undefined>();
  /* istanbul ignore next */
  const saveOfferMadeNote = useStoreActions((actions) => actions.account.saveOfferMadeNote);
  const [isLoading, setIsLoading] = React.useState(false);
  const [noteContent, setNoteContent] = React.useState('');
  const [updatedPositionId, setUpdatedPositionId] = React.useState<string | undefined>(
    selectedCandidate?.newHireData?.positionId,
  );
  const [updatedBusinessId, setUpdatedBusinessId] = React.useState<string | undefined>(
    selectedCandidate?.newHireData?.businessId,
  );
  const updateNoteContent = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteContent(e.target.value);
  };
  const [sendMessageToCandidates, setSendMessageToCandidates] = React.useState(true);
  const [autoDismiss, setAutoDismiss] = React.useState(true);

  const { isOpen: isOpenConformationModal, onOpen: onOpenConformationModal, onClose: onCloseConformationModal } = useDisclosure();

  const handleOfferMade = async () => {
    onCloseConformationModal();
    // istanbul ignore else
    if (selectedCandidate && selectedRequisition) {
      setIsLoading(true);
      try {
        await saveOfferMadeNote({
          positionId: selectedCandidate.position,
          seekerId: selectedCandidate.seeker,
          offerMade: noteContent,
          salary: selectedRequisition?.wage.value.toString(),
          salaryUnit: selectedRequisition?.wage.unit.toLowerCase(),
          customSalaryUnit: selectedRequisition?.wage.customUnit,
          requisitionId: requisitionEnabled ? selectedRequisition.requisitionId : '',
          sendMessageToCandidates,
          updatedPositionId,
          updatedBusinessId,
          autoDismiss,
        });
        setIsLoading(false);
        onClose();
      } catch (e) {
        // istanbul ignore next
        setIsLoading(false);
        // istanbul ignore next
        toast({
          title: 'Something went Wrong!',
          description: 'Could not make offer at moment.',
          duration: 3000,
          isClosable: true,
          status: 'error',
        });
      }
    }
  };
  return (
    <Box>
      <Modal
        id="HireConfirmationModal"
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader
              css={confirmationModalHeaderCSS}
              className="modal-header"
              style={{ color: 'black' }}
              fontSize={16}
              fontWeight={700}
            >
              {t('messaging:actions.hire.sendMessageToCandidates')}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <SelectRequisition
                  selectedRequisitionID={selectedRequisitionID}
                  positionId={selectedCandidate?.position || ''}
                  onRequisitionChanged={(requisition) => setSelectedRequisition(requisition)}
                  handleJobLocationChange={(businessLocationItem: BusinessSelectType | null) =>
                    setUpdatedBusinessId(businessLocationItem?.id)
                  }
                  handleJobChange={(jobPositionItem: PositionSelectType | null) => setUpdatedPositionId(jobPositionItem?.id)}
                  isOpenConformationModal={isOpenConformationModal}
                  onHandleConformationModal={async (conformation: boolean) => {
                    if (conformation) {
                      await handleOfferMade();
                    } else {
                      onCloseConformationModal();
                    }
                  }}
                />

                <Box>
                  <Text fontSize="14px" fontWeight={700} mt="12px">
                    {t('requisition:offerNotes')}
                  </Text>
                  <Textarea
                    mt="2px"
                    value={noteContent}
                    data-testid="NoteContent"
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => updateNoteContent(e)}
                    focusBorderColor="gray"
                    height="70px"
                    placeholder={t('requisition:offerNotes')}
                  />
                  <Flex mt={6}>
                    <Tooltip
                      label={t('messaging:actions.hire.sendMessageToCandidatesToolTip')}
                      shouldWrapChildren
                      placement="top"
                    >
                      <Checkbox
                        mr={6}
                        isDisabled={!!(updatedBusinessId || updatedPositionId)}
                        isChecked={sendMessageToCandidates}
                        data-testid="sendMessageToCandidates"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setSendMessageToCandidates(e.target.checked)}
                      >
                        {t('messaging:actions.hire.sendMessageToCandidates')}
                      </Checkbox>
                    </Tooltip>
                    <Tooltip label={t('messaging:actions.hire.autoDismissCandidatesToolTip')} shouldWrapChildren placement="top">
                      <Checkbox
                        mr={2}
                        isChecked={autoDismiss}
                        isDisabled={!sendMessageToCandidates}
                        data-testid="autoDismissCandidates"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setAutoDismiss(e.target.checked)}
                      >
                        {t('messaging:actions.hire.autoDismissCandidates')}
                      </Checkbox>
                    </Tooltip>
                  </Flex>
                </Box>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
                {t('messaging:actions.hire.confirmationModal.cancelButton')}
              </Button>
              <Button
                data-testid="ConfirmButton"
                colorScheme="blue"
                onClick={() => (updatedBusinessId || updatedPositionId ? onOpenConformationModal() : handleOfferMade())}
                isLoading={isLoading}
                disabled={disabled || !selectedRequisition}
              >
                {t('messaging:actions.hire.confirmationModal.saveButton')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </Box>
  );
};
