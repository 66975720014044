import { css } from '@emotion/core';
import colors from '../../../../styles/colors';

export const createBusinessStyle = css`
  .chakra-form-control {
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    textarea,
    select {
      background-color: ${colors.gray['150']};
      border-color: ${colors.gray['150']};
      box-shadow: 0 0 0 1px ${colors.gray['150']};
      height: 3rem;
      padding: 0.4rem 1rem;
      width: 100%;
      display: block;

      &:focus,
      &:active {
        background-color: ${colors.gray['150']};
        border: 1px solid ${colors.blue[500]};
        box-shadow: 0 0 0 1px ${colors.blue[500]};
      }
    }
    .chakra-input__group .chakra-input__left-addon {
      height: 3rem;
      border: 0;
      background: #dee1e3;
      border-radius: 3px 0px 0px 3px;
    }
  }
  .closeIcon {
    cursor: pointer;
    opacity: 0.8;
  }
  .closeIcon:hover {
    opacity: 1;
  }
`;
