import { useMemo } from 'react';
import _isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import { Candidate, CandidateStatus } from '../../../../firebase/firestore/documents/candidate';
import { Collections } from '../../../../firebase/firestore/collections';
import { useQuerySnapshot } from '../../../../firebase/firestore/hooks';
import { Query } from '../../../../firebase/firestore/query/query';
import { useCurrentUserProfile } from '../../../../app/hooks/useCurrentUserProfile';
import { useStoreActions, useStoreState } from '../../../../models/hooks';
import { Criteria } from '../../../../firebase/firestore/query/criteria';
import { ModernHireStatusList } from '../../../../firebase/firestore/documents/modernHireTypes';
import { isCandidatePage } from '../../../../utils/candidatesUtils';

const buildQuery = (
  filterCandidateByDays: number,
  account?: string,
  seekerId?: string,
  isRequsitionEnabled?: boolean,
): Criteria[] | null => {
  // istanbul ignore next
  if (isCandidatePage()) {
    return [
      Query.field<Candidate>('monetized').equals(true),
      Query.field<Candidate>('seeker').equals(seekerId),
      Query.field<Candidate>('account').equals(account),
      Query.field<Candidate>('positionOpen').equals(true),
    ];
  }

  if (isRequsitionEnabled) {
    const fromDate = moment(moment().subtract(filterCandidateByDays, 'days').format('YYYY-MM-DDT00:00:00')).toDate();
    return [
      Query.field<Candidate>('monetized').equals(true),
      Query.field<Candidate>('seeker').equals(seekerId),
      Query.field<Candidate>('account').equals(account),
      Query.field<Candidate>('positionOpen').equals(true),
      Query.field('computedStatus.lastUpdate').greaterThan(fromDate),
    ];
  }

  return [
    Query.field<Candidate>('monetized').equals(true),
    Query.field<Candidate>('seeker').equals(seekerId),
    Query.field<Candidate>('account').equals(account),
    Query.field<Candidate>('positionOpen').equals(true),
  ];
};

export const useSeekerCandidates = (seekerId?: string): Readonly<Candidate>[] | undefined => {
  const { currentUserProfile } = useCurrentUserProfile();
  const { setSelectedCandidates } = useStoreActions((a) => a.candidate);
  const isRequsitionEnabled = useStoreState((state) => state.app.accounts?.configuration?.requisitionEnabled);
  const candidates = useQuerySnapshot<Readonly<Candidate>>(
    Collections.CANDIDATES,
    buildQuery(currentUserProfile?.filterCandidateByDays || 45, currentUserProfile?.account, seekerId, isRequsitionEnabled),
  );

  const filteredCandidates = candidates
    ?.filter((value) => {
      return !(value.accountExcluded && (value.computedStatus?.status === CandidateStatus.Blacklisted || value.dismissed));
    })
    .filter(
      (c) =>
        ([true, false].includes(c.modernHireEnable) &&
          c.modernHire &&
          c.modernHire.status === ModernHireStatusList.ASSESSMENT_PASSED) ||
        (_isNil(c.modernHireEnable) && _isNil(c.modernHire)),
    );

  return useMemo(() => {
    if (!currentUserProfile || _isNil(filteredCandidates)) {
      return undefined;
    }

    setSelectedCandidates(filteredCandidates);
    return filteredCandidates;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidates, currentUserProfile, setSelectedCandidates]);
};
