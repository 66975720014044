import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  Box,
  Flex,
  Badge,
} from '@chakra-ui/core';
import { IoDocumentText } from 'react-icons/io5';
import { RiHandCoinLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { AiFillAudio } from 'react-icons/ai';
import isEmpty from 'lodash/isEmpty';
import colors from '../../styles/colors';
import { BackgroundCheck, Count, HireForm, OfferMade } from '../../firebase/firestore/documents/toDo';
import CertnLogoSuccess from '../../assets/img/certn-logo-success.svg';
import AppyHereIcon from '../../assets/img/appy_logo.svg';

export type ToDoCountViewSideBarProps = {
  count: Count;
};
export const ToDoCountViewSideBar = ({ count }: ToDoCountViewSideBarProps): JSX.Element => {
  const { t } = useTranslation('toDoList');
  return (
    <Box>
      <Box overflow="auto">
        <Accordion allowToggle>
          <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
            <h2>
              <AccordionButton>
                <Flex as="span" flex="1" textAlign="left" alignItems="center">
                  <IoDocumentText style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />
                  <Text fontSize="14px" fontWeight={700} ml="8px">
                    {t('hireForm')}
                  </Text>
                </Flex>
                {count.totalHireForm.toString().length < 3 ? (
                  <Box
                    mr="6px"
                    w="20px"
                    lineHeight="20px"
                    borderRadius="50%"
                    textAlign="center"
                    background={count.totalHireForm === 0 ? colors.green[500] : colors.red[500]}
                    color={colors.white}
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {count.totalHireForm}
                  </Box>
                ) : (
                  <Badge
                    background={colors.red[500]}
                    color={colors.white}
                    ml="6px"
                    fontSize="14px"
                    fontWeight={700}
                    borderRadius="12px"
                  >
                    {count.totalHireForm}
                  </Badge>
                )}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel p={0}>
              {!isEmpty(count?.hireForm) &&
                Object.keys(count.hireForm).map((item) => (
                  <Flex
                    key={item}
                    as="span"
                    flex="1"
                    textAlign="left"
                    alignItems="center"
                    background="#F7FAFC"
                    p="12px"
                    border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                    justifyContent="space-between"
                  >
                    {console.log('item', item)}
                    <Text fontSize="14px" fontWeight={400}>
                      {t(`${item}`)}
                    </Text>
                    {count.hireForm?.[item as keyof HireForm].toString().length < 3 ? (
                      <Box
                        mr="6px"
                        background={count.hireForm?.[item as keyof HireForm] === 0 ? colors.green[500] : colors.red[500]}
                        w="20px"
                        lineHeight="20px"
                        borderRadius="50%"
                        textAlign="center"
                        color={colors.white}
                        fontSize="14px"
                        fontWeight={700}
                      >
                        {count.hireForm?.[item as keyof HireForm]}
                      </Box>
                    ) : (
                      <Badge
                        background={colors.red[500]}
                        color={colors.white}
                        ml="6px"
                        fontSize="14px"
                        fontWeight={700}
                        borderRadius="12px"
                        mr="35px"
                      >
                        {count.hireForm?.[item as keyof HireForm]}
                      </Badge>
                    )}
                  </Flex>
                ))}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
            <h2>
              <AccordionButton>
                <Flex as="span" flex="1" textAlign="left" alignItems="center">
                  <img src={CertnLogoSuccess} style={{ width: 26 }} alt="" />
                  <Text fontSize="14px" fontWeight={700} ml="8px">
                    {t('backgroundCheck')}
                  </Text>
                </Flex>
                {count.totalBackgroundCheck.toString().length < 3 ? (
                  <Box
                    mr="6px"
                    background={count.totalBackgroundCheck === 0 ? colors.green[500] : colors.red[500]}
                    w="20px"
                    lineHeight="20px"
                    borderRadius="50%"
                    textAlign="center"
                    color={colors.white}
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {count.totalBackgroundCheck}
                  </Box>
                ) : (
                  <Badge
                    background={colors.red[500]}
                    color={colors.white}
                    ml="6px"
                    fontSize="14px"
                    fontWeight={700}
                    borderRadius="12px"
                  >
                    {count.totalBackgroundCheck}
                  </Badge>
                )}

                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel p={0}>
              {!isEmpty(count?.backgroundCheck) &&
                Object.keys(count.backgroundCheck).map((item) => (
                  <Flex
                    key={item}
                    as="span"
                    flex="1"
                    textAlign="left"
                    alignItems="center"
                    background="#F7FAFC"
                    p="12px"
                    border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                    justifyContent="space-between"
                  >
                    <Text fontSize="14px" fontWeight={400}>
                      {t(`${item}`)}
                    </Text>
                    {count.backgroundCheck?.[item as keyof BackgroundCheck].toString().length < 3 ? (
                      <Box
                        mr="6px"
                        background={
                          count.backgroundCheck?.[item as keyof BackgroundCheck] === 0 ? colors.green[500] : colors.red[500]
                        }
                        w="20px"
                        lineHeight="20px"
                        borderRadius="50%"
                        textAlign="center"
                        color={colors.white}
                        fontSize="14px"
                        fontWeight={700}
                      >
                        {count.backgroundCheck?.[item as keyof BackgroundCheck]}
                      </Box>
                    ) : (
                      <Badge
                        background={colors.red[500]}
                        color={colors.white}
                        ml="6px"
                        fontSize="14px"
                        fontWeight={700}
                        borderRadius="12px"
                        mr="35px"
                      >
                        {count.backgroundCheck?.[item as keyof BackgroundCheck]}
                      </Badge>
                    )}
                  </Flex>
                ))}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
            <h2>
              <AccordionButton>
                <Flex as="span" flex="1" textAlign="left" alignItems="center">
                  <RiHandCoinLine style={{ marginRight: '4px' }} color={colors.blue.default} size="20px" />
                  <Text fontSize="14px" fontWeight={700} ml="8px">
                    {t('offer')}
                  </Text>
                </Flex>
                {count.totalOfferMade.toString().length < 3 ? (
                  <Box
                    mr="6px"
                    background={count.totalOfferMade === 0 ? colors.green[500] : colors.red[500]}
                    w="20px"
                    lineHeight="20px"
                    borderRadius="50%"
                    textAlign="center"
                    color={colors.white}
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {count.totalOfferMade}
                  </Box>
                ) : (
                  <Badge
                    background={colors.red[500]}
                    color={colors.white}
                    ml="6px"
                    fontSize="14px"
                    fontWeight={700}
                    borderRadius="12px"
                  >
                    {count.totalOfferMade}
                  </Badge>
                )}
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel p={0}>
              {!isEmpty(count?.offerMade) &&
                Object.keys(count.offerMade).map((item) => (
                  <Flex
                    key={item}
                    as="span"
                    flex="1"
                    textAlign="left"
                    alignItems="center"
                    background="#F7FAFC"
                    p="12px"
                    border="1px solid var(--Outline-Border-Color, #E5E7EB);"
                    justifyContent="space-between"
                  >
                    <Text fontSize="14px" fontWeight={400}>
                      {t(`${item}`)}
                    </Text>
                    {count.offerMade?.[item as keyof OfferMade].toString().length < 3 ? (
                      <Box
                        mr="6px"
                        background={count.offerMade?.[item as keyof OfferMade] === 0 ? colors.green[500] : colors.red[500]}
                        w="20px"
                        lineHeight="20px"
                        borderRadius="50%"
                        textAlign="center"
                        color={colors.white}
                        fontSize="14px"
                        fontWeight={700}
                      >
                        {count.offerMade?.[item as keyof OfferMade]}
                      </Box>
                    ) : (
                      <Badge
                        background={colors.red[500]}
                        color={colors.white}
                        ml="6px"
                        fontSize="14px"
                        fontWeight={700}
                        borderRadius="12px"
                        mr="35px"
                      >
                        {count.offerMade?.[item as keyof OfferMade]}
                      </Badge>
                    )}
                  </Flex>
                ))}
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`}>
            <h2>
              <AccordionButton>
                <Flex as="span" flex="1" textAlign="left" alignItems="center">
                  <AiFillAudio style={{ marginRight: '4px' }} color={colors.orange[500]} size="20px" />
                  <Text fontSize="14px" fontWeight={700} ml="8px">
                    {t('languageEvaluation')}
                  </Text>
                </Flex>
                {count.languageEvaluation.toString().length < 3 ? (
                  <Box
                    mr="6px"
                    background={count?.languageEvaluation === 0 ? colors.green[500] : colors.red[500]}
                    w="20px"
                    lineHeight="20px"
                    borderRadius="50%"
                    textAlign="center"
                    color={colors.white}
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {count.languageEvaluation}
                  </Box>
                ) : (
                  <Badge
                    background={colors.red[500]}
                    color={colors.white}
                    ml="6px"
                    fontSize="14px"
                    fontWeight={700}
                    borderRadius="12px"
                  >
                    {count?.languageEvaluation}
                  </Badge>
                )}
                <AccordionIcon color={colors.white} visibility="hidden" />
              </AccordionButton>
            </h2>
          </AccordionItem>
          <AccordionItem borderTop={`1px solid ${colors.neutral[200]}`} borderBottom={`1px solid ${colors.neutral[200]}`}>
            <h2>
              <AccordionButton>
                <Flex as="span" flex="1" textAlign="left" alignItems="center">
                  <img src={AppyHereIcon} style={{ width: 16 }} alt="" />
                  <Text fontSize="14px" fontWeight={700} ml="8px">
                    {t('conversationalAI')}
                  </Text>
                </Flex>
                {count.dialogflow.toString().length < 3 ? (
                  <Box
                    mr="6px"
                    background={count?.dialogflow === 0 ? colors.green[500] : colors.red[500]}
                    w="20px"
                    lineHeight="20px"
                    borderRadius="50%"
                    textAlign="center"
                    color={colors.white}
                    fontSize="14px"
                    fontWeight={700}
                  >
                    {count.dialogflow}
                  </Box>
                ) : (
                  <Badge
                    background={colors.red[500]}
                    color={colors.white}
                    ml="6px"
                    fontSize="14px"
                    fontWeight={700}
                    borderRadius="12px"
                  >
                    {count?.dialogflow}
                  </Badge>
                )}
                <AccordionIcon visibility="hidden" />
              </AccordionButton>
            </h2>
          </AccordionItem>
        </Accordion>
      </Box>
    </Box>
  );
};
