import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import isNil from 'lodash/isNil';
import { useCurrentUserProfile } from '../app/hooks/useCurrentUserProfile';
import { useUserIsAuthenticated } from '../app/hooks/useUserIsAuthenticated';
import { RemoteConfigKey } from '../firebase/remoteConfig';
import { useStoreActions, useStoreState } from '../models/hooks';
import { AdministrationLayout } from '../modules/administration/AdministrationLayout';
import { RecruiterCalendarView } from '../modules/administration/recruiterCalendar/RecruiterCalendarView';
import { TeamView } from '../modules/administration/team/TeamView';
import { TopCandidateHome } from '../modules/administration/topCandidates/TopCandidateHome';
import { AnalyticsView } from '../modules/analytics/AnalyticsView';
import { NewAnalyticsView } from '../modules/analytics/NewAnalyticsView';
import { LoginView } from '../modules/auth/login/LoginView';
import { CalendarView } from '../modules/calendar/CalendarView';
import { DashboardHome } from '../modules/dashboard/DashboardHome';
import OfferAndHireProcessView from '../modules/dashboard/offerAndHireProcessOverview/OfferAndHireProcessView';
import { EmailInvitationView } from '../modules/emailInvitation/EmailInvitationView';
import { JobPositionView } from '../modules/jobPosition/JobPositionView';
import { MeetView } from '../modules/meet/MeetView';
import { CandidateMessagingView } from '../modules/messaging/CandidateMessagingView';
import { InterviewMessagingView } from '../modules/messaging/InterviewMessagingView';
import { MessagingView } from '../modules/messaging/MessagingView';
import { SeekersPresenter } from '../modules/messaging/sidebar/seekerList/seekersPresenter';
import { SupportLayout } from '../modules/support/SupportLayout';
import UnsubscribeView from '../modules/unsubscribe/UnSubscribeView';
import { isCandidatePage, isCandidatesPage } from '../utils/candidatesUtils';
import PrivateRoute from './PrivateRoute';
import {
  PATH_ADMINISTRATION,
  PATH_ADMINISTRATION_TABS,
  PATH_ANALYTICS,
  PATH_CALENDAR,
  PATH_CANDIDATE,
  PATH_DASHBOARD,
  PATH_EMAIL_INVITATION,
  PATH_GUEST_MEET,
  PATH_INTERVIEW_MESSAGING_TABS,
  PATH_LOGIN,
  PATH_MAIN,
  PATH_MEET,
  PATH_MESSAGING_TABS,
  PATH_OFFER_HIRE_PROCESS,
  PATH_POSITION,
  PATH_MANAGEMENT_OVERVIEW,
  PATH_POSITIONS,
  PATH_PROTOTYPE,
  PATH_RECRUITER_CALENDAR,
  PATH_ROOT,
  PATH_SUPPORT,
  PATH_TEAM,
  PATH_UNSUBSCRIBE,
} from './constants';
import { ManagementOverviewView } from '../modules/managementOverview/ManagementOverviewView';

const Routes = (): JSX.Element => {
  const location = useLocation();
  const isAuthenticated = useUserIsAuthenticated();
  const { currentUserProfile } = useCurrentUserProfile();

  const { loadCandidate } = useStoreActions((actions) => actions.candidate);
  const setAllCandidates = useStoreActions((a) => a.candidate.setAllCandidates);
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const superOwners = useStoreState((s) => s.app.accounts?.superOwners);
  const userId = useStoreState((s) => s.app.user?.id);
  const appOwnersId = useStoreState((s) => s.app.accounts?.owners);

  /* istanbul ignore next */
  const onLoadCandidates = () => {
    if (currentUserProfile && minutesBeforeInactivity) {
      loadCandidate({ accountId: currentUserProfile.account })
        .then((response) => {
          const candidates = response;
          const dismissedcandidates = candidates.filter((c) => c.dismissed);
          const seekerPresenter = new SeekersPresenter(
            candidates,
            currentUserProfile.id,
            minutesBeforeInactivity,
            dismissedcandidates,
          );
          setAllCandidates(seekerPresenter);
        })
        .catch((error) => {
          console.error('Error fetching candidate:', error);
        });
    }
  };

  /* istanbul ignore next */
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isCandidatePage() || isCandidatesPage()) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    if (currentUserProfile && minutesBeforeInactivity && !isCandidatePage()) {
      onLoadCandidates();

      const timer = setInterval(() => {
        onLoadCandidates();
      }, 240000);

      // Cleanup the timer when the component unmounts
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserProfile, loadCandidate, minutesBeforeInactivity, location]);

  useEffect(() => {
    if (location.pathname) {
      firebase.analytics().logEvent('change_route', { pathname: location.pathname });
    }
  }, [location.pathname]);

  const isNewAnalyticEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.ENABLE_NEW_ANALYTIC_VIEW];
  });

  const isSuperAdmin = (): boolean => {
    if (!superOwners || !userId) {
      return false;
    }
    return superOwners?.includes(userId);
  };

  const isAdmin = !isNil(appOwnersId) && !isNil(userId) && appOwnersId?.includes(userId);

  return (
    <Switch location={location} key={location.pathname}>
      <Redirect exact from={PATH_ROOT} to={PATH_LOGIN} />
      <Route exact path={PATH_LOGIN} component={LoginView} />
      <Route exact path={PATH_GUEST_MEET} component={MeetView} />
      <Route exact path={PATH_TEAM} component={TeamView} />
      <Route exact path={PATH_MEET} component={MeetView} />
      <Route exact path={PATH_SUPPORT} component={SupportLayout} />
      <Route exact path={PATH_EMAIL_INVITATION} component={EmailInvitationView} />
      <Route exact path={PATH_UNSUBSCRIBE} component={UnsubscribeView} />
      <PrivateRoute exact path={PATH_ADMINISTRATION} userIsAuth={isAuthenticated} component={AdministrationLayout} />
      <PrivateRoute exact path={PATH_POSITION} userIsAuth={isAuthenticated} component={TopCandidateHome} />
      <PrivateRoute exact path={PATH_ADMINISTRATION_TABS} userIsAuth={isAuthenticated} component={AdministrationLayout} />
      <PrivateRoute
        exact
        path={PATH_ANALYTICS}
        userIsAuth={isAuthenticated}
        component={isNewAnalyticEnabled ? NewAnalyticsView : AnalyticsView}
      />
      <PrivateRoute exact path={PATH_PROTOTYPE} userIsAuth={isAuthenticated} component={NewAnalyticsView} />
      <PrivateRoute exact path={PATH_MAIN} userIsAuth={isAuthenticated} component={MessagingView} />
      <PrivateRoute exact path={PATH_MESSAGING_TABS} userIsAuth={isAuthenticated} component={MessagingView} />
      <PrivateRoute exact path={PATH_CANDIDATE} userIsAuth={isAuthenticated} component={CandidateMessagingView} />
      <PrivateRoute exact path={PATH_INTERVIEW_MESSAGING_TABS} userIsAuth={isAuthenticated} component={InterviewMessagingView} />
      <PrivateRoute exact path={PATH_CALENDAR} userIsAuth={isAuthenticated} component={CalendarView} />
      <PrivateRoute exact path={PATH_RECRUITER_CALENDAR} userIsAuth={isAuthenticated} component={RecruiterCalendarView} />
      <PrivateRoute exact path={PATH_POSITIONS} userIsAuth={isAuthenticated} component={JobPositionView} />
      <PrivateRoute exact path={PATH_DASHBOARD} userIsAuth={isAuthenticated} component={DashboardHome} />
      <PrivateRoute
        exact
        path={PATH_MANAGEMENT_OVERVIEW}
        userIsAuth={isAuthenticated}
        component={isSuperAdmin() || isAdmin ? ManagementOverviewView : MessagingView}
      />
      <PrivateRoute exact path={PATH_OFFER_HIRE_PROCESS} userIsAuth={isAuthenticated} component={OfferAndHireProcessView} />
      <Redirect from="*" to={isAuthenticated ? PATH_MAIN : PATH_LOGIN} />
    </Switch>
  );
};

export default Routes;
