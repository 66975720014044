import React, { Fragment } from 'react';
import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay, ModalCloseButton, IconButton } from '@chakra-ui/core';
import { CloseIcon } from '@chakra-ui/icons';
import colors from '../../styles/colors';
import { CandidateMessagingView } from '../../modules/messaging/CandidateMessagingView';

export type CandidateMessagingViewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentCandidateId: string;
};

export const CandidateMessagingViewModal = ({
  isOpen,
  onClose,
  currentCandidateId,
}: CandidateMessagingViewModalProps): JSX.Element => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl" scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent data-testid="PreferenceModal" height="97vh" maxHeight="97vh" overflow="auto">
          <ModalHeader />
          <ModalCloseButton
            as={IconButton}
            icon={<CloseIcon boxSize={5} />}
            aria-label="Close modal"
            style={{
              cursor: 'pointer',
              backgroundColor: colors.white,
              borderRadius: '50%',
              border: `1px solid ${colors.gray[300]}`,
              padding: 20,
            }}
            _hover={{ background: `${colors.gray[100]} !important` }}
          />

          <ModalBody padding={0}>
            <CandidateMessagingView candidateId={currentCandidateId} hideHeader />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};
