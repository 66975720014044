import { css } from '@emotion/core';

export const dashboardStyleCSS = css`
  padding-top: 64px;

  .stickyTop {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .sticky .countBar {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 1;
  }
`;
