/* istanbul ignore file */
/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../../../../models/hooks';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { confirmationModalHeaderCSS } from './ConfirmationModal.styles';
import { useInterviewConfig } from './useInterviewConfig';
import { AppointmentType } from '../../../../../firebase/firestore/documents/position';
import { AppointmentTypeIcon } from '../../../../common/AppointmentTypeIcon/AppointmentTypeIcon';

export type MeetConfirmationModalProps = {
  isOpen: boolean;
  disabled: boolean;
  onClose: () => void;
  isMessagingView: boolean;
};

export const MeetConfirmationModal = ({
  isOpen,
  disabled,
  onClose,
  isMessagingView,
}: MeetConfirmationModalProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { selectedCandidate } = useSeekerContainerContext();
  const selectedCandidatePositionID = selectedCandidate?.position;
  const interviewConfig = useInterviewConfig();

  const createAppointmentRequest = useStoreActions((actions) => actions.messaging.createAppointmentRequest);
  const loadAppointmentAvailabilities = useStoreActions((actions) => actions.messaging.loadAppointmentAvailabilities);

  // if (selectedCandidate) {
  //   // eslint-disable-next-line no-void
  //   void loadAppointmentAvailabilities({ positionId: selectedCandidate.position });
  // }

  const availabilitiesCount = useStoreState((state) => state.messaging.availabilitiesCount);
  const handleCreateAppointmentRequest = async () => {
    if (!selectedCandidate) {
      return;
    }
    try {
      setIsLoading(true);
      await createAppointmentRequest({ positionId: selectedCandidate.position, seekerId: selectedCandidate.seeker });
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const getAppointmentTypeText = (appointmentType: AppointmentType): string => t(`common:appointmentTypes.${appointmentType}`);

  const getHeaderText = (): string => {
    if (interviewConfig) {
      return t('messaging:actions.meet.confirmationModal.header', {
        duration: interviewConfig.getDuration(),
        type: getAppointmentTypeText(interviewConfig.getType()),
      });
    }
    return '';
  };

  useEffect(() => {
    if (selectedCandidatePositionID) {
      // eslint-disable-next-line no-void
      void loadAppointmentAvailabilities({ positionId: selectedCandidatePositionID });
    }
  }, [loadAppointmentAvailabilities, selectedCandidatePositionID]);

  return (
    <Modal id="MeetConfirmationModal" isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay>
        {availabilitiesCount && availabilitiesCount > 0 ? (
          <ModalContent>
            <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
              <Flex direction="row" alignItems="center">
                <AppointmentTypeIcon type={interviewConfig?.getType()} />
                <Box width={2} />
                {getHeaderText()}
              </Flex>
            </ModalHeader>
            <ModalBody>{t('messaging:actions.meet.confirmationModal.body')}</ModalBody>
            <ModalFooter>
              <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
                {t('messaging:actions.meet.confirmationModal.cancelButton')}
              </Button>
              <Button
                data-testid="SendButton"
                colorScheme="blue"
                onClick={handleCreateAppointmentRequest}
                disabled={disabled}
                isLoading={isLoading}
              >
                {t('messaging:actions.meet.confirmationModal.sendButton')}
              </Button>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader css={confirmationModalHeaderCSS} className="modal-header">
              <Flex direction="row" alignItems="center">
                <AppointmentTypeIcon type={interviewConfig?.getType()} />
                <Box width={2} />
                {t('messaging:actions.meet.notificationModal.header')}
              </Flex>
            </ModalHeader>
            <ModalBody>{t('messaging:actions.meet.notificationModal.body')}</ModalBody>

            <ModalFooter>
              <Button data-testid="CancelButton" colorScheme="red" mr={3} onClick={onClose} disabled={isLoading}>
                {t('messaging:actions.meet.notificationModal.cancelButton')}
              </Button>
              {isMessagingView && (
                <Button
                  data-testid="CreateButton"
                  colorScheme="blue"
                  disabled={disabled}
                  isLoading={isLoading}
                  onClick={() => {
                    history.push({ pathname: '/calendar' }, { state: true });
                  }}
                >
                  {t('messaging:actions.meet.notificationModal.createButton')}
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        )}
      </ModalOverlay>
    </Modal>
  );
};
