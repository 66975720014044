export default {
  updates: 'Nouvelles',
  notifications: 'Notifications',
  invitation: 'Invitation',
  accept: 'Acceptation',
  decline: 'Décliner',
  noNotification: 'Aucune notification !',
  inPastLabel: 'Dans le passé',
  noNewsDescription: 'Pas de nouvelles.',
  accepted: 'Accepté',
  declined: 'Refusé',
  cancelled: 'Annulé',
  requested: 'Demandé',
  acceptedWithMessage: 'Replanifer',
  revoked: 'Révoqué',
  noDataFound: "Il n'a aucunes données.",
  otherRecruitersAppointment: 'Cette entrevue est cédulé avec {{otherRecruiter}}',

  invitationConfirmationModal: {
    headerText: "Es-tu sûr de vouloir {{invitationAction}} l'invitation ?",
    bodyText: '',
    cancelButton: 'Annuler',
    confirmButton: 'Confirmer',
  },

  subTabs: {
    groupEvent: 'Événement de groupe',
    activities: 'Activités',
    backgroundCheck: 'Vérification des antécédents',
    candidates: 'Candidats',
    hireForm: 'Documents',
    modernHireForm: 'Évaluation des comptétences',
    requisition: 'Requisition',
    languageEvaluation: 'Évaluation de la langue',
  },
  statusIcon: {
    expired: 'Expiré',
    inactive: 'Inactif',
    positionClosed: 'Poste Fermé',
    closed: 'Fermé',
    appointmentInThePast: 'Entrevue - Passé',
    appointmentStatusAccepted: 'Entrevue - Accepté',
    appointmentStatusRequested: 'Entrevue - Demandé',
    appointmentStatusDeclined: 'Entrevue - Refusé',
    appointmentStatusCancelled: 'Entrevue - Annulé',
    appointmentStatusReschedulingNeeded: 'Entrevue - Replanifer',
    contactStatusAccepted: 'Contact - Accepté',
    contactStatusDeclined: 'Contact - Refusé',
    contactStatusSent: 'Contact - Demandé',
    interestRevoked: 'Pas intéressé',
    seekerIsHired: 'Embauché',
    seekerIsNotLookingForWork: 'Non disponible',
    appointmentDateFormat: 'D MMM k:mm',
    hoursToDismissal: {
      zero: 'Dans quelques minutes',
      one: 'Dans une heure',
      other: 'Dans {{count}} heures',
    },
    joinVideo: 'EN COURS',
  },
  availability: {
    noAvailability: {
      title: 'Aucune disponibilité',
      description: `Vous n'avez actuellement aucune disponibilité. Vous pouvez en avoir en utilisant l'option "Créer".`,
    },
    lowAvailability: {
      title: 'Faible disponibilité',
      description:
        'Vous avez {{candidates}} candidats prêts à céduler une entrevue, mais votre manque de disponibilités ne peut pas accommoder ces candidats',
    },
    alert: {
      lowAvailability: '<b>Augmentez vos disponibilité</b> - {{candidates}} candidats en attente',
      lowAvailabilityNoCount: '<b>Faible disponibilité</b> - Augmentez vos disponibilités',
    },
  },
  vacancyStatusReminderModal: {
    addVacancy: 'Ajouter plus de postes ouverts',
    closePosition: 'Fermer la position',
    okay: 'Ok',
    actionSuccessful: 'Action Réussie',
    actionSuccessfulDescription: 'Position fermée avec succès',
  },
  addVacancyModal: {
    addNoOfOpenings: 'Entrez le nombre de postes additionels pour cette position',
    actionSuccessfulDescription: 'Le nombre de postes ouverts a été mis à jour',
    alreadyUpdated: 'Ce poste a déjà été mis à jour.',
  },
  seekerHeader: {
    actionSuccessFul: 'Action Réussie',
    addedToFavorite: 'Le candidat a été ajouté à la liste de favoris',
    removedFromFavorite: 'Le candidat a été retiré de la liste de favoris',
    favoriteTooltip: 'Ajouter à la liste de favoris ',
    unFavoriteTooltip: 'Retirer de la liste de favoris',
    favoriteCandidate: 'Candidat préféré',
    deactivatedUserLabel: 'Compte désactivé',
    deactivatedFavoriteText: 'Vous ne pouvez pas ajouter un compte désactivé à vos favoris.',
    priorityTooltip: 'Ce candidat a été référé.',
    kioskTooltip: 'Ce candidat a postulé en utilisant le mode kiosque',
    interventionTooltip: 'Intervention recruteur requise',
  },
  certn: {
    title: `La vérification des antécédents de <b>{{candidate}}</b> est complétée`,
  },
};
