/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { Profile } from '../profile/Profile';
import { History } from '../history/History';
import { AudioReview } from '../audioReview/AudioReview';
import { useSeekerContainerContext } from '../../SeekerContainerContext';
import { Questions } from '../questions/Questions';
import {
  Candidate,
  CandidateStatus,
  LanguageEvaluationStatus,
  RecordingStatus,
} from '../../../../../firebase/firestore/documents/candidate';

const styles = { border: 'none', borderBottom: '1px solid #ededed' };

enum AccordionItems {
  PROFILE = 'PROFILE',
  LANGUAGE_EVALUATION = 'LANGUAGE_EVALUATION',
  QUESTIONS = 'QUESTIONS',
  HISTORY = 'HISTORY',
}

export type VerticalSeekerTabProps = {
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
};

const VerticalSeekerTab = ({ isCertnFeatureEnabled, isCertnEnabled }: VerticalSeekerTabProps) => {
  const { t } = useTranslation();
  const { candidates, selectedCandidate } = useSeekerContainerContext();

  const currentAccordionItemsValues = Object.values(AccordionItems);

  const [currentAccordionItems, setCurrentAccordionItems] = React.useState<AccordionItems[]>(currentAccordionItemsValues);

  const [expandedItem, setExpandedItem] = React.useState<AccordionItems | string>(AccordionItems.PROFILE);

  const handleToggle = (itemKey: AccordionItems) => {
    setExpandedItem((prevItem) => (prevItem === itemKey ? '' : itemKey));
  };

  const currentExpandedItem = currentAccordionItems.findIndex((item) => item === expandedItem);

  const canRenderLanguageEvaluation = selectedCandidate?.recording || selectedCandidate?.recordingRequested;

  useMemo(() => {
    const currentAccordionItemsCopy = currentAccordionItems;
    /* istanbul ignore next */
    if (canRenderLanguageEvaluation && !currentAccordionItems.includes(AccordionItems.LANGUAGE_EVALUATION)) {
      currentAccordionItemsCopy.splice(1, 0, AccordionItems.LANGUAGE_EVALUATION);
      setCurrentAccordionItems(currentAccordionItemsCopy);
    }

    if (!canRenderLanguageEvaluation && currentAccordionItems.includes(AccordionItems.LANGUAGE_EVALUATION)) {
      setCurrentAccordionItems(currentAccordionItemsCopy.filter((item) => item !== AccordionItems.LANGUAGE_EVALUATION));
    }
  }, [canRenderLanguageEvaluation, currentAccordionItems]);

  const filteredCandidates = (candidateList: Candidate[] | undefined): Candidate[] => {
    if (!candidateList) {
      return [];
    }
    return candidateList?.filter(
      (candidate) =>
        candidate.computedStatus?.status === CandidateStatus.OfferMade ||
        candidate.computedStatus?.status === CandidateStatus.Hired ||
        candidate.computedStatus?.status === CandidateStatus.HireInReview,
    );
  };

  const onGetStatusColor = () => {
    if (selectedCandidate?.languageEvaluationReview?.status === LanguageEvaluationStatus.PASS) {
      return 'green.500';
    }
    if (selectedCandidate?.languageEvaluationReview?.status === LanguageEvaluationStatus.FAIL) {
      return 'red.500';
    }
    return 'orange.500';
  };

  const onGetLanguageRecordingStatusBadge = () => {
    if (selectedCandidate?.recordingStatus === RecordingStatus.RE_REQUESTED) {
      return (
        <Box bg="purple.500" px={3} py={1} borderRadius={16} fontSize="12px" fontWeight="700" color="#fff">
          {t(`audioEvaluation:status.RE_REQUESTED`)}
        </Box>
      );
    }
    if (selectedCandidate?.languageEvaluationReview?.status) {
      return (
        <Box bg={onGetStatusColor()} px={3} py={1} borderRadius={16} fontSize="12px" fontWeight="700" color="#fff">
          {t(`common:tabs.${selectedCandidate?.languageEvaluationReview?.status}`)}
        </Box>
      );
    }
    return (
      <Box bg="blue.500" px={3} py={1} borderRadius={16} fontSize="12px" fontWeight="700" color="#fff">
        {t(`audioEvaluation:status.TO_BE_ACCESSED`)}
      </Box>
    );
  };

  return (
    <Box data-testid="VerticalSeekerTab" h="100%" overflowY="scroll">
      <Accordion index={[currentExpandedItem]} allowToggle>
        <AccordionItem style={styles}>
          <AccordionButton
            data-testid="profileAccordionButton"
            bgColor="#FAFAFA"
            py={3}
            px={4}
            _hover={{ outline: 'none', boxShadow: 'none' }}
            _active={{ outline: 'none', boxShadow: 'none' }}
            _focus={{ outline: 'none', boxShadow: 'none' }}
            onClick={() => handleToggle(AccordionItems.PROFILE)}
          >
            <Flex flex="1" textAlign="left" justifyContent="space-between" alignItems="center">
              <Text fontWeight="bold">{t('common:tabs.profile')}</Text>
              {filteredCandidates(candidates).length > 0 && currentExpandedItem !== 0 && (
                <Box bg="red.500" px={3} py={1} borderRadius={16} fontSize="12px" fontWeight="700" color="#fff">
                  Imp
                </Box>
              )}
            </Flex>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
            <Profile hiredAndOfferedCandidates={filteredCandidates(candidates)} />
          </AccordionPanel>
        </AccordionItem>

        {canRenderLanguageEvaluation && (
          <AccordionItem style={styles} isExpanded>
            {({ isExpanded }) => (
              <Box>
                <AccordionButton
                  bgColor="#FAFAFA"
                  py={3}
                  px={4}
                  _hover={{ outline: 'none', boxShadow: 'none' }}
                  _active={{ outline: 'none', boxShadow: 'none' }}
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  data-testid="audioRecordingAccordionButton"
                  onClick={() => handleToggle(AccordionItems.LANGUAGE_EVALUATION)}
                >
                  <Box flex="1" textAlign="left">
                    <Flex flex="1" textAlign="left" justifyContent="space-between" alignItems="center">
                      <Text fontWeight="bold">{t('common:tabs.audioRecording')}</Text>
                      {onGetLanguageRecordingStatusBadge()}
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                  {isExpanded && selectedCandidate && <AudioReview candidate={selectedCandidate} />}
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        )}
        <AccordionItem style={styles} data-testid="QuestionsTabButton" isExpanded={expandedItem === AccordionItems.QUESTIONS}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FAFAFA"
                py={3}
                px={4}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                data-testid="questionAccordionButton"
                onClick={() => handleToggle(AccordionItems.QUESTIONS)}
              >
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold">{t('common:tabs.questions')}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                {isExpanded && <Questions />}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
        <AccordionItem style={styles} isExpanded={expandedItem === AccordionItems.HISTORY}>
          {({ isExpanded }) => (
            <Box>
              <AccordionButton
                bgColor="#FAFAFA"
                py={3}
                px={4}
                _hover={{ outline: 'none', boxShadow: 'none' }}
                _active={{ outline: 'none', boxShadow: 'none' }}
                _focus={{ outline: 'none', boxShadow: 'none' }}
                data-testid="historyAccordionButton"
                onClick={() => handleToggle(AccordionItems.HISTORY)}
                isExpanded={expandedItem === AccordionItems.HISTORY}
              >
                <Box flex="1" textAlign="left">
                  <Text fontWeight="bold">{t('common:tabs.history')}</Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                {isExpanded && <History isCertnFeatureEnabled={isCertnFeatureEnabled} isCertnEnabled={isCertnEnabled} />}
              </AccordionPanel>
            </Box>
          )}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default VerticalSeekerTab;
