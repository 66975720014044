import { Badge, Box, Flex, Text } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Requisition, RequisitionStatus } from '../../../../../firebase/firestore/documents/requisition';
import { useStoreState } from '../../../../../models/hooks';

interface ViewRequisitionDetailUtilsProps {
  requisition: Requisition | undefined;
}
export default function ViewRequisitionDetailUtils({ requisition }: ViewRequisitionDetailUtilsProps) {
  const { t } = useTranslation('requisition');
  const allCandidates = useStoreState((state) => state.candidate.allCandidates?.engagedCandidates);
  const candidateWithReqId = allCandidates?.filter((candidate) => candidate.requisitionId === requisition?.requisitionId)[0];

  return (
    <Box>
      <Flex w="100%">
        {candidateWithReqId && requisition?.status !== RequisitionStatus.OPEN && (
          <Box mt="16px">
            <Link to={`/candidates/${candidateWithReqId.id}/messaging`}>
              <Flex alignItems="center">
                <Text fontSize="14px" fontWeight="700" mr={2}>
                  {t(`requisition:associatedCandidate`)}
                </Text>
                <Flex fontSize="14px" fontWeight="500" pr="6px">
                  <Box mr={3}>{`${candidateWithReqId.firstName} ${candidateWithReqId.lastName},`}</Box>
                  <Box>
                    <Badge variant="outline" colorScheme="blue">
                      {t(`candidateStatus:${candidateWithReqId.computedStatus?.status}`)}
                    </Badge>
                  </Box>
                </Flex>
              </Flex>
            </Link>
          </Box>
        )}
      </Flex>
    </Box>
  );
}
