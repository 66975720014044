/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Center, Image, Text } from '@chakra-ui/core';
import _isNil from 'lodash/isNil';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMessagingViewContext, useSelectedEngagedCandidate } from '../MessagingViewContext';
import SeekerHeader from './header/SeekerHeader';
import { SeekerContainerContextProvider } from './SeekerContainerContext';
import CandidateContainer from './candidate/CandidateContainer';
import { seekerPanel } from './SeekerContainer.styles';
import { useStoreState } from '../../../models/hooks';
import { SeekersPresenter } from '../sidebar/seekerList/seekersPresenter';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { MessagingTabsParams } from '../../../routes/constants';

export type SeekerContainerProps = {
  isInterviewEnabled: boolean;
  isCertnFeatureEnabled: boolean;
  isCertnEnabled: boolean;
  isUndismissedeEnabled: boolean;
  isMessagingView: boolean;
  givenCandidateId?: string;
};

export const SeekerContainer = ({
  isInterviewEnabled,
  isCertnFeatureEnabled,
  isCertnEnabled,
  isUndismissedeEnabled,
  isMessagingView,
  givenCandidateId,
}: SeekerContainerProps): JSX.Element => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('noSeekerMsg');
  const seekerList: SeekersPresenter | undefined = useStoreState((s) => s.candidate.allCandidates);
  const selectedEngagedCandidate = useSelectedEngagedCandidate();

  const { candidateId: candidateIdFromParams } = useParams<MessagingTabsParams>();
  const candidateId = givenCandidateId || candidateIdFromParams;

  const { setSelectedEngagedCandidate, setSelectedEngagedSeeker } = useMessagingViewContext();

  const interviewCandidateSetter = useCallback(() => {
    const candidate = seekerList?.getFirstEngagedCandidate(candidateId);
    setSelectedEngagedCandidate(candidate);
    setSelectedEngagedSeeker(candidate && { seekerId: candidate.seeker, candidateId: candidate.id });
  }, [setSelectedEngagedCandidate, setSelectedEngagedSeeker, candidateId, seekerList]);

  useEffect(() => {
    if (isInterviewEnabled) {
      interviewCandidateSetter();
    }
  }, [isInterviewEnabled, interviewCandidateSetter]);

  const isUserDeactivated = Boolean(selectedEngagedCandidate?.deactivated || selectedEngagedCandidate?.scheduledDeletion);

  const isShowFavoriteStatusEnabled: boolean = useStoreState((state) => {
    return state.remoteConfig[RemoteConfigKey.SHOW_FAVORITE_STATUS];
  });

  return (
    <Box css={seekerPanel} data-testid="SeekerViewPanel" marginLeft="4vw">
      {(() => {
        if (
          seekerList?.getSeekersList(i18n.language).length === 0 &&
          seekerList?.getGhostedSeekersList(i18n.language).length === 0 &&
          seekerList?.getArchivedSeekersList(i18n.language).length === 0 &&
          seekerList?.getDismissedSeekersList(i18n.language).length === 0
        ) {
          return (
            <Center>
              <Box
                align="center"
                pos="absolute"
                height={{
                  base: '100%', // 0-48em
                  md: '50%', // 48em-80em,
                  xl: '25%', // 80em+
                }}
                top="35%"
              >
                <Image src="/noseekermsg.png" alt="no_seeker_img" pb={5} />
                <Text>{t('message')}</Text>
              </Box>
            </Center>
          );
        }
        if (!_isNil(selectedEngagedCandidate)) {
          return (
            <SeekerContainerContextProvider>
              <SeekerHeader
                isUndismissedeEnabled={isUndismissedeEnabled}
                isInterviewEnabled={isInterviewEnabled}
                isCertnFeatureEnabled={isCertnFeatureEnabled}
                isCertnEnabled={isCertnEnabled}
                isShowFavoriteStatusEnabled={isShowFavoriteStatusEnabled}
                isUserDeactivated={isUserDeactivated}
                isMessagingView={isMessagingView}
              />
              <CandidateContainer isCertnFeatureEnabled={isCertnFeatureEnabled} isCertnEnabled={isCertnEnabled} />
            </SeekerContainerContextProvider>
          );
        }
        return <Box gridRow="1 / 3" className="super-centered" />;
      })()}
    </Box>
  );
};
