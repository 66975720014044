/** @jsx jsx */
import { jsx } from 'theme-ui';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Fragment, useEffect, useState } from 'react';
import { Box, Button, FormControl, FormLabel, Heading, Input, Text, useToast } from '@chakra-ui/core';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import { useParams, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../models/hooks';
import { meetViewStyle } from '../meet/MeetVide.styles';
import { useAuthenticationStatus } from '../../app/hooks/useAuthenticationStatus';
import i18n from '../../locales/i18n';
import { AuthenticationStatus } from '../../app/models/appModel';
import { GuestMeetParams } from '../../routes/constants';
import { ValidateURLCode, ValidateURLCodeExpiredError } from '../meet/MeetTokenResponse';

export type QueryParamsType = {
  appointmentAvailabilityId: string;
  bookingId: string;
  candidateId: string;
};

export type MeetGuestVerificationProps = {
  joinRoom: () => void;
  isLoading: boolean;
};

export const MeetGuestVerification = ({ joinRoom, isLoading }: MeetGuestVerificationProps): JSX.Element => {
  const { t } = useTranslation('meet');
  const { candidateId } = useParams<GuestMeetParams>();
  const searchParams = decodeURI(useLocation().search);
  const toast = useToast();
  const queryParamsData = searchParams ? (JSON.parse(searchParams.replace('?', '')) as QueryParamsType) : { bookingId: '' };

  const [isLoadingBtn, setIsLoadingBtn] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [userName, setUserName] = useState<string>('');

  const { signInWithAnonymously } = useStoreActions((actions) => actions.auth);
  const { verifyURLWithCode } = useStoreActions((actions) => actions.meet);
  const { updateUserDetail, initializeAsEmployer, employerOnboarded } = useStoreActions((actions) => actions.user);
  const appUserId = useStoreState((s) => s.auth.firebaseUser?.uid);
  const loggedInUserName = useStoreState((s) => s.app.user?.firstName);

  const authenticationStatus = useAuthenticationStatus();

  const handleGuestLogin = async () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    await signInWithAnonymously().catch((err: any) => {
      console.log('error:', err);
      setIsLoadingBtn(false);
    });
  };

  const registerAnonymousUser = async (userId: string) => {
    await initializeAsEmployer({
      userId,
      employerInitializeData: {
        phoneNumber: null,
        locale: moment.locale(i18n.language),
        timeOffset: moment.parseZone(new Date()).utcOffset(),
      },
    });
    await employerOnboarded({ userId });
    await updateUserDetail({
      userId,
      userDetail: {
        firstName: userName,
        lastName: ' ',
        email: '',
        phone: '',
        anonymousUser: true,
        candidateId,
      },
    });
  };

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.NEW_USER) {
      if (appUserId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        registerAnonymousUser(appUserId).then(() => {
          joinRoom();
          setIsLoadingBtn(false);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationStatus]);

  const handleGuestTokenVerification = async () => {
    setIsLoadingBtn(true);
    await verifyURLWithCode({ code: verificationCode, bookingId: queryParamsData.bookingId })
      .then((response) => {
        const { isValid } = response as ValidateURLCode;
        const { message } = response as ValidateURLCodeExpiredError;
        if (isValid) {
          handleGuestLogin().catch((err) => {
            console.log(err);
          });
        } else {
          toast({
            title: message || t('guestMeet.invalidCode'),
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          setIsLoadingBtn(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingBtn(false);
      });
  };

  return (
    <Box css={meetViewStyle}>
      <Box boxShadow="lg" mb={4}>
        <Box className="meet-card-header">
          <Heading as="h3" size="md">
            {t('guestMeet.readyToJoin')}
          </Heading>
          {(authenticationStatus === AuthenticationStatus.NEW_USER ||
            authenticationStatus === AuthenticationStatus.NOT_AUTHENTICATED) && <Text>{t('guestMeet.enterInfoToJoin')}</Text>}
        </Box>
        <Box className="meet-card-body" spacing={4}>
          {authenticationStatus === AuthenticationStatus.NEW_USER ||
          authenticationStatus === AuthenticationStatus.NOT_AUTHENTICATED ? (
            <Fragment>
              <FormControl id="email" mb={3} isRequired>
                <FormLabel>{t('guestMeet.name')}</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={userName}
                  placeholder={t('guestMeet.namePlaceholder')}
                  data-testid="NameInput"
                  onChange={(event) => setUserName(event.target.value.trimStart())}
                  required
                />
              </FormControl>
              <FormControl id="email" mb={5} isRequired>
                <FormLabel>{t('guestMeet.code')}</FormLabel>
                <Input
                  size="sm"
                  type="text"
                  value={verificationCode}
                  placeholder={t('guestMeet.codePlaceholder')}
                  data-testid="CodeInput"
                  onChange={(event) => setVerificationCode(event.target.value)}
                  required
                />
              </FormControl>
              <Button
                mb={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                data-testid="askToJoinBtn"
                isLoading={isLoadingBtn || isLoading}
                disabled={isLoadingBtn || _isEmpty(userName) || _isEmpty(verificationCode)}
                onClick={() => handleGuestTokenVerification()}
              >
                {t('guestMeet.join')}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              {loggedInUserName && (
                <Heading size="sm" mb={3}>
                  {t('guestMeet.joinMessage', { userName: loggedInUserName })}
                </Heading>
              )}
              <Button
                mb={2}
                size="sm"
                bg="blue.500"
                color="white"
                _hover={{
                  bg: 'blue.600',
                }}
                data-testid="guestJoinBtn"
                isLoading={isLoadingBtn || isLoading}
                disabled={isLoadingBtn || isLoading}
                onClick={() => joinRoom()}
              >
                {t('guestMeet.join')}
              </Button>
            </Fragment>
          )}
        </Box>
      </Box>
    </Box>
  );
};
