import { Dispatch, SetStateAction } from 'react';
import SeekerListSortType from './seekerListSortType';
import SeekerListGroupType from './seekerListGroupType';

export type SeekersFiltersContextValueType = {
  sort: SeekerListSortType;
  group: SeekerListGroupType;
  warningMessage: boolean;
  updateSort: (sortValue: SeekerListSortType) => void;
  updateGroup: (groupValue: SeekerListGroupType) => void;
  updateWarningMessage: (preferenceValue: boolean) => void;
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  ghostedListView: boolean;
  setGhostedListView: Dispatch<SetStateAction<boolean>>;
  favoriteCandidateView: boolean;
  setFavoriteCandidateView: Dispatch<SetStateAction<boolean>>;
  isFromSearchAndSort: boolean;
  setIsFromSearchAndSort: Dispatch<SetStateAction<boolean>>;
  searchAndSortTypes: SearchAndSortTypes;
  setSearchAndSortTypes: Dispatch<SetStateAction<SearchAndSortTypes>>;
  archiveListView: boolean;
  setArchiveListView: Dispatch<SetStateAction<boolean>>;
  dismissListView: boolean;
  setDismissListView: Dispatch<SetStateAction<boolean>>;
  openFilterByPositionList: Map<string, PositionWiseFilterTypes> | undefined;
  setOpenFilterByPositionList: Dispatch<SetStateAction<Map<string, PositionWiseFilterTypes> | undefined>>;
  isPositionFilterApplied: boolean;
  setIsPositionFilterApplied: Dispatch<SetStateAction<boolean>>;
  selectedPositionIdForFilter: string;
  setSelectedPositionIdForFilter: Dispatch<SetStateAction<string>>;
  lengthOfMappedSeekers: number;
  setLengthOfMappedSeekers: Dispatch<SetStateAction<number>>;
  listType: CandidateListType;
  setListType: Dispatch<SetStateAction<CandidateListType>>;
  changeCandidate: boolean;
  setChangeCandidate: Dispatch<SetStateAction<boolean>>;
};

export type SearchAndSortTypes = {
  isInterventionEnabled: boolean;
  isFavouriteApplied: boolean;
  isPriorityCandidates: boolean;
  kioskEnabled?: boolean;
  backgroundCheck: string[];
  hireStatus: string[];
  languageEvaluation: string[];
  availabilityFit: string[];
  modernHireStatus: string[];
  availability: {
    day: boolean;
    night: boolean;
    evening: boolean;
  };
  candidateEngagement: EngagementTypes;
  rating: number[];
};

export type EngagementTypes = {
  apointmentRequested: string;
  appoinmentAccepted: string;
  appoinmentDeclined: string;
  appoinmentCancled: string;
  noAppointmentRequest: string;
  offerMade: string;
  offerRejected: string;
  offerAccepted: string;
  Hired: string;
  interestRevoked: string;
  contactRequestDeclined: string;
};
export type PositionWiseFilterTypes = {
  question?: QuestionFilter[];
  experience?: number[];
  salary?: number[];
  salaryUnit?: string;
};
export type QuestionFilter = {
  question?: string;
  expectedAnswer?: boolean;
  id?: number;
};

export enum CandidateListType {
  DISMISS_CANDIDATE_LIST = 'DISMISS_CANDIDATE_LIST',
  ARCHIVED_CANDIDATE_LIST = 'ARCHIVED_CANDIDATE_LIST',
  FAVOURITE_CANDIDATE_LIST = 'FAVOURITE_CANDIDATE_LIST',
  GHOSTED_CANDIDATE_LIST = 'GHOSTED_CANDIDATE_LIST',
  NONE = 'NONE',
  NOT_MATCHED = 'NOT_MATCHED',
  UNKNOWN = 'UNKNOWN',
}
